import { ProjectMarkers, LayerOptions, PageConfig, IdConfigValue } from '../types/scalars'
import { EmbedDashboardParams } from '../superset-sdk'
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Attachment: string;
  DateTime: number;
  EmbedDashboardParams: Partial<EmbedDashboardParams>;
  IdConfigValue: IdConfigValue;
  Image: string;
  Json: unknown;
  LayerOptions: LayerOptions;
  /** A 64-bit signed integer */
  Long: number;
  PageConfig: PageConfig;
  ProjectMarkers: ProjectMarkers;
  Text: string;
  UUID: string | undefined;
  /** Empty return type */
  Void: Function;
  Wysiwyg: string;
}

export enum AccessType {
  DataRead = 'DATA_READ',
  DataWrite = 'DATA_WRITE',
  Deny = 'DENY',
  Execute = 'EXECUTE'
}

export interface Action {
  __typename?: 'Action';
  config: Maybe<Scalars['Json']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface ActionBatchOperationIn {
  model?: InputMaybe<ActionIn>;
  type?: InputMaybe<OperationType>;
}

export interface ActionIn {
  config?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface ActionList {
  __typename?: 'ActionList';
  data: Maybe<Array<Maybe<Action>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface ActionMutation {
  __typename?: 'ActionMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<Action>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<Action>;
}


export interface ActionMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<ActionBatchOperationIn>>>;
}


export interface ActionMutationCreateArgs {
  data?: InputMaybe<ActionIn>;
}


export interface ActionMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface ActionMutationUpdateArgs {
  data?: InputMaybe<ActionIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface ActionQuery {
  __typename?: 'ActionQuery';
  get: Maybe<Action>;
  list: Maybe<ActionList>;
}


export interface ActionQueryGetArgs {
  id: Scalars['String'];
}


export interface ActionQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<ActionWhereIn>;
}

export interface ActionWhereIn {
  _and?: InputMaybe<Array<InputMaybe<ActionWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<ActionWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<ActionWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface AuthToken {
  __typename?: 'AuthToken';
  refreshToken: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
}

export interface BoundingBox {
  latmax?: InputMaybe<Scalars['Float']>;
  latmin?: InputMaybe<Scalars['Float']>;
  longmin?: InputMaybe<Scalars['Float']>;
  longmmax?: InputMaybe<Scalars['Float']>;
}

export interface Config {
  __typename?: 'Config';
  config: Maybe<Scalars['IdConfigValue']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface ConfigBatchOperationIn {
  model?: InputMaybe<ConfigIn>;
  type?: InputMaybe<OperationType>;
}

export interface ConfigIn {
  config?: InputMaybe<Scalars['IdConfigValue']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface ConfigList {
  __typename?: 'ConfigList';
  data: Maybe<Array<Maybe<Config>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface ConfigMutation {
  __typename?: 'ConfigMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<Config>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<Config>;
}


export interface ConfigMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<ConfigBatchOperationIn>>>;
}


export interface ConfigMutationCreateArgs {
  data?: InputMaybe<ConfigIn>;
}


export interface ConfigMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface ConfigMutationUpdateArgs {
  data?: InputMaybe<ConfigIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface ConfigQuery {
  __typename?: 'ConfigQuery';
  get: Maybe<Config>;
  list: Maybe<ConfigList>;
}


export interface ConfigQueryGetArgs {
  id: Scalars['String'];
}


export interface ConfigQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<ConfigWhereIn>;
}

export interface ConfigWhereIn {
  _and?: InputMaybe<Array<InputMaybe<ConfigWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<ConfigWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<ConfigWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface DbSettings {
  __typename?: 'DbSettings';
  alias: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  jdbcUrl: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
}

export interface DbSettingsBatchOperationIn {
  model?: InputMaybe<DbSettingsIn>;
  type?: InputMaybe<OperationType>;
}

export interface DbSettingsIn {
  alias?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jdbcUrl?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
}

export interface DbSettingsList {
  __typename?: 'DbSettingsList';
  data: Maybe<Array<Maybe<DbSettings>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface DbSettingsMutation {
  __typename?: 'DbSettingsMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<DbSettings>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<DbSettings>;
}


export interface DbSettingsMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<DbSettingsBatchOperationIn>>>;
}


export interface DbSettingsMutationCreateArgs {
  data?: InputMaybe<DbSettingsIn>;
}


export interface DbSettingsMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface DbSettingsMutationUpdateArgs {
  data?: InputMaybe<DbSettingsIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface DbSettingsQuery {
  __typename?: 'DbSettingsQuery';
  dumpDatabase: Maybe<Scalars['Json']>;
  get: Maybe<DbSettings>;
  getDbSchema: Maybe<Scalars['Json']>;
  list: Maybe<DbSettingsList>;
  restoreDatabase: Maybe<Scalars['Json']>;
}


export interface DbSettingsQueryDumpDatabaseArgs {
  dbId?: InputMaybe<Scalars['UUID']>;
  filename?: InputMaybe<Scalars['String']>;
}


export interface DbSettingsQueryGetArgs {
  id: Scalars['String'];
}


export interface DbSettingsQueryGetDbSchemaArgs {
  alias: Scalars['String'];
}


export interface DbSettingsQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<DbSettingsWhereIn>;
}


export interface DbSettingsQueryRestoreDatabaseArgs {
  dbId?: InputMaybe<Scalars['UUID']>;
  filename?: InputMaybe<Scalars['String']>;
}

export interface DbSettingsWhereIn {
  _and?: InputMaybe<Array<InputMaybe<DbSettingsWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<DbSettingsWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<DbSettingsWhereIn>>>;
  alias?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

export interface DirectGrantAccess {
  __typename?: 'DirectGrantAccess';
  accessType: Maybe<AccessType>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  entityId: Maybe<Scalars['UUID']>;
  graphPath: Maybe<Scalars['String']>;
  groupId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  note: Maybe<Scalars['Text']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
}

export interface DirectGrantAccessBatchOperationIn {
  model?: InputMaybe<DirectGrantAccessIn>;
  type?: InputMaybe<OperationType>;
}

export interface DirectGrantAccessIn {
  accessType?: InputMaybe<AccessType>;
  entityId?: InputMaybe<Scalars['UUID']>;
  graphPath?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['Text']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface DirectGrantAccessList {
  __typename?: 'DirectGrantAccessList';
  data: Maybe<Array<Maybe<DirectGrantAccess>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface DirectGrantAccessMutation {
  __typename?: 'DirectGrantAccessMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<DirectGrantAccess>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<DirectGrantAccess>;
}


export interface DirectGrantAccessMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<DirectGrantAccessBatchOperationIn>>>;
}


export interface DirectGrantAccessMutationCreateArgs {
  data?: InputMaybe<DirectGrantAccessIn>;
}


export interface DirectGrantAccessMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface DirectGrantAccessMutationUpdateArgs {
  data?: InputMaybe<DirectGrantAccessIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface DirectGrantAccessQuery {
  __typename?: 'DirectGrantAccessQuery';
  checkAccess: Maybe<Array<Maybe<OperationAccessCheckResult>>>;
  get: Maybe<DirectGrantAccess>;
  list: Maybe<DirectGrantAccessList>;
}


export interface DirectGrantAccessQueryCheckAccessArgs {
  query: InputCheckAccessParams;
}


export interface DirectGrantAccessQueryGetArgs {
  id: Scalars['String'];
}


export interface DirectGrantAccessQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<DirectGrantAccessWhereIn>;
}

export interface DirectGrantAccessWhereIn {
  _and?: InputMaybe<Array<InputMaybe<DirectGrantAccessWhereIn>>>;
  _not?: InputMaybe<DirectGrantAccessWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<DirectGrantAccessWhereIn>>>;
  accessType?: InputMaybe<GraphqlStringBoolExpression>;
  entityId?: InputMaybe<GraphqlUuidBoolExpression>;
  graphPath?: InputMaybe<GraphqlStringBoolExpression>;
  groupId?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  userId?: InputMaybe<GraphqlStringBoolExpression>;
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface EntityConfig {
  __typename?: 'EntityConfig';
  config: Maybe<Scalars['Json']>;
  constraints: Maybe<Scalars['Json']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  fields: Maybe<Scalars['Json']>;
  hidefrommenu: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  internal: Maybe<Scalars['Boolean']>;
  securityrules: Maybe<Scalars['Json']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface EntityConfigBatchOperationIn {
  model?: InputMaybe<EntityConfigIn>;
  type?: InputMaybe<OperationType>;
}

export interface EntityConfigIn {
  config?: InputMaybe<Scalars['Json']>;
  constraints?: InputMaybe<Scalars['Json']>;
  fields?: InputMaybe<Scalars['Json']>;
  hidefrommenu?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  securityrules?: InputMaybe<Scalars['Json']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface EntityConfigList {
  __typename?: 'EntityConfigList';
  data: Maybe<Array<Maybe<EntityConfig>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface EntityConfigMutation {
  __typename?: 'EntityConfigMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<EntityConfig>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<EntityConfig>;
}


export interface EntityConfigMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<EntityConfigBatchOperationIn>>>;
}


export interface EntityConfigMutationCreateArgs {
  data?: InputMaybe<EntityConfigIn>;
}


export interface EntityConfigMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface EntityConfigMutationUpdateArgs {
  data?: InputMaybe<EntityConfigIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface EntityConfigQuery {
  __typename?: 'EntityConfigQuery';
  get: Maybe<EntityConfig>;
  list: Maybe<EntityConfigList>;
}


export interface EntityConfigQueryGetArgs {
  id: Scalars['String'];
}


export interface EntityConfigQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<EntityConfigWhereIn>;
}

export interface EntityConfigWhereIn {
  _and?: InputMaybe<Array<InputMaybe<EntityConfigWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<EntityConfigWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<EntityConfigWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export enum EventType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export interface Feature {
  __typename?: 'Feature';
  bbox: Maybe<Array<Maybe<Scalars['Float']>>>;
  geometry: Maybe<Geometry>;
  geometry_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  layerId: Maybe<Scalars['String']>;
  properties: Maybe<Properties>;
  type: Maybe<Scalars['String']>;
}

/** Настройки персонального доступа */
export interface GpAcl {
  __typename?: 'GPAcl';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** Текстовое пояснение к записи списка разграничения прав доступа. */
  note: Maybe<Scalars['String']>;
  /** Массив ссылок на экземпляры сущностей. */
  object: Maybe<Array<Maybe<AclObjectUnion>>>;
  /** Операция (из справочника). */
  operation: Maybe<Operation>;
  /** Ссылка на профиль доступа пользователя. */
  profile: Maybe<GpCustomerProfile>;
  /** Количество попыток активации (применения) права доступа. Для прав доступа с ограничением по количеству попыток. */
  remains: Maybe<Scalars['Long']>;
  /** Ссылка на учетную запись пользователя. */
  user: Maybe<User>;
}

export interface GpAclBatchOperationIn {
  model?: InputMaybe<GpAclIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpAclIn {
  id?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  operation?: InputMaybe<Operation>;
  profile?: InputMaybe<UuidRefInput>;
  remains?: InputMaybe<Scalars['Long']>;
  user?: InputMaybe<UuidRefInput>;
}

export interface GpAclList {
  __typename?: 'GPAclList';
  data: Maybe<Array<Maybe<GpAcl>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpAclMutation {
  __typename?: 'GPAclMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpAcl>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpAcl>;
}


export interface GpAclMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpAclBatchOperationIn>>>;
}


export interface GpAclMutationCreateArgs {
  data?: InputMaybe<GpAclIn>;
}


export interface GpAclMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpAclMutationUpdateArgs {
  data?: InputMaybe<GpAclIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpAclObjectWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpAclObjectWhereIn>>>;
  _not?: InputMaybe<GpAclObjectWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpAclObjectWhereIn>>>;
  on_GPCmsPage?: InputMaybe<GpCmsPageWhereIn>;
  on_GPCmsPageBlock?: InputMaybe<GpCmsPageBlockWhereIn>;
  on_GPCmsPageExtension?: InputMaybe<GpCmsPageExtensionWhereIn>;
  on_GPCommercialService?: InputMaybe<GpCommercialServiceWhereIn>;
  on_GPDSScheduleDB?: InputMaybe<GpdsScheduleDbWhereIn>;
  on_GPGeoModel?: InputMaybe<GpGeoModelWhereIn>;
  on_GPLayer?: InputMaybe<GpLayerWhereIn>;
  on_GPLayerMap?: InputMaybe<GpLayerMapWhereIn>;
  on_GPLayerPOI?: InputMaybe<GpLayerPoiWhereIn>;
  on_GPOrganization?: InputMaybe<GpOrganizationWhereIn>;
  on_GPPaidFunction?: InputMaybe<GpPaidFunctionWhereIn>;
  on_GPProject?: InputMaybe<GpProjectWhereIn>;
  on_GPProjectGn?: InputMaybe<GpProjectGnWhereIn>;
  on_GPStatDataset?: InputMaybe<GpStatDatasetWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpAclQuery {
  __typename?: 'GPAclQuery';
  get: Maybe<GpAcl>;
  list: Maybe<GpAclList>;
}


export interface GpAclQueryGetArgs {
  id: Scalars['String'];
}


export interface GpAclQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpAclWhereIn>;
}

export interface GpAclWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpAclWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpAclWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpAclWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  note?: InputMaybe<GraphqlStringBoolExpression>;
  object?: InputMaybe<GpAclObjectWhereIn>;
  operation?: InputMaybe<GraphqlStringBoolExpression>;
  profile?: InputMaybe<GpCustomerProfileWhereIn>;
  remains?: InputMaybe<GraphqlLongBoolExpression>;
  user?: InputMaybe<UserWhereIn>;
}

/** BI-проект */
export interface GpbiProject {
  __typename?: 'GPBIProject';
  /** конфигурация для инициализации дашбоарда в режиме эмбеда  */
  biCfg: Maybe<Scalars['EmbedDashboardParams']>;
  cmscreatedate: Maybe<Scalars['DateTime']>;
  cmscreatedby: Maybe<Scalars['String']>;
  cmsupdatedate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** слепок рецепта, по которому создан проект */
  recipe: Maybe<Array<Maybe<GpbiRecipe>>>;
  /** заголовок */
  title: Maybe<Scalars['String']>;
}

export interface GpbiProjectBatchOperationIn {
  model?: InputMaybe<GpbiProjectIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpbiProjectIn {
  biCfg?: InputMaybe<Scalars['EmbedDashboardParams']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  recipe?: InputMaybe<Array<InputMaybe<GpbiRecipeIn>>>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpbiProjectList {
  __typename?: 'GPBIProjectList';
  data: Maybe<Array<Maybe<GpbiProject>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpbiProjectMutation {
  __typename?: 'GPBIProjectMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpbiProject>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpbiProject>;
}


export interface GpbiProjectMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpbiProjectBatchOperationIn>>>;
}


export interface GpbiProjectMutationCreateArgs {
  data?: InputMaybe<GpbiProjectIn>;
}


export interface GpbiProjectMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpbiProjectMutationUpdateArgs {
  data?: InputMaybe<GpbiProjectIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpbiProjectQuery {
  __typename?: 'GPBIProjectQuery';
  get: Maybe<GpbiProject>;
  list: Maybe<GpbiProjectList>;
}


export interface GpbiProjectQueryGetArgs {
  id: Scalars['String'];
}


export interface GpbiProjectQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpbiProjectWhereIn>;
}

export interface GpbiProjectRecipeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiProjectRecipeWhereIn>>>;
  _not?: InputMaybe<GpbiProjectRecipeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiProjectRecipeWhereIn>>>;
  on_GPBIRecipe?: InputMaybe<GpbiRecipeWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpbiProjectWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiProjectWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpbiProjectWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiProjectWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  recipe?: InputMaybe<GpbiProjectRecipeWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-BI: Рецепт - шаблон для типового BI-проекта */
export interface GpbiRecipe {
  __typename?: 'GPBIRecipe';
  /** текстовая аннотация для карточки поиска */
  annotation: Maybe<Scalars['Wysiwyg']>;
  biTemplate: Maybe<Scalars['Attachment']>;
  /** пользователь, создавший рецепт */
  cmscreatedby: Maybe<Scalars['String']>;
  /** дата и время создания рецепта */
  cmscreationdate: Maybe<Scalars['DateTime']>;
  /** признак доступности рецепта через АПИ */
  cmspublished: Maybe<Scalars['Boolean']>;
  /** список наборов данных для рецепта */
  datasetsList: Maybe<Array<Maybe<GpDatasetsList>>>;
  /** текстовое описание рецепта */
  description: Maybe<Scalars['Wysiwyg']>;
  /** длительность (в минутах) */
  duration: Maybe<Scalars['Long']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** иллюстрация */
  img: Maybe<Scalars['Image']>;
  /** массив бизнес-ценностей рецепта (как товара) */
  recipeValues: Maybe<Array<Maybe<GpRecipeValue>>>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  /** массив шагов рецепта (последовательность действий для решения бизнес-задачи) */
  steps: Maybe<Array<Maybe<GpRecipeStep>>>;
  /** заголовок */
  title: Maybe<Scalars['String']>;
  /** ссылки на релевантные (применимые) наборы данных */
  usefulDatasets: Maybe<Array<Maybe<GpStatDataset>>>;
}

export interface GpbiRecipeBatchOperationIn {
  model?: InputMaybe<GpbiRecipeIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpbiRecipeDatasetsListWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiRecipeDatasetsListWhereIn>>>;
  _not?: InputMaybe<GpbiRecipeDatasetsListWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiRecipeDatasetsListWhereIn>>>;
  on_GPDatasetsList?: InputMaybe<GpDatasetsListWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpbiRecipeIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  biTemplate?: InputMaybe<Scalars['Attachment']>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  datasetsList?: InputMaybe<Array<InputMaybe<GpDatasetsListIn>>>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  duration?: InputMaybe<Scalars['Long']>;
  id?: InputMaybe<Scalars['String']>;
  img?: InputMaybe<Scalars['Image']>;
  recipeValues?: InputMaybe<Array<InputMaybe<GpRecipeValueIn>>>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  steps?: InputMaybe<Array<InputMaybe<GpRecipeStepIn>>>;
  title?: InputMaybe<Scalars['String']>;
  usefulDatasets?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
}

export interface GpbiRecipeList {
  __typename?: 'GPBIRecipeList';
  data: Maybe<Array<Maybe<GpbiRecipe>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpbiRecipeMutation {
  __typename?: 'GPBIRecipeMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpbiRecipe>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpbiRecipe>;
}


export interface GpbiRecipeMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpbiRecipeBatchOperationIn>>>;
}


export interface GpbiRecipeMutationCreateArgs {
  data?: InputMaybe<GpbiRecipeIn>;
}


export interface GpbiRecipeMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpbiRecipeMutationUpdateArgs {
  data?: InputMaybe<GpbiRecipeIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpbiRecipeQuery {
  __typename?: 'GPBIRecipeQuery';
  get: Maybe<GpbiRecipe>;
  list: Maybe<GpbiRecipeList>;
}


export interface GpbiRecipeQueryGetArgs {
  id: Scalars['String'];
}


export interface GpbiRecipeQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpbiRecipeWhereIn>;
}

export interface GpbiRecipeRecipeValuesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiRecipeRecipeValuesWhereIn>>>;
  _not?: InputMaybe<GpbiRecipeRecipeValuesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiRecipeRecipeValuesWhereIn>>>;
  on_GPRecipeValue?: InputMaybe<GpRecipeValueWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpbiRecipeStepsWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiRecipeStepsWhereIn>>>;
  _not?: InputMaybe<GpbiRecipeStepsWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiRecipeStepsWhereIn>>>;
  on_GPRecipeStep?: InputMaybe<GpRecipeStepWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpbiRecipeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpbiRecipeWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpbiRecipeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpbiRecipeWhereIn>>>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  datasetsList?: InputMaybe<GpbiRecipeDatasetsListWhereIn>;
  duration?: InputMaybe<GraphqlLongBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  recipeValues?: InputMaybe<GpbiRecipeRecipeValuesWhereIn>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  steps?: InputMaybe<GpbiRecipeStepsWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  usefulDatasets?: InputMaybe<GpStatDatasetWhereIn>;
}

/** Страница Интернет-сайта */
export interface GpCmsPage {
  __typename?: 'GPCmsPage';
  /** *** deprecated */
  blocks: Maybe<Array<Maybe<GpCmsPageBlock>>>;
  blocksEm: Maybe<Array<Maybe<GpCmsPageBlockItem>>>;
  /** ссылка на контент правого блока (на синем фоне) */
  extension: Maybe<GpCmsPageExtension>;
  htmlDescription: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** набор иллюстраций для применения на странице */
  imgs: Maybe<Array<Maybe<Scalars['String']>>>;
  /** признак, что страница разводящая и содержит оглавление */
  isLandingToc: Maybe<Scalars['Boolean']>;
  /** путь для навигации */
  route: Maybe<Scalars['String']>;
  /** основной текст страницы */
  text: Maybe<Scalars['Wysiwyg']>;
  /** заголовок страницы */
  title: Maybe<Scalars['String']>;
}

export interface GpCmsPageBatchOperationIn {
  model?: InputMaybe<GpCmsPageIn>;
  type?: InputMaybe<OperationType>;
}

/** GP-CMS: Блок страницы сайта (левой части) - Блок страницы Интернет-сайта (на левой стороне) */
export interface GpCmsPageBlock {
  __typename?: 'GPCmsPageBlock';
  actionCmd: Maybe<Scalars['String']>;
  actionText: Maybe<Scalars['String']>;
  bgImg: Maybe<Scalars['Image']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  detailCmd: Maybe<Scalars['String']>;
  iconImg: Maybe<Scalars['Image']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  widthInGrid: Maybe<Scalars['Long']>;
}

export interface GpCmsPageBlockBatchOperationIn {
  model?: InputMaybe<GpCmsPageBlockIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCmsPageBlockIn {
  actionCmd?: InputMaybe<Scalars['String']>;
  actionText?: InputMaybe<Scalars['String']>;
  bgImg?: InputMaybe<Scalars['Image']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  detailCmd?: InputMaybe<Scalars['String']>;
  iconImg?: InputMaybe<Scalars['Image']>;
  id?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  widthInGrid?: InputMaybe<Scalars['Long']>;
}

/** Элемент упорядоченного списка блоков страницы Интернет-сайта. */
export interface GpCmsPageBlockItem {
  __typename?: 'GPCmsPageBlockItem';
  block: Maybe<GpCmsPageBlock>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  widthInGrid: Maybe<Scalars['Long']>;
}

export interface GpCmsPageBlockItemBatchOperationIn {
  model?: InputMaybe<GpCmsPageBlockItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCmsPageBlockItemIn {
  block?: InputMaybe<UuidRefInput>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  widthInGrid?: InputMaybe<Scalars['Long']>;
}

export interface GpCmsPageBlockItemList {
  __typename?: 'GPCmsPageBlockItemList';
  data: Maybe<Array<Maybe<GpCmsPageBlockItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCmsPageBlockItemMutation {
  __typename?: 'GPCmsPageBlockItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCmsPageBlockItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCmsPageBlockItem>;
}


export interface GpCmsPageBlockItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCmsPageBlockItemBatchOperationIn>>>;
}


export interface GpCmsPageBlockItemMutationCreateArgs {
  data?: InputMaybe<GpCmsPageBlockItemIn>;
}


export interface GpCmsPageBlockItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCmsPageBlockItemMutationUpdateArgs {
  data?: InputMaybe<GpCmsPageBlockItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageBlockItemQuery {
  __typename?: 'GPCmsPageBlockItemQuery';
  get: Maybe<GpCmsPageBlockItem>;
  list: Maybe<GpCmsPageBlockItemList>;
}


export interface GpCmsPageBlockItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCmsPageBlockItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCmsPageBlockItemWhereIn>;
}

export interface GpCmsPageBlockItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCmsPageBlockItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCmsPageBlockItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCmsPageBlockItemWhereIn>>>;
  block?: InputMaybe<GpCmsPageBlockWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  widthInGrid?: InputMaybe<GraphqlLongBoolExpression>;
}

export interface GpCmsPageBlockList {
  __typename?: 'GPCmsPageBlockList';
  data: Maybe<Array<Maybe<GpCmsPageBlock>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCmsPageBlockMutation {
  __typename?: 'GPCmsPageBlockMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCmsPageBlock>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCmsPageBlock>;
}


export interface GpCmsPageBlockMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCmsPageBlockBatchOperationIn>>>;
}


export interface GpCmsPageBlockMutationCreateArgs {
  data?: InputMaybe<GpCmsPageBlockIn>;
}


export interface GpCmsPageBlockMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCmsPageBlockMutationUpdateArgs {
  data?: InputMaybe<GpCmsPageBlockIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageBlockQuery {
  __typename?: 'GPCmsPageBlockQuery';
  get: Maybe<GpCmsPageBlock>;
  list: Maybe<GpCmsPageBlockList>;
}


export interface GpCmsPageBlockQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCmsPageBlockQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCmsPageBlockWhereIn>;
}

export interface GpCmsPageBlockWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCmsPageBlockWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCmsPageBlockWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCmsPageBlockWhereIn>>>;
  actionCmd?: InputMaybe<GraphqlStringBoolExpression>;
  actionText?: InputMaybe<GraphqlStringBoolExpression>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  detailCmd?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  subtitle?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  widthInGrid?: InputMaybe<GraphqlLongBoolExpression>;
}

export interface GpCmsPageBlocksEmWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCmsPageBlocksEmWhereIn>>>;
  _not?: InputMaybe<GpCmsPageBlocksEmWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCmsPageBlocksEmWhereIn>>>;
  on_GPCmsPageBlockItem?: InputMaybe<GpCmsPageBlockItemWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Элемент правой части страницы Интернет-сайта (настраиваемый контент, отображаемый на синем фоне) */
export interface GpCmsPageExtension {
  __typename?: 'GPCmsPageExtension';
  body: Maybe<Scalars['Wysiwyg']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpCmsPageExtensionBatchOperationIn {
  model?: InputMaybe<GpCmsPageExtensionIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCmsPageExtensionIn {
  body?: InputMaybe<Scalars['Wysiwyg']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageExtensionList {
  __typename?: 'GPCmsPageExtensionList';
  data: Maybe<Array<Maybe<GpCmsPageExtension>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCmsPageExtensionMutation {
  __typename?: 'GPCmsPageExtensionMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCmsPageExtension>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCmsPageExtension>;
}


export interface GpCmsPageExtensionMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCmsPageExtensionBatchOperationIn>>>;
}


export interface GpCmsPageExtensionMutationCreateArgs {
  data?: InputMaybe<GpCmsPageExtensionIn>;
}


export interface GpCmsPageExtensionMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCmsPageExtensionMutationUpdateArgs {
  data?: InputMaybe<GpCmsPageExtensionIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageExtensionQuery {
  __typename?: 'GPCmsPageExtensionQuery';
  get: Maybe<GpCmsPageExtension>;
  list: Maybe<GpCmsPageExtensionList>;
}


export interface GpCmsPageExtensionQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCmsPageExtensionQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCmsPageExtensionWhereIn>;
}

export interface GpCmsPageExtensionWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCmsPageExtensionWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCmsPageExtensionWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCmsPageExtensionWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  subtitle?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpCmsPageIn {
  blocks?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  blocksEm?: InputMaybe<Array<InputMaybe<GpCmsPageBlockItemIn>>>;
  extension?: InputMaybe<UuidRefInput>;
  htmlDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imgs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isLandingToc?: InputMaybe<Scalars['Boolean']>;
  route?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['Wysiwyg']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageList {
  __typename?: 'GPCmsPageList';
  data: Maybe<Array<Maybe<GpCmsPage>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCmsPageMutation {
  __typename?: 'GPCmsPageMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCmsPage>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCmsPage>;
}


export interface GpCmsPageMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCmsPageBatchOperationIn>>>;
}


export interface GpCmsPageMutationCreateArgs {
  data?: InputMaybe<GpCmsPageIn>;
}


export interface GpCmsPageMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCmsPageMutationUpdateArgs {
  data?: InputMaybe<GpCmsPageIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCmsPageQuery {
  __typename?: 'GPCmsPageQuery';
  get: Maybe<GpCmsPage>;
  list: Maybe<GpCmsPageList>;
}


export interface GpCmsPageQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCmsPageQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCmsPageWhereIn>;
}

export interface GpCmsPageWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCmsPageWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCmsPageWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCmsPageWhereIn>>>;
  blocks?: InputMaybe<GpCmsPageBlockWhereIn>;
  blocksEm?: InputMaybe<GpCmsPageBlocksEmWhereIn>;
  extension?: InputMaybe<GpCmsPageExtensionWhereIn>;
  htmlDescription?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  route?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-WMS: Товар = услуга - коммерческая услуга (как товар в маркетплейсе). */
export interface GpCommercialService {
  __typename?: 'GPCommercialService';
  annotation: Maybe<Scalars['Wysiwyg']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  passport: Maybe<Scalars['Attachment']>;
  price: Maybe<Scalars['String']>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  serviceValues: Maybe<Array<Maybe<GpCommercialServiceValue>>>;
  territory: Maybe<GpTerritories>;
  title: Maybe<Scalars['String']>;
  uNameKey: Maybe<Scalars['String']>;
}

export interface GpCommercialServiceBatchOperationIn {
  model?: InputMaybe<GpCommercialServiceIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCommercialServiceIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  price?: InputMaybe<Scalars['String']>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  serviceValues?: InputMaybe<Array<InputMaybe<GpCommercialServiceValueIn>>>;
  territory?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
  uNameKey?: InputMaybe<Scalars['String']>;
}

export interface GpCommercialServiceList {
  __typename?: 'GPCommercialServiceList';
  data: Maybe<Array<Maybe<GpCommercialService>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCommercialServiceMutation {
  __typename?: 'GPCommercialServiceMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCommercialService>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCommercialService>;
}


export interface GpCommercialServiceMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCommercialServiceBatchOperationIn>>>;
}


export interface GpCommercialServiceMutationCreateArgs {
  data?: InputMaybe<GpCommercialServiceIn>;
}


export interface GpCommercialServiceMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCommercialServiceMutationUpdateArgs {
  data?: InputMaybe<GpCommercialServiceIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCommercialServiceQuery {
  __typename?: 'GPCommercialServiceQuery';
  get: Maybe<GpCommercialService>;
  list: Maybe<GpCommercialServiceList>;
}


export interface GpCommercialServiceQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCommercialServiceQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCommercialServiceWhereIn>;
}

export interface GpCommercialServiceServiceValuesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCommercialServiceServiceValuesWhereIn>>>;
  _not?: InputMaybe<GpCommercialServiceServiceValuesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCommercialServiceServiceValuesWhereIn>>>;
  on_GPCommercialServiceValue?: InputMaybe<GpCommercialServiceValueWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Элемент описания УЦП услуги, для карточки маркетплейса */
export interface GpCommercialServiceValue {
  __typename?: 'GPCommercialServiceValue';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  title: Maybe<Scalars['String']>;
  wording: Maybe<Scalars['Wysiwyg']>;
}

export interface GpCommercialServiceValueBatchOperationIn {
  model?: InputMaybe<GpCommercialServiceValueIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCommercialServiceValueIn {
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
  wording?: InputMaybe<Scalars['Wysiwyg']>;
}

export interface GpCommercialServiceValueList {
  __typename?: 'GPCommercialServiceValueList';
  data: Maybe<Array<Maybe<GpCommercialServiceValue>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCommercialServiceValueMutation {
  __typename?: 'GPCommercialServiceValueMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCommercialServiceValue>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCommercialServiceValue>;
}


export interface GpCommercialServiceValueMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCommercialServiceValueBatchOperationIn>>>;
}


export interface GpCommercialServiceValueMutationCreateArgs {
  data?: InputMaybe<GpCommercialServiceValueIn>;
}


export interface GpCommercialServiceValueMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCommercialServiceValueMutationUpdateArgs {
  data?: InputMaybe<GpCommercialServiceValueIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCommercialServiceValueQuery {
  __typename?: 'GPCommercialServiceValueQuery';
  get: Maybe<GpCommercialServiceValue>;
  list: Maybe<GpCommercialServiceValueList>;
}


export interface GpCommercialServiceValueQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCommercialServiceValueQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCommercialServiceValueWhereIn>;
}

export interface GpCommercialServiceValueWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCommercialServiceValueWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCommercialServiceValueWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCommercialServiceValueWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpCommercialServiceWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCommercialServiceWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCommercialServiceWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCommercialServiceWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlStringBoolExpression>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  serviceValues?: InputMaybe<GpCommercialServiceServiceValuesWhereIn>;
  territory?: InputMaybe<GpTerritoriesWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uNameKey?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-CRM: Профиль клиента - Профиль клиента, связывает клиента с тарифом и балансом. Баланс хранится в виде ссылки на запись в бухгалтерской системе (идентификатора). */
export interface GpCustomerProfile {
  __typename?: 'GPCustomerProfile';
  balanceId: Maybe<Scalars['Long']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  gpOrganization: Maybe<GpOrganization>;
  gpUser: Maybe<GpUser>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  orders: Maybe<Array<Maybe<GpOrder>>>;
  tariffPlan: Maybe<GpTariffPlan>;
  title: Maybe<Scalars['String']>;
}

export interface GpCustomerProfileBatchOperationIn {
  model?: InputMaybe<GpCustomerProfileIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpCustomerProfileIn {
  balanceId?: InputMaybe<Scalars['Long']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  gpOrganization?: InputMaybe<UuidRefInput>;
  gpUser?: InputMaybe<UuidRefInput>;
  id?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  tariffPlan?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpCustomerProfileList {
  __typename?: 'GPCustomerProfileList';
  data: Maybe<Array<Maybe<GpCustomerProfile>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpCustomerProfileMutation {
  __typename?: 'GPCustomerProfileMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpCustomerProfile>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpCustomerProfile>;
}


export interface GpCustomerProfileMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpCustomerProfileBatchOperationIn>>>;
}


export interface GpCustomerProfileMutationCreateArgs {
  data?: InputMaybe<GpCustomerProfileIn>;
}


export interface GpCustomerProfileMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpCustomerProfileMutationUpdateArgs {
  data?: InputMaybe<GpCustomerProfileIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpCustomerProfileQuery {
  __typename?: 'GPCustomerProfileQuery';
  get: Maybe<GpCustomerProfile>;
  list: Maybe<GpCustomerProfileList>;
}


export interface GpCustomerProfileQueryGetArgs {
  id: Scalars['String'];
}


export interface GpCustomerProfileQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpCustomerProfileWhereIn>;
}

export interface GpCustomerProfileWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpCustomerProfileWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpCustomerProfileWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpCustomerProfileWhereIn>>>;
  balanceId?: InputMaybe<GraphqlLongBoolExpression>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  gpUser?: InputMaybe<GpUserWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  tariffPlan?: InputMaybe<GpTariffPlanWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Поставка набора данных (метаданные для привязки к товару) */
export interface GpdsSchedule {
  __typename?: 'GPDSSchedule';
  cmscreatedby: Maybe<Scalars['String']>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  composeDate: Maybe<Scalars['DateTime']>;
  dataset: Maybe<GpdsScheduleDb>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  onSale: Maybe<Scalars['Boolean']>;
  price: Maybe<Scalars['Long']>;
  releaseDate: Maybe<Scalars['DateTime']>;
  title: Maybe<Scalars['String']>;
}

export interface GpdsScheduleBatchOperationIn {
  model?: InputMaybe<GpdsScheduleIn>;
  type?: InputMaybe<OperationType>;
}

/** Набор стат. данных с EDWL */
export interface GpdsScheduleDb {
  __typename?: 'GPDSScheduleDB';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  passport: Maybe<Scalars['Attachment']>;
  title: Maybe<Scalars['String']>;
  uName: Maybe<Scalars['String']>;
}

export interface GpdsScheduleDbBatchOperationIn {
  model?: InputMaybe<GpdsScheduleDbIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpdsScheduleDbIn {
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  title?: InputMaybe<Scalars['String']>;
  uName?: InputMaybe<Scalars['String']>;
}

export interface GpdsScheduleDbList {
  __typename?: 'GPDSScheduleDBList';
  data: Maybe<Array<Maybe<GpdsScheduleDb>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpdsScheduleDbMutation {
  __typename?: 'GPDSScheduleDBMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpdsScheduleDb>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpdsScheduleDb>;
}


export interface GpdsScheduleDbMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpdsScheduleDbBatchOperationIn>>>;
}


export interface GpdsScheduleDbMutationCreateArgs {
  data?: InputMaybe<GpdsScheduleDbIn>;
}


export interface GpdsScheduleDbMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpdsScheduleDbMutationUpdateArgs {
  data?: InputMaybe<GpdsScheduleDbIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpdsScheduleDbQuery {
  __typename?: 'GPDSScheduleDBQuery';
  get: Maybe<GpdsScheduleDb>;
  list: Maybe<GpdsScheduleDbList>;
}


export interface GpdsScheduleDbQueryGetArgs {
  id: Scalars['String'];
}


export interface GpdsScheduleDbQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpdsScheduleDbWhereIn>;
}

export interface GpdsScheduleDbWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpdsScheduleDbWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpdsScheduleDbWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpdsScheduleDbWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uName?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpdsScheduleIn {
  composeDate?: InputMaybe<Scalars['DateTime']>;
  dataset?: InputMaybe<UuidRefInput>;
  id?: InputMaybe<Scalars['String']>;
  onSale?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Long']>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpdsScheduleList {
  __typename?: 'GPDSScheduleList';
  data: Maybe<Array<Maybe<GpdsSchedule>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpdsScheduleMutation {
  __typename?: 'GPDSScheduleMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpdsSchedule>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpdsSchedule>;
}


export interface GpdsScheduleMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpdsScheduleBatchOperationIn>>>;
}


export interface GpdsScheduleMutationCreateArgs {
  data?: InputMaybe<GpdsScheduleIn>;
}


export interface GpdsScheduleMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpdsScheduleMutationUpdateArgs {
  data?: InputMaybe<GpdsScheduleIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpdsScheduleQuery {
  __typename?: 'GPDSScheduleQuery';
  get: Maybe<GpdsSchedule>;
  list: Maybe<GpdsScheduleList>;
}


export interface GpdsScheduleQueryGetArgs {
  id: Scalars['String'];
}


export interface GpdsScheduleQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpdsScheduleWhereIn>;
}

export interface GpdsScheduleWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpdsScheduleWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpdsScheduleWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpdsScheduleWhereIn>>>;
  composeDate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  dataset?: InputMaybe<GpdsScheduleDbWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlLongBoolExpression>;
  releaseDate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpDataset {
  __typename?: 'GPDataset';
  annotation: Maybe<Scalars['Wysiwyg']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  passport: Maybe<Scalars['Attachment']>;
  price: Maybe<Scalars['String']>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  territory: Maybe<GpTerritories>;
  title: Maybe<Scalars['String']>;
  uNameKey: Maybe<Scalars['String']>;
}

export interface GpDatasetBatchOperationIn {
  model?: InputMaybe<GpDatasetIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpDatasetIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  price?: InputMaybe<Scalars['String']>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  territory?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
  uNameKey?: InputMaybe<Scalars['String']>;
}

export interface GpDatasetList {
  __typename?: 'GPDatasetList';
  data: Maybe<Array<Maybe<GpDataset>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpDatasetMutation {
  __typename?: 'GPDatasetMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpDataset>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpDataset>;
}


export interface GpDatasetMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpDatasetBatchOperationIn>>>;
}


export interface GpDatasetMutationCreateArgs {
  data?: InputMaybe<GpDatasetIn>;
}


export interface GpDatasetMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpDatasetMutationUpdateArgs {
  data?: InputMaybe<GpDatasetIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpDatasetQuery {
  __typename?: 'GPDatasetQuery';
  get: Maybe<GpDataset>;
  list: Maybe<GpDatasetList>;
}


export interface GpDatasetQueryGetArgs {
  id: Scalars['String'];
}


export interface GpDatasetQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpDatasetWhereIn>;
}

export interface GpDatasetWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpDatasetWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpDatasetWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpDatasetWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlStringBoolExpression>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  territory?: InputMaybe<GpTerritoriesWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uNameKey?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Список наборов данных для совместного применения в группе */
export interface GpDatasetsList {
  __typename?: 'GPDatasetsList';
  cid: Maybe<Scalars['UUID']>;
  dslist: Maybe<Array<Maybe<GpuiDataset>>>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
}

export interface GpDatasetsListBatchOperationIn {
  model?: InputMaybe<GpDatasetsListIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpDatasetsListDslistWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpDatasetsListDslistWhereIn>>>;
  _not?: InputMaybe<GpDatasetsListDslistWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpDatasetsListDslistWhereIn>>>;
  on_GPUIDataset?: InputMaybe<GpuiDatasetWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpDatasetsListIn {
  cid?: InputMaybe<Scalars['UUID']>;
  dslist?: InputMaybe<Array<InputMaybe<GpuiDatasetIn>>>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpDatasetsListList {
  __typename?: 'GPDatasetsListList';
  data: Maybe<Array<Maybe<GpDatasetsList>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpDatasetsListMutation {
  __typename?: 'GPDatasetsListMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpDatasetsList>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpDatasetsList>;
}


export interface GpDatasetsListMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpDatasetsListBatchOperationIn>>>;
}


export interface GpDatasetsListMutationCreateArgs {
  data?: InputMaybe<GpDatasetsListIn>;
}


export interface GpDatasetsListMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpDatasetsListMutationUpdateArgs {
  data?: InputMaybe<GpDatasetsListIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpDatasetsListQuery {
  __typename?: 'GPDatasetsListQuery';
  get: Maybe<GpDatasetsList>;
  list: Maybe<GpDatasetsListList>;
}


export interface GpDatasetsListQueryGetArgs {
  id: Scalars['String'];
}


export interface GpDatasetsListQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpDatasetsListWhereIn>;
}

export interface GpDatasetsListWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpDatasetsListWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpDatasetsListWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpDatasetsListWhereIn>>>;
  cid?: InputMaybe<GraphqlUuidBoolExpression>;
  dslist?: InputMaybe<GpDatasetsListDslistWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

/** Распределение значений геослоя (для визуализации диаграммы на UI). Структура для оперирования распределением значений признаков на клиенте. В поле distribution массив пар XY, описывающий распределение. Применимо для построения графика признака со слоя на UI: 1) на формах настройки интервала визуализации; 2) на формах прокола на клиенте. */
export interface GpDistribution {
  __typename?: 'GPDistribution';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  distribution: Maybe<Scalars['Json']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpDistributionBatchOperationIn {
  model?: InputMaybe<GpDistributionIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpDistributionIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  distribution?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpDistributionList {
  __typename?: 'GPDistributionList';
  data: Maybe<Array<Maybe<GpDistribution>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpDistributionMutation {
  __typename?: 'GPDistributionMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpDistribution>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpDistribution>;
}


export interface GpDistributionMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpDistributionBatchOperationIn>>>;
}


export interface GpDistributionMutationCreateArgs {
  data?: InputMaybe<GpDistributionIn>;
}


export interface GpDistributionMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpDistributionMutationUpdateArgs {
  data?: InputMaybe<GpDistributionIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpDistributionQuery {
  __typename?: 'GPDistributionQuery';
  get: Maybe<GpDistribution>;
  list: Maybe<GpDistributionList>;
}


export interface GpDistributionQueryGetArgs {
  id: Scalars['String'];
}


export interface GpDistributionQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpDistributionWhereIn>;
}

export interface GpDistributionWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpDistributionWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpDistributionWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpDistributionWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpDoc {
  __typename?: 'GPDoc';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['Wysiwyg']>;
  fname: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}

export interface GpDocBatchOperationIn {
  model?: InputMaybe<GpDocIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpDocIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  fname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GpDocList {
  __typename?: 'GPDocList';
  data: Maybe<Array<Maybe<GpDoc>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpDocMutation {
  __typename?: 'GPDocMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpDoc>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpDoc>;
}


export interface GpDocMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpDocBatchOperationIn>>>;
}


export interface GpDocMutationCreateArgs {
  data?: InputMaybe<GpDocIn>;
}


export interface GpDocMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpDocMutationUpdateArgs {
  data?: InputMaybe<GpDocIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpDocQuery {
  __typename?: 'GPDocQuery';
  get: Maybe<GpDoc>;
  list: Maybe<GpDocList>;
}


export interface GpDocQueryGetArgs {
  id: Scalars['String'];
}


export interface GpDocQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpDocWhereIn>;
}

export interface GpDocWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpDocWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpDocWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpDocWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  fname?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpGeoItem {
  __typename?: 'GPGeoItem';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  geometryJSON: Maybe<Scalars['Json']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
}

export interface GpGeoItemBatchOperationIn {
  model?: InputMaybe<GpGeoItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpGeoItemIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  geometryJSON?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpGeoItemList {
  __typename?: 'GPGeoItemList';
  data: Maybe<Array<Maybe<GpGeoItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpGeoItemMutation {
  __typename?: 'GPGeoItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpGeoItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpGeoItem>;
}


export interface GpGeoItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpGeoItemBatchOperationIn>>>;
}


export interface GpGeoItemMutationCreateArgs {
  data?: InputMaybe<GpGeoItemIn>;
}


export interface GpGeoItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpGeoItemMutationUpdateArgs {
  data?: InputMaybe<GpGeoItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpGeoItemQuery {
  __typename?: 'GPGeoItemQuery';
  get: Maybe<GpGeoItem>;
  list: Maybe<GpGeoItemList>;
}


export interface GpGeoItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpGeoItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpGeoItemWhereIn>;
}

export interface GpGeoItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpGeoItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpGeoItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpGeoItemWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

/** Геомодель, как товар маркетплейса */
export interface GpGeoModel {
  __typename?: 'GPGeoModel';
  annotation: Maybe<Scalars['Wysiwyg']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  passport: Maybe<Scalars['Attachment']>;
  price: Maybe<Scalars['String']>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  territory: Maybe<GpTerritories>;
  title: Maybe<Scalars['String']>;
  uNameKey: Maybe<Scalars['String']>;
}

export interface GpGeoModelBatchOperationIn {
  model?: InputMaybe<GpGeoModelIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpGeoModelIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  price?: InputMaybe<Scalars['String']>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  territory?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
  uNameKey?: InputMaybe<Scalars['String']>;
}

export interface GpGeoModelList {
  __typename?: 'GPGeoModelList';
  data: Maybe<Array<Maybe<GpGeoModel>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpGeoModelMutation {
  __typename?: 'GPGeoModelMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpGeoModel>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpGeoModel>;
}


export interface GpGeoModelMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpGeoModelBatchOperationIn>>>;
}


export interface GpGeoModelMutationCreateArgs {
  data?: InputMaybe<GpGeoModelIn>;
}


export interface GpGeoModelMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpGeoModelMutationUpdateArgs {
  data?: InputMaybe<GpGeoModelIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpGeoModelQuery {
  __typename?: 'GPGeoModelQuery';
  get: Maybe<GpGeoModel>;
  list: Maybe<GpGeoModelList>;
}


export interface GpGeoModelQueryGetArgs {
  id: Scalars['String'];
}


export interface GpGeoModelQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpGeoModelWhereIn>;
}

export interface GpGeoModelWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpGeoModelWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpGeoModelWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpGeoModelWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlStringBoolExpression>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  territory?: InputMaybe<GpTerritoriesWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uNameKey?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-INT: Тип геоданных - Справочник типов дискретизации геоданных (пока для слоев). */
export interface GpGeoType {
  __typename?: 'GPGeoType';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpGeoTypeBatchOperationIn {
  model?: InputMaybe<GpGeoTypeIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpGeoTypeIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpGeoTypeList {
  __typename?: 'GPGeoTypeList';
  data: Maybe<Array<Maybe<GpGeoType>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpGeoTypeMutation {
  __typename?: 'GPGeoTypeMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpGeoType>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpGeoType>;
}


export interface GpGeoTypeMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpGeoTypeBatchOperationIn>>>;
}


export interface GpGeoTypeMutationCreateArgs {
  data?: InputMaybe<GpGeoTypeIn>;
}


export interface GpGeoTypeMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpGeoTypeMutationUpdateArgs {
  data?: InputMaybe<GpGeoTypeIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpGeoTypeQuery {
  __typename?: 'GPGeoTypeQuery';
  get: Maybe<GpGeoType>;
  list: Maybe<GpGeoTypeList>;
}


export interface GpGeoTypeQueryGetArgs {
  id: Scalars['String'];
}


export interface GpGeoTypeQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpGeoTypeWhereIn>;
}

export interface GpGeoTypeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpGeoTypeWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpGeoTypeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpGeoTypeWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpGeoserverGetFeatureQuery {
  __typename?: 'GPGeoserverGetFeatureQuery';
  get: Maybe<GetFeatureResponse>;
}


export interface GpGeoserverGetFeatureQueryGetArgs {
  params?: InputMaybe<GetFeatureParams>;
}

/** Геослой, как товар маркетплейса */
export interface GpLayer {
  __typename?: 'GPLayer';
  annotation: Maybe<Scalars['Wysiwyg']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['Wysiwyg']>;
  geoType: Maybe<GpGeoType>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  onSale: Maybe<Scalars['Boolean']>;
  passport: Maybe<Scalars['Attachment']>;
  photos: Maybe<Array<Maybe<Scalars['String']>>>;
  price: Maybe<Scalars['String']>;
  productFeatures: Maybe<Array<Maybe<GpProductFeature>>>;
  recommendedRecipes: Maybe<Array<Maybe<GpRecipe>>>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  schedules: Maybe<Array<Maybe<GpSchedule>>>;
  semanticTerms: Maybe<Array<Maybe<GpSemanticCoreItem>>>;
  similarLayers: Maybe<Array<Maybe<GpLayer>>>;
  supplier: Maybe<Scalars['String']>;
  territory: Maybe<GpTerritories>;
  title: Maybe<Scalars['String']>;
  uNameKey: Maybe<Scalars['String']>;
  updatePeriod: Maybe<GpUpdatePeriod>;
}

export interface GpLayerBatchOperationIn {
  model?: InputMaybe<GpLayerIn>;
  type?: InputMaybe<OperationType>;
}

/** Вычисляемый слой - параметры формулы для пересечения слоев. */
export interface GpLayerFormula {
  __typename?: 'GPLayerFormula';
  I: Maybe<Scalars['Boolean']>;
  L: Maybe<Array<Maybe<GpLayerFormulaItem>>>;
  M: Maybe<Scalars['Long']>;
  O: Maybe<Scalars['Long']>;
  P: Maybe<Array<Maybe<GpPalette>>>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
}

export interface GpLayerFormulaBatchOperationIn {
  model?: InputMaybe<GpLayerFormulaIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpLayerFormulaIn {
  I?: InputMaybe<Scalars['Boolean']>;
  L?: InputMaybe<Array<InputMaybe<GpLayerFormulaItemIn>>>;
  M?: InputMaybe<Scalars['Long']>;
  O?: InputMaybe<Scalars['Long']>;
  P?: InputMaybe<Array<InputMaybe<GpPaletteIn>>>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
}

/** Элемент формулы, описывающий вклад конкретного слоя в расчет. */
export interface GpLayerFormulaItem {
  __typename?: 'GPLayerFormulaItem';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  max: Maybe<Scalars['Long']>;
  min: Maybe<Scalars['Long']>;
  title: Maybe<Scalars['String']>;
  weight: Maybe<Scalars['Long']>;
}

export interface GpLayerFormulaItemBatchOperationIn {
  model?: InputMaybe<GpLayerFormulaItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpLayerFormulaItemIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  max?: InputMaybe<Scalars['Long']>;
  min?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Long']>;
}

export interface GpLayerFormulaItemList {
  __typename?: 'GPLayerFormulaItemList';
  data: Maybe<Array<Maybe<GpLayerFormulaItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpLayerFormulaItemMutation {
  __typename?: 'GPLayerFormulaItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayerFormulaItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayerFormulaItem>;
}


export interface GpLayerFormulaItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayerFormulaItemBatchOperationIn>>>;
}


export interface GpLayerFormulaItemMutationCreateArgs {
  data?: InputMaybe<GpLayerFormulaItemIn>;
}


export interface GpLayerFormulaItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayerFormulaItemMutationUpdateArgs {
  data?: InputMaybe<GpLayerFormulaItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayerFormulaItemQuery {
  __typename?: 'GPLayerFormulaItemQuery';
  get: Maybe<GpLayerFormulaItem>;
  list: Maybe<GpLayerFormulaItemList>;
}


export interface GpLayerFormulaItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayerFormulaItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayerFormulaItemWhereIn>;
}

export interface GpLayerFormulaItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerFormulaItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayerFormulaItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerFormulaItemWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  max?: InputMaybe<GraphqlLongBoolExpression>;
  min?: InputMaybe<GraphqlLongBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  weight?: InputMaybe<GraphqlLongBoolExpression>;
}

export interface GpLayerFormulaLWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerFormulaLWhereIn>>>;
  _not?: InputMaybe<GpLayerFormulaLWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerFormulaLWhereIn>>>;
  on_GPLayerFormulaItem?: InputMaybe<GpLayerFormulaItemWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerFormulaList {
  __typename?: 'GPLayerFormulaList';
  data: Maybe<Array<Maybe<GpLayerFormula>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpLayerFormulaMutation {
  __typename?: 'GPLayerFormulaMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayerFormula>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayerFormula>;
}


export interface GpLayerFormulaMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayerFormulaBatchOperationIn>>>;
}


export interface GpLayerFormulaMutationCreateArgs {
  data?: InputMaybe<GpLayerFormulaIn>;
}


export interface GpLayerFormulaMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayerFormulaMutationUpdateArgs {
  data?: InputMaybe<GpLayerFormulaIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayerFormulaPWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerFormulaPWhereIn>>>;
  _not?: InputMaybe<GpLayerFormulaPWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerFormulaPWhereIn>>>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerFormulaQuery {
  __typename?: 'GPLayerFormulaQuery';
  get: Maybe<GpLayerFormula>;
  list: Maybe<GpLayerFormulaList>;
}


export interface GpLayerFormulaQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayerFormulaQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayerFormulaWhereIn>;
}

export interface GpLayerFormulaWhereIn {
  L?: InputMaybe<GpLayerFormulaLWhereIn>;
  M?: InputMaybe<GraphqlLongBoolExpression>;
  O?: InputMaybe<GraphqlLongBoolExpression>;
  P?: InputMaybe<GpLayerFormulaPWhereIn>;
  _and?: InputMaybe<Array<InputMaybe<GpLayerFormulaWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayerFormulaWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerFormulaWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

export interface GpLayerIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  geoType?: InputMaybe<UuidRefInput>;
  id?: InputMaybe<Scalars['String']>;
  onSale?: InputMaybe<Scalars['Boolean']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  photos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  price?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  recommendedRecipes?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  schedules?: InputMaybe<Array<InputMaybe<GpScheduleIn>>>;
  semanticTerms?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  similarLayers?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  supplier?: InputMaybe<Scalars['String']>;
  territory?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
  uNameKey?: InputMaybe<Scalars['String']>;
  updatePeriod?: InputMaybe<UuidRefInput>;
}

export interface GpLayerList {
  __typename?: 'GPLayerList';
  data: Maybe<Array<Maybe<GpLayer>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

/** GP-GEO: Слой с геохостинга - Слой на основе uri с геохостинга (соответствует опубликованному на геохостинге слою). Фасетное уникальное имя (артикул): ATST_DTS_MSKV_2021Q1_V1 Наименование: [Автомобилисты]_[Москва - 2021Q1 - v1] */
export interface GpLayerMap {
  __typename?: 'GPLayerMap';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  distribution: Maybe<Array<Maybe<GpDistribution>>>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  layerOptions: Maybe<Scalars['LayerOptions']>;
  passport: Maybe<Scalars['Attachment']>;
  title: Maybe<Scalars['String']>;
  uName: Maybe<Scalars['String']>;
  uri: Maybe<Scalars['String']>;
}

export interface GpLayerMapBatchOperationIn {
  model?: InputMaybe<GpLayerMapIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpLayerMapDistributionWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerMapDistributionWhereIn>>>;
  _not?: InputMaybe<GpLayerMapDistributionWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerMapDistributionWhereIn>>>;
  on_GPDistribution?: InputMaybe<GpDistributionWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerMapIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  distribution?: InputMaybe<Array<InputMaybe<GpDistributionIn>>>;
  id?: InputMaybe<Scalars['String']>;
  layerOptions?: InputMaybe<Scalars['LayerOptions']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  title?: InputMaybe<Scalars['String']>;
  uName?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
}

export interface GpLayerMapList {
  __typename?: 'GPLayerMapList';
  data: Maybe<Array<Maybe<GpLayerMap>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpLayerMapMutation {
  __typename?: 'GPLayerMapMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayerMap>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayerMap>;
}


export interface GpLayerMapMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayerMapBatchOperationIn>>>;
}


export interface GpLayerMapMutationCreateArgs {
  data?: InputMaybe<GpLayerMapIn>;
}


export interface GpLayerMapMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayerMapMutationUpdateArgs {
  data?: InputMaybe<GpLayerMapIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayerMapQuery {
  __typename?: 'GPLayerMapQuery';
  get: Maybe<GpLayerMap>;
  list: Maybe<GpLayerMapList>;
}


export interface GpLayerMapQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayerMapQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayerMapWhereIn>;
}

export interface GpLayerMapWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerMapWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayerMapWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerMapWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  distribution?: InputMaybe<GpLayerMapDistributionWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uName?: InputMaybe<GraphqlStringBoolExpression>;
  uri?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerMutation {
  __typename?: 'GPLayerMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayer>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayer>;
}


export interface GpLayerMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayerBatchOperationIn>>>;
}


export interface GpLayerMutationCreateArgs {
  data?: InputMaybe<GpLayerIn>;
}


export interface GpLayerMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayerMutationUpdateArgs {
  data?: InputMaybe<GpLayerIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayerPoi {
  __typename?: 'GPLayerPOI';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  poiList: Maybe<Array<Maybe<GpGeoItem>>>;
  title: Maybe<Scalars['String']>;
}

export interface GpLayerPoiBatchOperationIn {
  model?: InputMaybe<GpLayerPoiIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpLayerPoiIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  poiList?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpLayerPoiList {
  __typename?: 'GPLayerPOIList';
  data: Maybe<Array<Maybe<GpLayerPoi>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpLayerPoiMutation {
  __typename?: 'GPLayerPOIMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayerPoi>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayerPoi>;
}


export interface GpLayerPoiMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayerPoiBatchOperationIn>>>;
}


export interface GpLayerPoiMutationCreateArgs {
  data?: InputMaybe<GpLayerPoiIn>;
}


export interface GpLayerPoiMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayerPoiMutationUpdateArgs {
  data?: InputMaybe<GpLayerPoiIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayerPoiQuery {
  __typename?: 'GPLayerPOIQuery';
  get: Maybe<GpLayerPoi>;
  list: Maybe<GpLayerPoiList>;
}


export interface GpLayerPoiQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayerPoiQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayerPoiWhereIn>;
}

export interface GpLayerPoiWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerPoiWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayerPoiWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerPoiWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  poiList?: InputMaybe<GpGeoItemWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerQuery {
  __typename?: 'GPLayerQuery';
  get: Maybe<GpLayer>;
  list: Maybe<GpLayerList>;
}


export interface GpLayerQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayerQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayerWhereIn>;
}

export interface GpLayerSchedulesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerSchedulesWhereIn>>>;
  _not?: InputMaybe<GpLayerSchedulesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerSchedulesWhereIn>>>;
  on_GPSchedule?: InputMaybe<GpScheduleWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayerWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayerWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayerWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayerWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  geoType?: InputMaybe<GpGeoTypeWhereIn>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlStringBoolExpression>;
  productFeatures?: InputMaybe<GpProductFeatureWhereIn>;
  recommendedRecipes?: InputMaybe<GpRecipeWhereIn>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  schedules?: InputMaybe<GpLayerSchedulesWhereIn>;
  semanticTerms?: InputMaybe<GpSemanticCoreItemWhereIn>;
  supplier?: InputMaybe<GraphqlStringBoolExpression>;
  territory?: InputMaybe<GpTerritoriesWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uNameKey?: InputMaybe<GraphqlStringBoolExpression>;
  updatePeriod?: InputMaybe<GpUpdatePeriodWhereIn>;
}

/** Список слоев доступный в конкретном контексте (списке для визуализации). */
export interface GpLayersList {
  __typename?: 'GPLayersList';
  /** это список слоев подложки */
  bg: Maybe<Array<Maybe<GpuiLayer>>>;
  cid: Maybe<Scalars['UUID']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  ov: Maybe<Array<Maybe<GpuiLayer>>>;
}

export interface GpLayersListBatchOperationIn {
  model?: InputMaybe<GpLayersListIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpLayersListBgWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayersListBgWhereIn>>>;
  _not?: InputMaybe<GpLayersListBgWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayersListBgWhereIn>>>;
  on_GPUILayer?: InputMaybe<GpuiLayerWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayersListIn {
  bg?: InputMaybe<Array<InputMaybe<GpuiLayerIn>>>;
  cid?: InputMaybe<Scalars['UUID']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  ov?: InputMaybe<Array<InputMaybe<GpuiLayerIn>>>;
}

export interface GpLayersListList {
  __typename?: 'GPLayersListList';
  data: Maybe<Array<Maybe<GpLayersList>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpLayersListMutation {
  __typename?: 'GPLayersListMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpLayersList>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpLayersList>;
}


export interface GpLayersListMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpLayersListBatchOperationIn>>>;
}


export interface GpLayersListMutationCreateArgs {
  data?: InputMaybe<GpLayersListIn>;
}


export interface GpLayersListMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpLayersListMutationUpdateArgs {
  data?: InputMaybe<GpLayersListIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpLayersListOvWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayersListOvWhereIn>>>;
  _not?: InputMaybe<GpLayersListOvWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayersListOvWhereIn>>>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpLayersListQuery {
  __typename?: 'GPLayersListQuery';
  get: Maybe<GpLayersList>;
  list: Maybe<GpLayersListList>;
}


export interface GpLayersListQueryGetArgs {
  id: Scalars['String'];
}


export interface GpLayersListQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpLayersListWhereIn>;
}

export interface GpLayersListWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpLayersListWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpLayersListWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpLayersListWhereIn>>>;
  bg?: InputMaybe<GpLayersListBgWhereIn>;
  cid?: InputMaybe<GraphqlUuidBoolExpression>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  ov?: InputMaybe<GpLayersListOvWhereIn>;
}

/** Сообщение с ленты новостей */
export interface GpNewsMessage {
  __typename?: 'GPNewsMessage';
  brief: Maybe<Scalars['Wysiwyg']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  dateTime: Maybe<Scalars['DateTime']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  imgs: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle: Maybe<Scalars['String']>;
  text: Maybe<Scalars['Wysiwyg']>;
  title: Maybe<Scalars['String']>;
}

export interface GpNewsMessageBatchOperationIn {
  model?: InputMaybe<GpNewsMessageIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpNewsMessageIn {
  brief?: InputMaybe<Scalars['Wysiwyg']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  dateTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imgs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['Wysiwyg']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpNewsMessageList {
  __typename?: 'GPNewsMessageList';
  data: Maybe<Array<Maybe<GpNewsMessage>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpNewsMessageMutation {
  __typename?: 'GPNewsMessageMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpNewsMessage>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpNewsMessage>;
}


export interface GpNewsMessageMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpNewsMessageBatchOperationIn>>>;
}


export interface GpNewsMessageMutationCreateArgs {
  data?: InputMaybe<GpNewsMessageIn>;
}


export interface GpNewsMessageMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpNewsMessageMutationUpdateArgs {
  data?: InputMaybe<GpNewsMessageIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpNewsMessageQuery {
  __typename?: 'GPNewsMessageQuery';
  get: Maybe<GpNewsMessage>;
  list: Maybe<GpNewsMessageList>;
}


export interface GpNewsMessageQueryGetArgs {
  id: Scalars['String'];
}


export interface GpNewsMessageQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpNewsMessageWhereIn>;
}

export interface GpNewsMessageWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpNewsMessageWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpNewsMessageWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpNewsMessageWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  dateTime?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  subtitle?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Заказ (а также корзина до оплаты) */
export interface GpOrder {
  __typename?: 'GPOrder';
  billUri: Maybe<Scalars['String']>;
  cmscreatedby: Maybe<Scalars['String']>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  dateTime: Maybe<Scalars['DateTime']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['String']>;
  orderItems: Maybe<Array<Maybe<GpOrderItem>>>;
  paymentId: Maybe<Scalars['String']>;
  profile: Maybe<GpCustomerProfile>;
  status: Maybe<OrderStatuses>;
  user: Maybe<User>;
}

export interface GpOrderBatchOperationIn {
  model?: InputMaybe<GpOrderIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpOrderIn {
  billUri?: InputMaybe<Scalars['String']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  dateTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['String']>;
  orderItems?: InputMaybe<Array<InputMaybe<GpOrderItemIn>>>;
  paymentId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<UuidRefInput>;
  status?: InputMaybe<OrderStatuses>;
  user?: InputMaybe<UuidRefInput>;
}

/** Элемент позиции заказа. */
export interface GpOrderItem {
  __typename?: 'GPOrderItem';
  /** ссылка на пользователя, добавившего позицию в заказ */
  cmscreatedby: Maybe<User>;
  /** дата и время добавления позиции в заказ */
  cmscreationdate: Maybe<Scalars['DateTime']>;
  /** признак видимости позиции в заказе через АПИ (опубликованность) */
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** порядковый номер позиции заказа (для сортировки) */
  num: Maybe<Scalars['Long']>;
  /** слепок категории продукта на момент добавления позиции в заказ */
  productCategory: Maybe<Array<Maybe<ProductCategoryUnion>>>;
  /** слепок приобретенной позиции (с учетом скидок и индивидуальных условий) */
  productPackaging: Maybe<Array<Maybe<ProductPackagingUnion>>>;
  /** признак "выбран" для работы UI */
  selFlag: Maybe<Scalars['Boolean']>;
}

export interface GpOrderItemBatchOperationIn {
  model?: InputMaybe<GpOrderItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpOrderItemIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  productCategory?: InputMaybe<Array<InputMaybe<GpOrderItemProductCategoryIn>>>;
  productPackaging?: InputMaybe<Array<InputMaybe<GpOrderItemProductPackagingIn>>>;
  selFlag?: InputMaybe<Scalars['Boolean']>;
}

export interface GpOrderItemList {
  __typename?: 'GPOrderItemList';
  data: Maybe<Array<Maybe<GpOrderItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpOrderItemMutation {
  __typename?: 'GPOrderItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpOrderItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpOrderItem>;
}


export interface GpOrderItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpOrderItemBatchOperationIn>>>;
}


export interface GpOrderItemMutationCreateArgs {
  data?: InputMaybe<GpOrderItemIn>;
}


export interface GpOrderItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpOrderItemMutationUpdateArgs {
  data?: InputMaybe<GpOrderItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpOrderItemProductCategoryIn {
  on_GPLayerIn?: InputMaybe<GpLayerIn>;
  on_GPStatDatasetIn?: InputMaybe<GpStatDatasetIn>;
}

export interface GpOrderItemProductCategoryWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrderItemProductCategoryWhereIn>>>;
  _not?: InputMaybe<GpOrderItemProductCategoryWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrderItemProductCategoryWhereIn>>>;
  on_GPLayer?: InputMaybe<GpLayerWhereIn>;
  on_GPStatDataset?: InputMaybe<GpStatDatasetWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpOrderItemProductPackagingIn {
  on_GPDSScheduleIn?: InputMaybe<GpdsScheduleIn>;
  on_GPScheduleIn?: InputMaybe<GpScheduleIn>;
}

export interface GpOrderItemProductPackagingWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrderItemProductPackagingWhereIn>>>;
  _not?: InputMaybe<GpOrderItemProductPackagingWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrderItemProductPackagingWhereIn>>>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpOrderItemQuery {
  __typename?: 'GPOrderItemQuery';
  get: Maybe<GpOrderItem>;
  list: Maybe<GpOrderItemList>;
}


export interface GpOrderItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpOrderItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpOrderItemWhereIn>;
}

export interface GpOrderItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrderItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpOrderItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrderItemWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  productCategory?: InputMaybe<GpOrderItemProductCategoryWhereIn>;
  productPackaging?: InputMaybe<GpOrderItemProductPackagingWhereIn>;
}

export interface GpOrderList {
  __typename?: 'GPOrderList';
  data: Maybe<Array<Maybe<GpOrder>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpOrderMutation {
  __typename?: 'GPOrderMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpOrder>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpOrder>;
}


export interface GpOrderMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpOrderBatchOperationIn>>>;
}


export interface GpOrderMutationCreateArgs {
  data?: InputMaybe<GpOrderIn>;
}


export interface GpOrderMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpOrderMutationUpdateArgs {
  data?: InputMaybe<GpOrderIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpOrderOrderItemsWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrderOrderItemsWhereIn>>>;
  _not?: InputMaybe<GpOrderOrderItemsWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrderOrderItemsWhereIn>>>;
  on_GPOrderItem?: InputMaybe<GpOrderItemWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpOrderPriceQuery {
  __typename?: 'GPOrderPriceQuery';
  get: Maybe<Price>;
}


export interface GpOrderPriceQueryGetArgs {
  codeText?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
}

export interface GpOrderQuery {
  __typename?: 'GPOrderQuery';
  get: Maybe<GpOrder>;
  list: Maybe<GpOrderList>;
}


export interface GpOrderQueryGetArgs {
  id: Scalars['String'];
}


export interface GpOrderQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpOrderWhereIn>;
}

export interface GpOrderWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrderWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpOrderWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrderWhereIn>>>;
  billUri?: InputMaybe<GraphqlStringBoolExpression>;
  dateTime?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlStringBoolExpression>;
  orderItems?: InputMaybe<GpOrderOrderItemsWhereIn>;
  paymentId?: InputMaybe<GraphqlStringBoolExpression>;
  profile?: InputMaybe<GpCustomerProfileWhereIn>;
  status?: InputMaybe<GraphqlStringBoolExpression>;
  user?: InputMaybe<UserWhereIn>;
}

/** GP-CRM: Организация-клиент - Организация-клиент сервиса (юридическое лицо). */
export interface GpOrganization {
  __typename?: 'GPOrganization';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  inn: Maybe<Scalars['Long']>;
  logoAvatar: Maybe<Scalars['Image']>;
  name: Maybe<Scalars['String']>;
  responsiblePersons: Maybe<Array<Maybe<GpUser>>>;
}

export interface GpOrganizationBatchOperationIn {
  model?: InputMaybe<GpOrganizationIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpOrganizationIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inn?: InputMaybe<Scalars['Long']>;
  logoAvatar?: InputMaybe<Scalars['Image']>;
  name?: InputMaybe<Scalars['String']>;
  responsiblePersons?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
}

export interface GpOrganizationList {
  __typename?: 'GPOrganizationList';
  data: Maybe<Array<Maybe<GpOrganization>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpOrganizationMutation {
  __typename?: 'GPOrganizationMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpOrganization>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpOrganization>;
}


export interface GpOrganizationMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpOrganizationBatchOperationIn>>>;
}


export interface GpOrganizationMutationCreateArgs {
  data?: InputMaybe<GpOrganizationIn>;
}


export interface GpOrganizationMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpOrganizationMutationUpdateArgs {
  data?: InputMaybe<GpOrganizationIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpOrganizationQuery {
  __typename?: 'GPOrganizationQuery';
  get: Maybe<GpOrganization>;
  list: Maybe<GpOrganizationList>;
}


export interface GpOrganizationQueryGetArgs {
  id: Scalars['String'];
}


export interface GpOrganizationQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpOrganizationWhereIn>;
}

export interface GpOrganizationWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpOrganizationWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpOrganizationWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpOrganizationWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  inn?: InputMaybe<GraphqlLongBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpPageConfig {
  __typename?: 'GPPageConfig';
  cmscreatedby: Maybe<Scalars['String']>;
  config: Maybe<Scalars['PageConfig']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  uri: Maybe<Scalars['String']>;
}

export interface GpPageConfigBatchOperationIn {
  model?: InputMaybe<GpPageConfigIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpPageConfigIn {
  config?: InputMaybe<Scalars['PageConfig']>;
  id?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
}

export interface GpPageConfigList {
  __typename?: 'GPPageConfigList';
  data: Maybe<Array<Maybe<GpPageConfig>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpPageConfigMutation {
  __typename?: 'GPPageConfigMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpPageConfig>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpPageConfig>;
}


export interface GpPageConfigMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpPageConfigBatchOperationIn>>>;
}


export interface GpPageConfigMutationCreateArgs {
  data?: InputMaybe<GpPageConfigIn>;
}


export interface GpPageConfigMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpPageConfigMutationUpdateArgs {
  data?: InputMaybe<GpPageConfigIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpPageConfigQuery {
  __typename?: 'GPPageConfigQuery';
  get: Maybe<GpPageConfig>;
  list: Maybe<GpPageConfigList>;
}


export interface GpPageConfigQueryGetArgs {
  id: Scalars['String'];
}


export interface GpPageConfigQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpPageConfigWhereIn>;
}

export interface GpPageConfigWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpPageConfigWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpPageConfigWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpPageConfigWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  uri?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Платные функциональные объекты - функции к которым ограничивается доступ и взымается плата. */
export interface GpPaidFunction {
  __typename?: 'GPPaidFunction';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  uri: Maybe<Scalars['String']>;
}

export interface GpPaidFunctionBatchOperationIn {
  model?: InputMaybe<GpPaidFunctionIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpPaidFunctionIn {
  id?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
}

export interface GpPaidFunctionList {
  __typename?: 'GPPaidFunctionList';
  data: Maybe<Array<Maybe<GpPaidFunction>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpPaidFunctionMutation {
  __typename?: 'GPPaidFunctionMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpPaidFunction>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpPaidFunction>;
}


export interface GpPaidFunctionMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpPaidFunctionBatchOperationIn>>>;
}


export interface GpPaidFunctionMutationCreateArgs {
  data?: InputMaybe<GpPaidFunctionIn>;
}


export interface GpPaidFunctionMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpPaidFunctionMutationUpdateArgs {
  data?: InputMaybe<GpPaidFunctionIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpPaidFunctionQuery {
  __typename?: 'GPPaidFunctionQuery';
  get: Maybe<GpPaidFunction>;
  list: Maybe<GpPaidFunctionList>;
}


export interface GpPaidFunctionQueryGetArgs {
  id: Scalars['String'];
}


export interface GpPaidFunctionQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpPaidFunctionWhereIn>;
}

export interface GpPaidFunctionWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpPaidFunctionWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpPaidFunctionWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpPaidFunctionWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlFloatBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uri?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Параметры палитры для стилизации слоя. */
export interface GpPalette {
  __typename?: 'GPPalette';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpPaletteBatchOperationIn {
  model?: InputMaybe<GpPaletteIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpPaletteIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpPaletteList {
  __typename?: 'GPPaletteList';
  data: Maybe<Array<Maybe<GpPalette>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpPaletteMutation {
  __typename?: 'GPPaletteMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpPalette>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpPalette>;
}


export interface GpPaletteMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpPaletteBatchOperationIn>>>;
}


export interface GpPaletteMutationCreateArgs {
  data?: InputMaybe<GpPaletteIn>;
}


export interface GpPaletteMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpPaletteMutationUpdateArgs {
  data?: InputMaybe<GpPaletteIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpPaletteQuery {
  __typename?: 'GPPaletteQuery';
  get: Maybe<GpPalette>;
  list: Maybe<GpPaletteList>;
}


export interface GpPaletteQueryGetArgs {
  id: Scalars['String'];
}


export interface GpPaletteQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpPaletteWhereIn>;
}

export interface GpPaletteWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpPaletteWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpPaletteWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpPaletteWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-WMS: Товар (базовая карточка) - коммерческий продукт маркетплейса (товар, услуга и тд) по типам. */
export interface GpProduct {
  __typename?: 'GPProduct';
  cmscreatedby: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  product: Maybe<Array<Maybe<ProductUnion>>>;
  productType: Maybe<GpProductTypes>;
}

export interface GpProductBatchOperationIn {
  model?: InputMaybe<GpProductIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProductFeature {
  __typename?: 'GPProductFeature';
  description: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpProductFeatureBatchOperationIn {
  model?: InputMaybe<GpProductFeatureIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProductFeatureIn {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpProductFeatureList {
  __typename?: 'GPProductFeatureList';
  data: Maybe<Array<Maybe<GpProductFeature>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProductFeatureMutation {
  __typename?: 'GPProductFeatureMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProductFeature>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProductFeature>;
}


export interface GpProductFeatureMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProductFeatureBatchOperationIn>>>;
}


export interface GpProductFeatureMutationCreateArgs {
  data?: InputMaybe<GpProductFeatureIn>;
}


export interface GpProductFeatureMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProductFeatureMutationUpdateArgs {
  data?: InputMaybe<GpProductFeatureIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProductFeatureQuery {
  __typename?: 'GPProductFeatureQuery';
  get: Maybe<GpProductFeature>;
  list: Maybe<GpProductFeatureList>;
}


export interface GpProductFeatureQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProductFeatureQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProductFeatureWhereIn>;
}

export interface GpProductFeatureWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProductFeatureWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProductFeatureWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProductFeatureWhereIn>>>;
  description?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProductIn {
  id?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  productType?: InputMaybe<UuidRefInput>;
}

export interface GpProductList {
  __typename?: 'GPProductList';
  data: Maybe<Array<Maybe<GpProduct>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProductMutation {
  __typename?: 'GPProductMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProduct>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProduct>;
}


export interface GpProductMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProductBatchOperationIn>>>;
}


export interface GpProductMutationCreateArgs {
  data?: InputMaybe<GpProductIn>;
}


export interface GpProductMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProductMutationUpdateArgs {
  data?: InputMaybe<GpProductIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProductProductWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProductProductWhereIn>>>;
  _not?: InputMaybe<GpProductProductWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProductProductWhereIn>>>;
  on_GPBIRecipe?: InputMaybe<GpbiRecipeWhereIn>;
  on_GPCommercialService?: InputMaybe<GpCommercialServiceWhereIn>;
  on_GPGeoModel?: InputMaybe<GpGeoModelWhereIn>;
  on_GPLayer?: InputMaybe<GpLayerWhereIn>;
  on_GPRecipe?: InputMaybe<GpRecipeWhereIn>;
  on_GPStatDataset?: InputMaybe<GpStatDatasetWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProductQuery {
  __typename?: 'GPProductQuery';
  get: Maybe<GpProduct>;
  list: Maybe<GpProductList>;
}


export interface GpProductQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProductQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProductWhereIn>;
}

/** GP-WMS: Тип товара - справочник типов товаров. */
export interface GpProductTypes {
  __typename?: 'GPProductTypes';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  /** имя модели, описывающей товар */
  model: Maybe<Scalars['String']>;
  /** наименование типа товара */
  name: Maybe<Scalars['String']>;
}

export interface GpProductTypesBatchOperationIn {
  model?: InputMaybe<GpProductTypesIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProductTypesIn {
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GpProductTypesList {
  __typename?: 'GPProductTypesList';
  data: Maybe<Array<Maybe<GpProductTypes>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProductTypesMutation {
  __typename?: 'GPProductTypesMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProductTypes>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProductTypes>;
}


export interface GpProductTypesMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProductTypesBatchOperationIn>>>;
}


export interface GpProductTypesMutationCreateArgs {
  data?: InputMaybe<GpProductTypesIn>;
}


export interface GpProductTypesMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProductTypesMutationUpdateArgs {
  data?: InputMaybe<GpProductTypesIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProductTypesQuery {
  __typename?: 'GPProductTypesQuery';
  get: Maybe<GpProductTypes>;
  list: Maybe<GpProductTypesList>;
}


export interface GpProductTypesQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProductTypesQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProductTypesWhereIn>;
}

export interface GpProductTypesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProductTypesWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProductTypesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProductTypesWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  model?: InputMaybe<GraphqlStringBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProductWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProductWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProductWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProductWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  product?: InputMaybe<GpProductProductWhereIn>;
  productType?: InputMaybe<GpProductTypesWhereIn>;
}

/** GP-GEO: Проект - Проект, как папка созданная и наполненная по рецепту, дополненная отчетом (и результирующими слоем). */
export interface GpProject {
  __typename?: 'GPProject';
  cmscreatedate: Maybe<Scalars['DateTime']>;
  cmscreatedby: Maybe<Scalars['String']>;
  cmsupdatedate: Maybe<Scalars['DateTime']>;
  complete: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  markersCfg: Maybe<Scalars['ProjectMarkers']>;
  progress: Maybe<Scalars['Long']>;
  recipe: Maybe<Array<Maybe<GpRecipe>>>;
  report: Maybe<Scalars['Attachment']>;
  result: Maybe<Array<Maybe<GpuiLayer>>>;
  title: Maybe<Scalars['String']>;
}

export interface GpProjectBatchOperationIn {
  model?: InputMaybe<GpProjectIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProjectGn {
  __typename?: 'GPProjectGn';
  cmscreatedby: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  project: Maybe<Array<Maybe<ProjectUnion>>>;
  projectType: Maybe<GpProjectTypes>;
}

export interface GpProjectGnBatchOperationIn {
  model?: InputMaybe<GpProjectGnIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProjectGnIn {
  id?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  projectType?: InputMaybe<UuidRefInput>;
}

export interface GpProjectGnList {
  __typename?: 'GPProjectGnList';
  data: Maybe<Array<Maybe<GpProjectGn>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProjectGnMutation {
  __typename?: 'GPProjectGnMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProjectGn>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProjectGn>;
}


export interface GpProjectGnMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProjectGnBatchOperationIn>>>;
}


export interface GpProjectGnMutationCreateArgs {
  data?: InputMaybe<GpProjectGnIn>;
}


export interface GpProjectGnMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProjectGnMutationUpdateArgs {
  data?: InputMaybe<GpProjectGnIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProjectGnProjectWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectGnProjectWhereIn>>>;
  _not?: InputMaybe<GpProjectGnProjectWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectGnProjectWhereIn>>>;
  on_GPBIProject?: InputMaybe<GpbiProjectWhereIn>;
  on_GPProject?: InputMaybe<GpProjectWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProjectGnQuery {
  __typename?: 'GPProjectGnQuery';
  get: Maybe<GpProjectGn>;
  list: Maybe<GpProjectGnList>;
}


export interface GpProjectGnQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProjectGnQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProjectGnWhereIn>;
}

export interface GpProjectGnWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectGnWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProjectGnWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectGnWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  project?: InputMaybe<GpProjectGnProjectWhereIn>;
  projectType?: InputMaybe<GpProjectTypesWhereIn>;
}

export interface GpProjectIn {
  complete?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  markersCfg?: InputMaybe<Scalars['ProjectMarkers']>;
  progress?: InputMaybe<Scalars['Long']>;
  recipe?: InputMaybe<Array<InputMaybe<GpRecipeIn>>>;
  report?: InputMaybe<Scalars['Attachment']>;
  result?: InputMaybe<Array<InputMaybe<GpuiLayerIn>>>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpProjectList {
  __typename?: 'GPProjectList';
  data: Maybe<Array<Maybe<GpProject>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProjectMutation {
  __typename?: 'GPProjectMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProject>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProject>;
}


export interface GpProjectMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProjectBatchOperationIn>>>;
}


export interface GpProjectMutationCreateArgs {
  data?: InputMaybe<GpProjectIn>;
}


export interface GpProjectMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProjectMutationUpdateArgs {
  data?: InputMaybe<GpProjectIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProjectQuery {
  __typename?: 'GPProjectQuery';
  get: Maybe<GpProject>;
  list: Maybe<GpProjectList>;
}


export interface GpProjectQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProjectQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProjectWhereIn>;
}

export interface GpProjectRecipeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectRecipeWhereIn>>>;
  _not?: InputMaybe<GpProjectRecipeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectRecipeWhereIn>>>;
  on_GPRecipe?: InputMaybe<GpRecipeWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProjectResultWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectResultWhereIn>>>;
  _not?: InputMaybe<GpProjectResultWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectResultWhereIn>>>;
  on_GPUILayer?: InputMaybe<GpuiLayerWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProjectTypes {
  __typename?: 'GPProjectTypes';
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  model: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}

export interface GpProjectTypesBatchOperationIn {
  model?: InputMaybe<GpProjectTypesIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpProjectTypesIn {
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GpProjectTypesList {
  __typename?: 'GPProjectTypesList';
  data: Maybe<Array<Maybe<GpProjectTypes>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpProjectTypesMutation {
  __typename?: 'GPProjectTypesMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpProjectTypes>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpProjectTypes>;
}


export interface GpProjectTypesMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpProjectTypesBatchOperationIn>>>;
}


export interface GpProjectTypesMutationCreateArgs {
  data?: InputMaybe<GpProjectTypesIn>;
}


export interface GpProjectTypesMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpProjectTypesMutationUpdateArgs {
  data?: InputMaybe<GpProjectTypesIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpProjectTypesQuery {
  __typename?: 'GPProjectTypesQuery';
  get: Maybe<GpProjectTypes>;
  list: Maybe<GpProjectTypesList>;
}


export interface GpProjectTypesQueryGetArgs {
  id: Scalars['String'];
}


export interface GpProjectTypesQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpProjectTypesWhereIn>;
}

export interface GpProjectTypesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectTypesWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProjectTypesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectTypesWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  model?: InputMaybe<GraphqlStringBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpProjectWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpProjectWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpProjectWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpProjectWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  progress?: InputMaybe<GraphqlLongBoolExpression>;
  recipe?: InputMaybe<GpProjectRecipeWhereIn>;
  result?: InputMaybe<GpProjectResultWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-CRM: Промо коды - Справочник Промо кодов, у каждого кода уникальный текстовый идентификатор + значение на которое надо умножить сумму заказа. Привязки кода к пользователю или товару пока не делаем. Время жизни кода управляется вручную. */
export interface GpPromoCode {
  __typename?: 'GPPromoCode';
  codeText: Maybe<Scalars['String']>;
  codeValue: Maybe<Scalars['Long']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
}

export interface GpPromoCodeBatchOperationIn {
  model?: InputMaybe<GpPromoCodeIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpPromoCodeIn {
  codeText?: InputMaybe<Scalars['String']>;
  codeValue?: InputMaybe<Scalars['Long']>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpPromoCodeList {
  __typename?: 'GPPromoCodeList';
  data: Maybe<Array<Maybe<GpPromoCode>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpPromoCodeMutation {
  __typename?: 'GPPromoCodeMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpPromoCode>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpPromoCode>;
}


export interface GpPromoCodeMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpPromoCodeBatchOperationIn>>>;
}


export interface GpPromoCodeMutationCreateArgs {
  data?: InputMaybe<GpPromoCodeIn>;
}


export interface GpPromoCodeMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpPromoCodeMutationUpdateArgs {
  data?: InputMaybe<GpPromoCodeIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpPromoCodeQuery {
  __typename?: 'GPPromoCodeQuery';
  get: Maybe<GpPromoCode>;
  list: Maybe<GpPromoCodeList>;
}


export interface GpPromoCodeQueryGetArgs {
  id: Scalars['String'];
}


export interface GpPromoCodeQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpPromoCodeWhereIn>;
}

export interface GpPromoCodeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpPromoCodeWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpPromoCodeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpPromoCodeWhereIn>>>;
  codeText?: InputMaybe<GraphqlStringBoolExpression>;
  codeValue?: InputMaybe<GraphqlLongBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

export interface GpPurchaseCompleteQuery {
  __typename?: 'GPPurchaseCompleteQuery';
  start: Maybe<Process>;
}


export interface GpPurchaseCompleteQueryStartArgs {
  variables?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
}

export interface GpPurchaseCompleteStatusQuery {
  __typename?: 'GPPurchaseCompleteStatusQuery';
  get: Maybe<HistoricProcess>;
}


export interface GpPurchaseCompleteStatusQueryGetArgs {
  processId: Scalars['ID'];
}

/** GP-GEO: Рецепт - шаблон для типового ГЕО-проекта. */
export interface GpRecipe {
  __typename?: 'GPRecipe';
  annotation: Maybe<Scalars['Wysiwyg']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['Wysiwyg']>;
  duration: Maybe<Scalars['Long']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  img: Maybe<Scalars['Image']>;
  layers: Maybe<Array<Maybe<GpLayersList>>>;
  recipeValues: Maybe<Array<Maybe<GpRecipeValue>>>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  semanticTerms: Maybe<Array<Maybe<GpSemanticCoreItem>>>;
  steps: Maybe<Array<Maybe<GpRecipeStep>>>;
  title: Maybe<Scalars['String']>;
  usefulLayers: Maybe<Array<Maybe<GpLayer>>>;
}

export interface GpRecipeBatchOperationIn {
  model?: InputMaybe<GpRecipeIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpRecipeIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  duration?: InputMaybe<Scalars['Long']>;
  id?: InputMaybe<Scalars['String']>;
  img?: InputMaybe<Scalars['Image']>;
  layers?: InputMaybe<Array<InputMaybe<GpLayersListIn>>>;
  recipeValues?: InputMaybe<Array<InputMaybe<GpRecipeValueIn>>>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  semanticTerms?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  steps?: InputMaybe<Array<InputMaybe<GpRecipeStepIn>>>;
  title?: InputMaybe<Scalars['String']>;
  usefulLayers?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
}

export interface GpRecipeLayersWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeLayersWhereIn>>>;
  _not?: InputMaybe<GpRecipeLayersWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeLayersWhereIn>>>;
  on_GPLayersList?: InputMaybe<GpLayersListWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpRecipeList {
  __typename?: 'GPRecipeList';
  data: Maybe<Array<Maybe<GpRecipe>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpRecipeMutation {
  __typename?: 'GPRecipeMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpRecipe>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpRecipe>;
}


export interface GpRecipeMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpRecipeBatchOperationIn>>>;
}


export interface GpRecipeMutationCreateArgs {
  data?: InputMaybe<GpRecipeIn>;
}


export interface GpRecipeMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpRecipeMutationUpdateArgs {
  data?: InputMaybe<GpRecipeIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpRecipeQuery {
  __typename?: 'GPRecipeQuery';
  get: Maybe<GpRecipe>;
  list: Maybe<GpRecipeList>;
}


export interface GpRecipeQueryGetArgs {
  id: Scalars['String'];
}


export interface GpRecipeQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpRecipeWhereIn>;
}

export interface GpRecipeRecipeValuesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeRecipeValuesWhereIn>>>;
  _not?: InputMaybe<GpRecipeRecipeValuesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeRecipeValuesWhereIn>>>;
  on_GPRecipeValue?: InputMaybe<GpRecipeValueWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpRecipeStep {
  __typename?: 'GPRecipeStep';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['Wysiwyg']>;
  gallery: Maybe<Array<Maybe<Scalars['String']>>>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
}

export interface GpRecipeStepBatchOperationIn {
  model?: InputMaybe<GpRecipeStepIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpRecipeStepIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  gallery?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpRecipeStepList {
  __typename?: 'GPRecipeStepList';
  data: Maybe<Array<Maybe<GpRecipeStep>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpRecipeStepMutation {
  __typename?: 'GPRecipeStepMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpRecipeStep>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpRecipeStep>;
}


export interface GpRecipeStepMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpRecipeStepBatchOperationIn>>>;
}


export interface GpRecipeStepMutationCreateArgs {
  data?: InputMaybe<GpRecipeStepIn>;
}


export interface GpRecipeStepMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpRecipeStepMutationUpdateArgs {
  data?: InputMaybe<GpRecipeStepIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpRecipeStepQuery {
  __typename?: 'GPRecipeStepQuery';
  get: Maybe<GpRecipeStep>;
  list: Maybe<GpRecipeStepList>;
}


export interface GpRecipeStepQueryGetArgs {
  id: Scalars['String'];
}


export interface GpRecipeStepQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpRecipeStepWhereIn>;
}

export interface GpRecipeStepWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeStepWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpRecipeStepWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeStepWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  subtitle?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpRecipeStepsWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeStepsWhereIn>>>;
  _not?: InputMaybe<GpRecipeStepsWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeStepsWhereIn>>>;
  on_GPRecipeStep?: InputMaybe<GpRecipeStepWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpRecipeValue {
  __typename?: 'GPRecipeValue';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  title: Maybe<Scalars['String']>;
  wording: Maybe<Scalars['Wysiwyg']>;
}

export interface GpRecipeValueBatchOperationIn {
  model?: InputMaybe<GpRecipeValueIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpRecipeValueIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
  wording?: InputMaybe<Scalars['Wysiwyg']>;
}

export interface GpRecipeValueList {
  __typename?: 'GPRecipeValueList';
  data: Maybe<Array<Maybe<GpRecipeValue>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpRecipeValueMutation {
  __typename?: 'GPRecipeValueMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpRecipeValue>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpRecipeValue>;
}


export interface GpRecipeValueMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpRecipeValueBatchOperationIn>>>;
}


export interface GpRecipeValueMutationCreateArgs {
  data?: InputMaybe<GpRecipeValueIn>;
}


export interface GpRecipeValueMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpRecipeValueMutationUpdateArgs {
  data?: InputMaybe<GpRecipeValueIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpRecipeValueQuery {
  __typename?: 'GPRecipeValueQuery';
  get: Maybe<GpRecipeValue>;
  list: Maybe<GpRecipeValueList>;
}


export interface GpRecipeValueQueryGetArgs {
  id: Scalars['String'];
}


export interface GpRecipeValueQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpRecipeValueWhereIn>;
}

export interface GpRecipeValueWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeValueWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpRecipeValueWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeValueWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpRecipeWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRecipeWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpRecipeWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRecipeWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  duration?: InputMaybe<GraphqlLongBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  layers?: InputMaybe<GpRecipeLayersWhereIn>;
  recipeValues?: InputMaybe<GpRecipeRecipeValuesWhereIn>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  steps?: InputMaybe<GpRecipeStepsWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-INT: Рубрика классификатора - рубрика классификатора (для товаров). возможно подойдет и для рецептов и иных сущностей (но может потребуется выделить отдельные). */
export interface GpRubric {
  __typename?: 'GPRubric';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  parent: Maybe<GpRubric>;
  title: Maybe<Scalars['String']>;
}

export interface GpRubricBatchOperationIn {
  model?: InputMaybe<GpRubricIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpRubricIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpRubricList {
  __typename?: 'GPRubricList';
  data: Maybe<Array<Maybe<GpRubric>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpRubricMutation {
  __typename?: 'GPRubricMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpRubric>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpRubric>;
}


export interface GpRubricMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpRubricBatchOperationIn>>>;
}


export interface GpRubricMutationCreateArgs {
  data?: InputMaybe<GpRubricIn>;
}


export interface GpRubricMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpRubricMutationUpdateArgs {
  data?: InputMaybe<GpRubricIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpRubricQuery {
  __typename?: 'GPRubricQuery';
  get: Maybe<GpRubric>;
  list: Maybe<GpRubricList>;
}


export interface GpRubricQueryGetArgs {
  id: Scalars['String'];
}


export interface GpRubricQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpRubricWhereIn>;
}

export interface GpRubricWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpRubricWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpRubricWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpRubricWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** Интервал актуальности данных слоя (как товара в маркетплейсе) + ссылка на физику слоя + цена набора данных + паспорт набора данных. */
export interface GpSchedule {
  __typename?: 'GPSchedule';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  composeDate: Maybe<Scalars['DateTime']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  layer: Maybe<GpLayerMap>;
  onSale: Maybe<Scalars['Boolean']>;
  price: Maybe<Scalars['Long']>;
  releaseDate: Maybe<Scalars['DateTime']>;
  title: Maybe<Scalars['String']>;
}

export interface GpScheduleBatchOperationIn {
  model?: InputMaybe<GpScheduleIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpScheduleIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  composeDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  layer?: InputMaybe<UuidRefInput>;
  onSale?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Long']>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpScheduleList {
  __typename?: 'GPScheduleList';
  data: Maybe<Array<Maybe<GpSchedule>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpScheduleMutation {
  __typename?: 'GPScheduleMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpSchedule>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpSchedule>;
}


export interface GpScheduleMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpScheduleBatchOperationIn>>>;
}


export interface GpScheduleMutationCreateArgs {
  data?: InputMaybe<GpScheduleIn>;
}


export interface GpScheduleMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpScheduleMutationUpdateArgs {
  data?: InputMaybe<GpScheduleIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpScheduleQuery {
  __typename?: 'GPScheduleQuery';
  get: Maybe<GpSchedule>;
  list: Maybe<GpScheduleList>;
}


export interface GpScheduleQueryGetArgs {
  id: Scalars['String'];
}


export interface GpScheduleQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpScheduleWhereIn>;
}

export interface GpScheduleWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpScheduleWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpScheduleWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpScheduleWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  composeDate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  layer?: InputMaybe<GpLayerMapWhereIn>;
  price?: InputMaybe<GraphqlLongBoolExpression>;
  releaseDate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpSemanticCoreItem {
  __typename?: 'GPSemanticCoreItem';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  definition: Maybe<Scalars['Text']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  term: Maybe<Scalars['String']>;
}

export interface GpSemanticCoreItemBatchOperationIn {
  model?: InputMaybe<GpSemanticCoreItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpSemanticCoreItemIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  definition?: InputMaybe<Scalars['Text']>;
  id?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
}

export interface GpSemanticCoreItemList {
  __typename?: 'GPSemanticCoreItemList';
  data: Maybe<Array<Maybe<GpSemanticCoreItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpSemanticCoreItemMutation {
  __typename?: 'GPSemanticCoreItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpSemanticCoreItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpSemanticCoreItem>;
}


export interface GpSemanticCoreItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpSemanticCoreItemBatchOperationIn>>>;
}


export interface GpSemanticCoreItemMutationCreateArgs {
  data?: InputMaybe<GpSemanticCoreItemIn>;
}


export interface GpSemanticCoreItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpSemanticCoreItemMutationUpdateArgs {
  data?: InputMaybe<GpSemanticCoreItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpSemanticCoreItemQuery {
  __typename?: 'GPSemanticCoreItemQuery';
  get: Maybe<GpSemanticCoreItem>;
  list: Maybe<GpSemanticCoreItemList>;
}


export interface GpSemanticCoreItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpSemanticCoreItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpSemanticCoreItemWhereIn>;
}

export interface GpSemanticCoreItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpSemanticCoreItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpSemanticCoreItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpSemanticCoreItemWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  term?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpStatDataset {
  __typename?: 'GPStatDataset';
  annotation: Maybe<Scalars['Wysiwyg']>;
  description: Maybe<Scalars['Wysiwyg']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  passport: Maybe<Scalars['Attachment']>;
  photos: Maybe<Array<Maybe<Scalars['String']>>>;
  price: Maybe<Scalars['String']>;
  productFeatures: Maybe<Array<Maybe<GpProductFeature>>>;
  rubrics: Maybe<Array<Maybe<GpRubric>>>;
  schedules: Maybe<Array<Maybe<GpdsSchedule>>>;
  supplier: Maybe<Scalars['String']>;
  territory: Maybe<GpTerritories>;
  title: Maybe<Scalars['String']>;
  uNameKey: Maybe<Scalars['String']>;
  updatePeriod: Maybe<GpUpdatePeriod>;
}

export interface GpStatDatasetBatchOperationIn {
  model?: InputMaybe<GpStatDatasetIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpStatDatasetIn {
  annotation?: InputMaybe<Scalars['Wysiwyg']>;
  description?: InputMaybe<Scalars['Wysiwyg']>;
  id?: InputMaybe<Scalars['String']>;
  passport?: InputMaybe<Scalars['Attachment']>;
  photos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  price?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  rubrics?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  schedules?: InputMaybe<Array<InputMaybe<GpdsScheduleIn>>>;
  supplier?: InputMaybe<Scalars['String']>;
  territory?: InputMaybe<UuidRefInput>;
  title?: InputMaybe<Scalars['String']>;
  uNameKey?: InputMaybe<Scalars['String']>;
  updatePeriod?: InputMaybe<UuidRefInput>;
}

export interface GpStatDatasetList {
  __typename?: 'GPStatDatasetList';
  data: Maybe<Array<Maybe<GpStatDataset>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpStatDatasetMutation {
  __typename?: 'GPStatDatasetMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpStatDataset>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpStatDataset>;
}


export interface GpStatDatasetMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpStatDatasetBatchOperationIn>>>;
}


export interface GpStatDatasetMutationCreateArgs {
  data?: InputMaybe<GpStatDatasetIn>;
}


export interface GpStatDatasetMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpStatDatasetMutationUpdateArgs {
  data?: InputMaybe<GpStatDatasetIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpStatDatasetQuery {
  __typename?: 'GPStatDatasetQuery';
  get: Maybe<GpStatDataset>;
  list: Maybe<GpStatDatasetList>;
}


export interface GpStatDatasetQueryGetArgs {
  id: Scalars['String'];
}


export interface GpStatDatasetQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpStatDatasetWhereIn>;
}

export interface GpStatDatasetSchedulesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpStatDatasetSchedulesWhereIn>>>;
  _not?: InputMaybe<GpStatDatasetSchedulesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpStatDatasetSchedulesWhereIn>>>;
  on_GPDSSchedule?: InputMaybe<GpdsScheduleWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpStatDatasetWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpStatDatasetWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpStatDatasetWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpStatDatasetWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  price?: InputMaybe<GraphqlStringBoolExpression>;
  rubrics?: InputMaybe<GpRubricWhereIn>;
  schedules?: InputMaybe<GpStatDatasetSchedulesWhereIn>;
  supplier?: InputMaybe<GraphqlStringBoolExpression>;
  territory?: InputMaybe<GpTerritoriesWhereIn>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  uNameKey?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-CRM: Тарифный план - Тарифный план (описывает возможности пользователя в рамках бюджета/лимита). */
export interface GpTariffPlan {
  __typename?: 'GPTariffPlan';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  price: Maybe<Scalars['Long']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  valuesList: Maybe<Array<Maybe<GpTariffPlanItem>>>;
}

export interface GpTariffPlanBatchOperationIn {
  model?: InputMaybe<GpTariffPlanIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpTariffPlanIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  price?: InputMaybe<Scalars['Long']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  valuesList?: InputMaybe<Array<InputMaybe<GpTariffPlanItemIn>>>;
}

/** GP-INT: Элемент тарифного плана - Элемент тарифного плана, описывающий конкретную ценность (формирует описательный список признаков тарифного плана). */
export interface GpTariffPlanItem {
  __typename?: 'GPTariffPlanItem';
  active: Maybe<Scalars['Boolean']>;
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  title: Maybe<Scalars['String']>;
}

export interface GpTariffPlanItemBatchOperationIn {
  model?: InputMaybe<GpTariffPlanItemIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpTariffPlanItemIn {
  active?: InputMaybe<Scalars['Boolean']>;
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpTariffPlanItemList {
  __typename?: 'GPTariffPlanItemList';
  data: Maybe<Array<Maybe<GpTariffPlanItem>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpTariffPlanItemMutation {
  __typename?: 'GPTariffPlanItemMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpTariffPlanItem>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpTariffPlanItem>;
}


export interface GpTariffPlanItemMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpTariffPlanItemBatchOperationIn>>>;
}


export interface GpTariffPlanItemMutationCreateArgs {
  data?: InputMaybe<GpTariffPlanItemIn>;
}


export interface GpTariffPlanItemMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpTariffPlanItemMutationUpdateArgs {
  data?: InputMaybe<GpTariffPlanItemIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpTariffPlanItemQuery {
  __typename?: 'GPTariffPlanItemQuery';
  get: Maybe<GpTariffPlanItem>;
  list: Maybe<GpTariffPlanItemList>;
}


export interface GpTariffPlanItemQueryGetArgs {
  id: Scalars['String'];
}


export interface GpTariffPlanItemQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpTariffPlanItemWhereIn>;
}

export interface GpTariffPlanItemWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpTariffPlanItemWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpTariffPlanItemWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpTariffPlanItemWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpTariffPlanList {
  __typename?: 'GPTariffPlanList';
  data: Maybe<Array<Maybe<GpTariffPlan>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpTariffPlanMutation {
  __typename?: 'GPTariffPlanMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpTariffPlan>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpTariffPlan>;
}


export interface GpTariffPlanMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpTariffPlanBatchOperationIn>>>;
}


export interface GpTariffPlanMutationCreateArgs {
  data?: InputMaybe<GpTariffPlanIn>;
}


export interface GpTariffPlanMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpTariffPlanMutationUpdateArgs {
  data?: InputMaybe<GpTariffPlanIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpTariffPlanQuery {
  __typename?: 'GPTariffPlanQuery';
  get: Maybe<GpTariffPlan>;
  list: Maybe<GpTariffPlanList>;
}


export interface GpTariffPlanQueryGetArgs {
  id: Scalars['String'];
}


export interface GpTariffPlanQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpTariffPlanWhereIn>;
}

export interface GpTariffPlanValuesListWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpTariffPlanValuesListWhereIn>>>;
  _not?: InputMaybe<GpTariffPlanValuesListWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpTariffPlanValuesListWhereIn>>>;
  on_GPTariffPlanItem?: InputMaybe<GpTariffPlanItemWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpTariffPlanWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpTariffPlanWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpTariffPlanWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpTariffPlanWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  price?: InputMaybe<GraphqlLongBoolExpression>;
  subtitle?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
  valuesList?: InputMaybe<GpTariffPlanValuesListWhereIn>;
}

/** GP-INT: Территория - Справочник территорий двойного назначения: 1) для атрибуции слоев, 2) для выбора границ TOI. */
export interface GpTerritories {
  __typename?: 'GPTerritories';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  fcode: Maybe<Scalars['String']>;
  geometryWKT: Maybe<Scalars['Text']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
}

export interface GpTerritoriesBatchOperationIn {
  model?: InputMaybe<GpTerritoriesIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpTerritoriesIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  fcode?: InputMaybe<Scalars['String']>;
  geometryWKT?: InputMaybe<Scalars['Text']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GpTerritoriesList {
  __typename?: 'GPTerritoriesList';
  data: Maybe<Array<Maybe<GpTerritories>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpTerritoriesMutation {
  __typename?: 'GPTerritoriesMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpTerritories>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpTerritories>;
}


export interface GpTerritoriesMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpTerritoriesBatchOperationIn>>>;
}


export interface GpTerritoriesMutationCreateArgs {
  data?: InputMaybe<GpTerritoriesIn>;
}


export interface GpTerritoriesMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpTerritoriesMutationUpdateArgs {
  data?: InputMaybe<GpTerritoriesIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpTerritoriesQuery {
  __typename?: 'GPTerritoriesQuery';
  get: Maybe<GpTerritories>;
  list: Maybe<GpTerritoriesList>;
}


export interface GpTerritoriesQueryGetArgs {
  id: Scalars['String'];
}


export interface GpTerritoriesQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpTerritoriesWhereIn>;
}

export interface GpTerritoriesWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpTerritoriesWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpTerritoriesWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpTerritoriesWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  fcode?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

/** component type */
export interface GpuiDataset {
  __typename?: 'GPUIDataset';
  ds: Maybe<Array<Maybe<GpdsScheduleDb>>>;
  name: Maybe<Scalars['String']>;
}

export interface GpuiDatasetDsWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiDatasetDsWhereIn>>>;
  _not?: InputMaybe<GpuiDatasetDsWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiDatasetDsWhereIn>>>;
  on_GPDSScheduleDB?: InputMaybe<GpdsScheduleDbWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpuiDatasetIn {
  ds?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  name?: InputMaybe<Scalars['String']>;
}

export interface GpuiDatasetWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiDatasetWhereIn>>>;
  _not?: InputMaybe<GpuiDatasetWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiDatasetWhereIn>>>;
  ds?: InputMaybe<GpuiDatasetDsWhereIn>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
}

/** component type */
export interface GpuiLayer {
  __typename?: 'GPUILayer';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  g: Maybe<Scalars['Float']>;
  h: Maybe<Scalars['Long']>;
  id: Maybe<Scalars['String']>;
  layer: Maybe<Array<Maybe<LayerUnion>>>;
  layerEm: Maybe<Array<Maybe<GpLayerFormula>>>;
  n: Maybe<Scalars['String']>;
  o: Maybe<Scalars['Float']>;
  on: Maybe<Scalars['Boolean']>;
  p: Maybe<Array<Maybe<GpPalette>>>;
  tmpLayerTypename: Maybe<TmpLayerTypenames>;
}

export interface GpuiLayerIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  g?: InputMaybe<Scalars['Float']>;
  h?: InputMaybe<Scalars['Long']>;
  id?: InputMaybe<Scalars['String']>;
  layer?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  layerEm?: InputMaybe<Array<InputMaybe<GpLayerFormulaIn>>>;
  n?: InputMaybe<Scalars['String']>;
  o?: InputMaybe<Scalars['Float']>;
  on?: InputMaybe<Scalars['Boolean']>;
  p?: InputMaybe<Array<InputMaybe<GpPaletteIn>>>;
  tmpLayerTypename?: InputMaybe<TmpLayerTypenames>;
}

export interface GpuiLayerLayerEmWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiLayerLayerEmWhereIn>>>;
  _not?: InputMaybe<GpuiLayerLayerEmWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiLayerLayerEmWhereIn>>>;
  on_GPLayerFormula?: InputMaybe<GpLayerFormulaWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpuiLayerLayerWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiLayerLayerWhereIn>>>;
  _not?: InputMaybe<GpuiLayerLayerWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiLayerLayerWhereIn>>>;
  on_GPLayerPOI?: InputMaybe<GpLayerPoiWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpuiLayerPWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiLayerPWhereIn>>>;
  _not?: InputMaybe<GpuiLayerPWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiLayerPWhereIn>>>;
  on_GPPalette?: InputMaybe<GpPaletteWhereIn>;
  typename?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface GpuiLayerWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpuiLayerWhereIn>>>;
  _not?: InputMaybe<GpuiLayerWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpuiLayerWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  g?: InputMaybe<GraphqlFloatBoolExpression>;
  h?: InputMaybe<GraphqlLongBoolExpression>;
  id?: InputMaybe<GraphqlStringBoolExpression>;
  layer?: InputMaybe<GpuiLayerLayerWhereIn>;
  layerEm?: InputMaybe<GpuiLayerLayerEmWhereIn>;
  n?: InputMaybe<GraphqlStringBoolExpression>;
  o?: InputMaybe<GraphqlFloatBoolExpression>;
  p?: InputMaybe<GpuiLayerPWhereIn>;
  tmpLayerTypename?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-INT: Период обновления - Справочник периодов обновления товаров в маркетплейсе (характеризует актуальность товара). */
export interface GpUpdatePeriod {
  __typename?: 'GPUpdatePeriod';
  cmscreatedby: Maybe<User>;
  cmscreationdate: Maybe<Scalars['DateTime']>;
  cmspublished: Maybe<Scalars['Boolean']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  num: Maybe<Scalars['Long']>;
  title: Maybe<Scalars['String']>;
}

export interface GpUpdatePeriodBatchOperationIn {
  model?: InputMaybe<GpUpdatePeriodIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpUpdatePeriodIn {
  cmscreatedby?: InputMaybe<UuidRefInput>;
  cmscreationdate?: InputMaybe<Scalars['DateTime']>;
  cmspublished?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Long']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface GpUpdatePeriodList {
  __typename?: 'GPUpdatePeriodList';
  data: Maybe<Array<Maybe<GpUpdatePeriod>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpUpdatePeriodMutation {
  __typename?: 'GPUpdatePeriodMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpUpdatePeriod>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpUpdatePeriod>;
}


export interface GpUpdatePeriodMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpUpdatePeriodBatchOperationIn>>>;
}


export interface GpUpdatePeriodMutationCreateArgs {
  data?: InputMaybe<GpUpdatePeriodIn>;
}


export interface GpUpdatePeriodMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpUpdatePeriodMutationUpdateArgs {
  data?: InputMaybe<GpUpdatePeriodIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpUpdatePeriodQuery {
  __typename?: 'GPUpdatePeriodQuery';
  get: Maybe<GpUpdatePeriod>;
  list: Maybe<GpUpdatePeriodList>;
}


export interface GpUpdatePeriodQueryGetArgs {
  id: Scalars['String'];
}


export interface GpUpdatePeriodQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpUpdatePeriodWhereIn>;
}

export interface GpUpdatePeriodWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpUpdatePeriodWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpUpdatePeriodWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpUpdatePeriodWhereIn>>>;
  cmscreatedby?: InputMaybe<UserWhereIn>;
  cmscreationdate?: InputMaybe<GraphqlDateTimeBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  num?: InputMaybe<GraphqlLongBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

/** GP-CRM: Пользователь-клиент - Прикладное расширение сущности "пользователь" (для разделения областей ответственности между системным и прикладным доменами). */
export interface GpUser {
  __typename?: 'GPUser';
  avatar: Maybe<Scalars['Image']>;
  email: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  organizations: Maybe<Array<Maybe<GpOrganization>>>;
  user: Maybe<User>;
}

export interface GpUserBatchOperationIn {
  model?: InputMaybe<GpUserIn>;
  type?: InputMaybe<OperationType>;
}

export interface GpUserIn {
  avatar?: InputMaybe<Scalars['Image']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<Array<InputMaybe<UuidRefInput>>>;
  user?: InputMaybe<UuidRefInput>;
}

export interface GpUserList {
  __typename?: 'GPUserList';
  data: Maybe<Array<Maybe<GpUser>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface GpUserMutation {
  __typename?: 'GPUserMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<GpUser>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<GpUser>;
}


export interface GpUserMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<GpUserBatchOperationIn>>>;
}


export interface GpUserMutationCreateArgs {
  data?: InputMaybe<GpUserIn>;
}


export interface GpUserMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface GpUserMutationUpdateArgs {
  data?: InputMaybe<GpUserIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface GpUserQuery {
  __typename?: 'GPUserQuery';
  get: Maybe<GpUser>;
  list: Maybe<GpUserList>;
}


export interface GpUserQueryGetArgs {
  id: Scalars['String'];
}


export interface GpUserQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<GpUserWhereIn>;
}

export interface GpUserWhereIn {
  _and?: InputMaybe<Array<InputMaybe<GpUserWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<GpUserWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<GpUserWhereIn>>>;
  email?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  name?: InputMaybe<GraphqlStringBoolExpression>;
  organizations?: InputMaybe<GpOrganizationWhereIn>;
  user?: InputMaybe<UserWhereIn>;
}

export interface Geometry {
  __typename?: 'Geometry';
  coordinates: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>>>;
  type: Maybe<Scalars['String']>;
}

export interface GetFeatureParams {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  count?: InputMaybe<Scalars['Int']>;
  featureID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featureVersion?: InputMaybe<Scalars['String']>;
  maxFeatures?: InputMaybe<Scalars['Int']>;
  propertyNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rect?: InputMaybe<BoundingBox>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  srsName?: InputMaybe<Scalars['String']>;
  typeNames: Array<InputMaybe<Scalars['String']>>;
}

export interface GetFeatureResponse {
  __typename?: 'GetFeatureResponse';
  bbox: Maybe<Array<Maybe<Scalars['Float']>>>;
  features: Maybe<Array<Maybe<Feature>>>;
  numberMatched: Maybe<Scalars['Int']>;
  numberReturned: Maybe<Scalars['Int']>;
  timeStamp: Maybe<Scalars['String']>;
  totalFeatures: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
}

export interface GraphqlDateTimeBoolExpression {
  _eq?: InputMaybe<Scalars['DateTime']>;
  _gt?: InputMaybe<Scalars['DateTime']>;
  _gte?: InputMaybe<Scalars['DateTime']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['DateTime']>;
  _lte?: InputMaybe<Scalars['DateTime']>;
  _neq?: InputMaybe<Scalars['DateTime']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
}

export interface GraphqlFloatBoolExpression {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
}

export interface GraphqlLongBoolExpression {
  _eq?: InputMaybe<Scalars['Long']>;
  _gt?: InputMaybe<Scalars['Long']>;
  _gte?: InputMaybe<Scalars['Long']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Long']>;
  _lte?: InputMaybe<Scalars['Long']>;
  _neq?: InputMaybe<Scalars['Long']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
}

export interface GraphqlStringBoolExpression {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface GraphqlUuidBoolExpression {
  _eq?: InputMaybe<Scalars['UUID']>;
  _gt?: InputMaybe<Scalars['UUID']>;
  _gte?: InputMaybe<Scalars['UUID']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['UUID']>;
  _lte?: InputMaybe<Scalars['UUID']>;
  _neq?: InputMaybe<Scalars['UUID']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
}

export interface HistoricProcess {
  __typename?: 'HistoricProcess';
  id: Scalars['ID'];
  state: State;
}

export interface History {
  __typename?: 'History';
  entityId: Maybe<Scalars['String']>;
  entityName: Maybe<Scalars['String']>;
  entries: Maybe<Array<Maybe<HistoryEntry>>>;
}

export interface HistoryEntry {
  __typename?: 'HistoryEntry';
  date: Maybe<Scalars['DateTime']>;
  entityId: Maybe<Scalars['String']>;
  entityName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  operationType: Maybe<EventType>;
  payload: Maybe<Scalars['Json']>;
  userId: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
}

export interface InputCheckAccessParams {
  entities?: InputMaybe<Array<InputMaybe<InputRef>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  securityIdentityId?: InputMaybe<Scalars['String']>;
}

export interface InputOrder {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
}

export interface InputRef {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}

export interface JComment {
  __typename?: 'JComment';
  id: Maybe<Scalars['UUID']>;
  j_comment: Maybe<Scalars['String']>;
  j_comment_type: Maybe<Scalars['String']>;
  j_created_at: Maybe<Scalars['DateTime']>;
  j_created_by: Maybe<Scalars['String']>;
  j_version: Maybe<Scalars['UUID']>;
}

export interface JCommentBatchOperationIn {
  model?: InputMaybe<JCommentIn>;
  type?: InputMaybe<OperationType>;
}

export interface JCommentIn {
  id?: InputMaybe<Scalars['String']>;
  j_comment?: InputMaybe<Scalars['String']>;
  j_comment_type?: InputMaybe<Scalars['String']>;
  j_created_at?: InputMaybe<Scalars['DateTime']>;
  j_version?: InputMaybe<Scalars['UUID']>;
}

export interface JCommentList {
  __typename?: 'JCommentList';
  data: Maybe<Array<Maybe<JComment>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface JCommentMutation {
  __typename?: 'JCommentMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<JComment>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<JComment>;
}


export interface JCommentMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<JCommentBatchOperationIn>>>;
}


export interface JCommentMutationCreateArgs {
  data?: InputMaybe<JCommentIn>;
}


export interface JCommentMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface JCommentMutationUpdateArgs {
  data?: InputMaybe<JCommentIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface JCommentQuery {
  __typename?: 'JCommentQuery';
  get: Maybe<JComment>;
  list: Maybe<JCommentList>;
}


export interface JCommentQueryGetArgs {
  id: Scalars['String'];
}


export interface JCommentQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<JCommentWhereIn>;
}

export interface JCommentWhereIn {
  _and?: InputMaybe<Array<InputMaybe<JCommentWhereIn>>>;
  _not?: InputMaybe<JCommentWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<JCommentWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  j_comment?: InputMaybe<GraphqlStringBoolExpression>;
  j_comment_type?: InputMaybe<GraphqlStringBoolExpression>;
  j_created_at?: InputMaybe<GraphqlDateTimeBoolExpression>;
  j_version?: InputMaybe<GraphqlUuidBoolExpression>;
}

export interface JEmbed {
  __typename?: 'JEmbed';
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  fields: Maybe<Scalars['Json']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface JEmbedBatchOperationIn {
  model?: InputMaybe<JEmbedIn>;
  type?: InputMaybe<OperationType>;
}

export interface JEmbedIn {
  description?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Scalars['Json']>;
  title?: InputMaybe<Scalars['String']>;
}

export interface JEmbedList {
  __typename?: 'JEmbedList';
  data: Maybe<Array<Maybe<JEmbed>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface JEmbedMutation {
  __typename?: 'JEmbedMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<JEmbed>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<JEmbed>;
}


export interface JEmbedMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<JEmbedBatchOperationIn>>>;
}


export interface JEmbedMutationCreateArgs {
  data?: InputMaybe<JEmbedIn>;
}


export interface JEmbedMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface JEmbedMutationUpdateArgs {
  data?: InputMaybe<JEmbedIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface JEmbedQuery {
  __typename?: 'JEmbedQuery';
  get: Maybe<JEmbed>;
  list: Maybe<JEmbedList>;
}


export interface JEmbedQueryGetArgs {
  id: Scalars['String'];
}


export interface JEmbedQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<JEmbedWhereIn>;
}

export interface JEmbedWhereIn {
  _and?: InputMaybe<Array<InputMaybe<JEmbedWhereIn>>>;
  _not?: InputMaybe<JEmbedWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<JEmbedWhereIn>>>;
  description?: InputMaybe<GraphqlStringBoolExpression>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface JForm {
  __typename?: 'JForm';
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  formDescription: Maybe<Scalars['Json']>;
  formKey: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface JFormBatchOperationIn {
  model?: InputMaybe<JFormIn>;
  type?: InputMaybe<OperationType>;
}

export interface JFormIn {
  formDescription?: InputMaybe<Scalars['Json']>;
  formKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}

export interface JFormList {
  __typename?: 'JFormList';
  data: Maybe<Array<Maybe<JForm>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface JFormMutation {
  __typename?: 'JFormMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<JForm>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<JForm>;
}


export interface JFormMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<JFormBatchOperationIn>>>;
}


export interface JFormMutationCreateArgs {
  data?: InputMaybe<JFormIn>;
}


export interface JFormMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface JFormMutationUpdateArgs {
  data?: InputMaybe<JFormIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface JFormQuery {
  __typename?: 'JFormQuery';
  get: Maybe<JForm>;
  list: Maybe<JFormList>;
}


export interface JFormQueryGetArgs {
  id: Scalars['String'];
}


export interface JFormQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<JFormWhereIn>;
}

export interface JFormWhereIn {
  _and?: InputMaybe<Array<InputMaybe<JFormWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<JFormWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<JFormWhereIn>>>;
  formKey?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

export interface JHandbook {
  __typename?: 'JHandbook';
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  values: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface JHandbookBatchOperationIn {
  model?: InputMaybe<JHandbookIn>;
  type?: InputMaybe<OperationType>;
}

export interface JHandbookIn {
  title?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface JHandbookList {
  __typename?: 'JHandbookList';
  data: Maybe<Array<Maybe<JHandbook>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface JHandbookMutation {
  __typename?: 'JHandbookMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<JHandbook>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<JHandbook>;
}


export interface JHandbookMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<JHandbookBatchOperationIn>>>;
}


export interface JHandbookMutationCreateArgs {
  data?: InputMaybe<JHandbookIn>;
}


export interface JHandbookMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface JHandbookMutationUpdateArgs {
  data?: InputMaybe<JHandbookIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface JHandbookQuery {
  __typename?: 'JHandbookQuery';
  get: Maybe<JHandbook>;
  list: Maybe<JHandbookList>;
}


export interface JHandbookQueryGetArgs {
  id: Scalars['String'];
}


export interface JHandbookQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<JHandbookWhereIn>;
}

export interface JHandbookWhereIn {
  _and?: InputMaybe<Array<InputMaybe<JHandbookWhereIn>>>;
  _not?: InputMaybe<JHandbookWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<JHandbookWhereIn>>>;
  title?: InputMaybe<GraphqlStringBoolExpression>;
}

export interface KeyValuePairInput {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface Layer {
  __typename?: 'Layer';
  id: Maybe<Scalars['ID']>;
  title: Maybe<Scalars['String']>;
  uName: Maybe<Scalars['String']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  Action: Maybe<ActionMutation>;
  Config: Maybe<ConfigMutation>;
  DbSettings: Maybe<DbSettingsMutation>;
  DirectGrantAccess: Maybe<DirectGrantAccessMutation>;
  EntityConfig: Maybe<EntityConfigMutation>;
  GPAcl: Maybe<GpAclMutation>;
  GPBIProject: Maybe<GpbiProjectMutation>;
  GPBIRecipe: Maybe<GpbiRecipeMutation>;
  GPCmsPage: Maybe<GpCmsPageMutation>;
  GPCmsPageBlock: Maybe<GpCmsPageBlockMutation>;
  GPCmsPageBlockItem: Maybe<GpCmsPageBlockItemMutation>;
  GPCmsPageExtension: Maybe<GpCmsPageExtensionMutation>;
  GPCommercialService: Maybe<GpCommercialServiceMutation>;
  GPCommercialServiceValue: Maybe<GpCommercialServiceValueMutation>;
  GPCustomerProfile: Maybe<GpCustomerProfileMutation>;
  GPDSSchedule: Maybe<GpdsScheduleMutation>;
  GPDSScheduleDB: Maybe<GpdsScheduleDbMutation>;
  GPDataset: Maybe<GpDatasetMutation>;
  GPDatasetsList: Maybe<GpDatasetsListMutation>;
  GPDistribution: Maybe<GpDistributionMutation>;
  GPDoc: Maybe<GpDocMutation>;
  GPGeoItem: Maybe<GpGeoItemMutation>;
  GPGeoModel: Maybe<GpGeoModelMutation>;
  GPGeoType: Maybe<GpGeoTypeMutation>;
  GPLayer: Maybe<GpLayerMutation>;
  GPLayerFormula: Maybe<GpLayerFormulaMutation>;
  GPLayerFormulaItem: Maybe<GpLayerFormulaItemMutation>;
  GPLayerMap: Maybe<GpLayerMapMutation>;
  GPLayerPOI: Maybe<GpLayerPoiMutation>;
  GPLayersList: Maybe<GpLayersListMutation>;
  GPNewsMessage: Maybe<GpNewsMessageMutation>;
  GPOrder: Maybe<GpOrderMutation>;
  GPOrderItem: Maybe<GpOrderItemMutation>;
  GPOrganization: Maybe<GpOrganizationMutation>;
  GPPageConfig: Maybe<GpPageConfigMutation>;
  GPPaidFunction: Maybe<GpPaidFunctionMutation>;
  GPPalette: Maybe<GpPaletteMutation>;
  GPProduct: Maybe<GpProductMutation>;
  GPProductFeature: Maybe<GpProductFeatureMutation>;
  GPProductTypes: Maybe<GpProductTypesMutation>;
  GPProject: Maybe<GpProjectMutation>;
  GPProjectGn: Maybe<GpProjectGnMutation>;
  GPProjectTypes: Maybe<GpProjectTypesMutation>;
  GPPromoCode: Maybe<GpPromoCodeMutation>;
  GPPurchaseComplete: Maybe<GpPurchaseCompleteQuery>;
  GPRecipe: Maybe<GpRecipeMutation>;
  GPRecipeStep: Maybe<GpRecipeStepMutation>;
  GPRecipeValue: Maybe<GpRecipeValueMutation>;
  GPRubric: Maybe<GpRubricMutation>;
  GPSchedule: Maybe<GpScheduleMutation>;
  GPSemanticCoreItem: Maybe<GpSemanticCoreItemMutation>;
  GPStatDataset: Maybe<GpStatDatasetMutation>;
  GPTariffPlan: Maybe<GpTariffPlanMutation>;
  GPTariffPlanItem: Maybe<GpTariffPlanItemMutation>;
  GPTerritories: Maybe<GpTerritoriesMutation>;
  GPUpdatePeriod: Maybe<GpUpdatePeriodMutation>;
  GPUser: Maybe<GpUserMutation>;
  JComment: Maybe<JCommentMutation>;
  JEmbed: Maybe<JEmbedMutation>;
  JForm: Maybe<JFormMutation>;
  JHandbook: Maybe<JHandbookMutation>;
  S3Config: Maybe<S3ConfigMutation>;
  User: Maybe<UserMutation>;
}

export enum Operation {
  Autocomplete = 'AUTOCOMPLETE',
  Create = 'CREATE',
  Delete = 'DELETE',
  FieldsVisibility = 'FIELDS_VISIBILITY',
  Get = 'GET',
  List = 'LIST',
  Read = 'READ',
  Table = 'TABLE',
  Update = 'UPDATE'
}

export interface OperationAccessCheckResult {
  __typename?: 'OperationAccessCheckResult';
  entity: Maybe<Ref>;
  isAvailable: Maybe<Scalars['Boolean']>;
  operationType: Maybe<OperationType>;
}

export enum OperationType {
  Autocomplete = 'AUTOCOMPLETE',
  Create = 'CREATE',
  Delete = 'DELETE',
  FieldsVisibility = 'FIELDS_VISIBILITY',
  Get = 'GET',
  List = 'LIST',
  Read = 'READ',
  Table = 'TABLE',
  Update = 'UPDATE'
}

export interface OrderItem {
  __typename?: 'OrderItem';
  discount: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['ID']>;
  layer: Maybe<Layer>;
  price: Maybe<Scalars['Float']>;
}

export interface PkPaymentStateQuery {
  __typename?: 'PKPaymentStateQuery';
  get: Maybe<Array<Maybe<Payment>>>;
}


export interface PkPaymentStateQueryGetArgs {
  id: Scalars['ID'];
}

export interface Payment {
  __typename?: 'Payment';
  clientid: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  obtain_datetime: Maybe<Scalars['String']>;
  orderid: Maybe<Scalars['String']>;
  pay_amount: Maybe<Scalars['String']>;
  payment_system_id: Maybe<Scalars['String']>;
  pending_datetime: Maybe<Scalars['String']>;
  refund_amount: Maybe<Scalars['String']>;
  repeat_counter: Maybe<Scalars['String']>;
  site_description: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  success_datetime: Maybe<Scalars['String']>;
  system_description: Maybe<Scalars['String']>;
  unique_id: Maybe<Scalars['String']>;
}

export interface Price {
  __typename?: 'Price';
  discount: Maybe<Scalars['Float']>;
  discountPercent: Maybe<Scalars['Int']>;
  orderId: Maybe<Scalars['ID']>;
  orderItems: Maybe<Array<Maybe<OrderItem>>>;
  price: Maybe<Scalars['Float']>;
}

export interface Process {
  __typename?: 'Process';
  id: Scalars['ID'];
  isEnded: Scalars['Boolean'];
}

export interface Properties {
  __typename?: 'Properties';
  name: Maybe<Scalars['String']>;
  val: Maybe<Scalars['Float']>;
}

export interface Query {
  __typename?: 'Query';
  Action: Maybe<ActionQuery>;
  Config: Maybe<ConfigQuery>;
  DbSettings: Maybe<DbSettingsQuery>;
  DirectGrantAccess: Maybe<DirectGrantAccessQuery>;
  EntityConfig: Maybe<EntityConfigQuery>;
  GPAcl: Maybe<GpAclQuery>;
  GPBIProject: Maybe<GpbiProjectQuery>;
  GPBIRecipe: Maybe<GpbiRecipeQuery>;
  GPCmsPage: Maybe<GpCmsPageQuery>;
  GPCmsPageBlock: Maybe<GpCmsPageBlockQuery>;
  GPCmsPageBlockItem: Maybe<GpCmsPageBlockItemQuery>;
  GPCmsPageExtension: Maybe<GpCmsPageExtensionQuery>;
  GPCommercialService: Maybe<GpCommercialServiceQuery>;
  GPCommercialServiceValue: Maybe<GpCommercialServiceValueQuery>;
  GPCustomerProfile: Maybe<GpCustomerProfileQuery>;
  GPDSSchedule: Maybe<GpdsScheduleQuery>;
  GPDSScheduleDB: Maybe<GpdsScheduleDbQuery>;
  GPDataset: Maybe<GpDatasetQuery>;
  GPDatasetsList: Maybe<GpDatasetsListQuery>;
  GPDistribution: Maybe<GpDistributionQuery>;
  GPDoc: Maybe<GpDocQuery>;
  GPGeoItem: Maybe<GpGeoItemQuery>;
  GPGeoModel: Maybe<GpGeoModelQuery>;
  GPGeoType: Maybe<GpGeoTypeQuery>;
  GPGeoserverGetFeature: Maybe<GpGeoserverGetFeatureQuery>;
  GPLayer: Maybe<GpLayerQuery>;
  GPLayerFormula: Maybe<GpLayerFormulaQuery>;
  GPLayerFormulaItem: Maybe<GpLayerFormulaItemQuery>;
  GPLayerMap: Maybe<GpLayerMapQuery>;
  GPLayerPOI: Maybe<GpLayerPoiQuery>;
  GPLayersList: Maybe<GpLayersListQuery>;
  GPNewsMessage: Maybe<GpNewsMessageQuery>;
  GPOrder: Maybe<GpOrderQuery>;
  GPOrderItem: Maybe<GpOrderItemQuery>;
  GPOrderPrice: Maybe<GpOrderPriceQuery>;
  GPOrganization: Maybe<GpOrganizationQuery>;
  GPPageConfig: Maybe<GpPageConfigQuery>;
  GPPaidFunction: Maybe<GpPaidFunctionQuery>;
  GPPalette: Maybe<GpPaletteQuery>;
  GPProduct: Maybe<GpProductQuery>;
  GPProductFeature: Maybe<GpProductFeatureQuery>;
  GPProductTypes: Maybe<GpProductTypesQuery>;
  GPProject: Maybe<GpProjectQuery>;
  GPProjectGn: Maybe<GpProjectGnQuery>;
  GPProjectTypes: Maybe<GpProjectTypesQuery>;
  GPPromoCode: Maybe<GpPromoCodeQuery>;
  GPPurchaseCompleteStatus: Maybe<GpPurchaseCompleteStatusQuery>;
  GPRecipe: Maybe<GpRecipeQuery>;
  GPRecipeStep: Maybe<GpRecipeStepQuery>;
  GPRecipeValue: Maybe<GpRecipeValueQuery>;
  GPRubric: Maybe<GpRubricQuery>;
  GPSchedule: Maybe<GpScheduleQuery>;
  GPSemanticCoreItem: Maybe<GpSemanticCoreItemQuery>;
  GPStatDataset: Maybe<GpStatDatasetQuery>;
  GPTariffPlan: Maybe<GpTariffPlanQuery>;
  GPTariffPlanItem: Maybe<GpTariffPlanItemQuery>;
  GPTerritories: Maybe<GpTerritoriesQuery>;
  GPUpdatePeriod: Maybe<GpUpdatePeriodQuery>;
  GPUser: Maybe<GpUserQuery>;
  JComment: Maybe<JCommentQuery>;
  JEmbed: Maybe<JEmbedQuery>;
  JForm: Maybe<JFormQuery>;
  JHandbook: Maybe<JHandbookQuery>;
  PKPaymentState: Maybe<PkPaymentStateQuery>;
  S3Config: Maybe<S3ConfigQuery>;
  User: Maybe<UserQuery>;
  history: Maybe<History>;
  historyEntry: Maybe<HistoryEntry>;
}


export interface QueryHistoryArgs {
  entityId?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
}


export interface QueryHistoryEntryArgs {
  id?: InputMaybe<Scalars['String']>;
}

export interface Ref {
  __typename?: 'Ref';
  id: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
}

export enum Role {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAuthor = 'ROLE_AUTHOR',
  RoleConstructor = 'ROLE_CONSTRUCTOR',
  RoleGuest = 'ROLE_GUEST',
  RoleSecadmin = 'ROLE_SECADMIN',
  RoleSuperUser = 'ROLE_SUPER_USER',
  RoleTuzBpm = 'ROLE_TUZ_BPM',
  RoleUser = 'ROLE_USER'
}

export interface S3Config {
  __typename?: 'S3Config';
  accessKey: Maybe<Scalars['String']>;
  alias: Maybe<Scalars['String']>;
  bucketName: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  endpointUrl: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  secretKey: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
}

export interface S3ConfigBatchOperationIn {
  model?: InputMaybe<S3ConfigIn>;
  type?: InputMaybe<OperationType>;
}

export interface S3ConfigIn {
  accessKey?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  bucketName?: InputMaybe<Scalars['String']>;
  endpointUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  secretKey?: InputMaybe<Scalars['String']>;
}

export interface S3ConfigList {
  __typename?: 'S3ConfigList';
  data: Maybe<Array<Maybe<S3Config>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface S3ConfigMutation {
  __typename?: 'S3ConfigMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  create: Maybe<S3Config>;
  delete: Maybe<Scalars['Void']>;
  update: Maybe<S3Config>;
}


export interface S3ConfigMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<S3ConfigBatchOperationIn>>>;
}


export interface S3ConfigMutationCreateArgs {
  data?: InputMaybe<S3ConfigIn>;
}


export interface S3ConfigMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface S3ConfigMutationUpdateArgs {
  data?: InputMaybe<S3ConfigIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface S3ConfigQuery {
  __typename?: 'S3ConfigQuery';
  get: Maybe<S3Config>;
  list: Maybe<S3ConfigList>;
}


export interface S3ConfigQueryGetArgs {
  id: Scalars['String'];
}


export interface S3ConfigQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<S3ConfigWhereIn>;
}

export interface S3ConfigWhereIn {
  _and?: InputMaybe<Array<InputMaybe<S3ConfigWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<S3ConfigWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<S3ConfigWhereIn>>>;
  alias?: InputMaybe<GraphqlStringBoolExpression>;
  bucketName?: InputMaybe<GraphqlStringBoolExpression>;
  endpointUrl?: InputMaybe<GraphqlStringBoolExpression>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
}

export enum State {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Delegated = 'DELEGATED',
  New = 'NEW',
  Unassigned = 'UNASSIGNED'
}

export interface UuidRefInput {
  id: Scalars['UUID'];
  typename: Scalars['String'];
}

export interface User {
  __typename?: 'User';
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  /** entity primary key */
  id: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  roles: Maybe<Array<Maybe<Role>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
}

export interface UserBatchOperationIn {
  model?: InputMaybe<UserIn>;
  type?: InputMaybe<OperationType>;
}

export interface UserIn {
  id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Role>>>;
  username?: InputMaybe<Scalars['String']>;
}

export interface UserList {
  __typename?: 'UserList';
  data: Maybe<Array<Maybe<User>>>;
  hasMore: Maybe<Scalars['Boolean']>;
  totalCount: Maybe<Scalars['Int']>;
}

export interface UserMutation {
  __typename?: 'UserMutation';
  batchUpdate: Maybe<Scalars['Void']>;
  confirmRegistration: Maybe<AuthToken>;
  create: Maybe<User>;
  delete: Maybe<Scalars['Void']>;
  registration: Maybe<Scalars['Void']>;
  update: Maybe<User>;
}


export interface UserMutationBatchUpdateArgs {
  operations?: InputMaybe<Array<InputMaybe<UserBatchOperationIn>>>;
}


export interface UserMutationConfirmRegistrationArgs {
  code?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}


export interface UserMutationCreateArgs {
  data?: InputMaybe<UserIn>;
}


export interface UserMutationDeleteArgs {
  id?: InputMaybe<Scalars['String']>;
}


export interface UserMutationRegistrationArgs {
  phone?: InputMaybe<Scalars['String']>;
}


export interface UserMutationUpdateArgs {
  data?: InputMaybe<UserIn>;
  id?: InputMaybe<Scalars['String']>;
}

export interface UserQuery {
  __typename?: 'UserQuery';
  get: Maybe<User>;
  list: Maybe<UserList>;
  login: Maybe<AuthToken>;
  subprocessExample: Maybe<Scalars['Json']>;
  supplyCurrentUserId: Maybe<Scalars['Json']>;
}


export interface UserQueryGetArgs {
  id: Scalars['String'];
}


export interface UserQueryListArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InputOrder>>>;
  where?: InputMaybe<UserWhereIn>;
}


export interface UserQueryLoginArgs {
  password: Scalars['String'];
  username: Scalars['String'];
}

export interface UserWhereIn {
  _and?: InputMaybe<Array<InputMaybe<UserWhereIn>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _not?: InputMaybe<UserWhereIn>;
  _or?: InputMaybe<Array<InputMaybe<UserWhereIn>>>;
  id?: InputMaybe<GraphqlUuidBoolExpression>;
  username?: InputMaybe<GraphqlStringBoolExpression>;
}

export type AclObjectUnion = GpCmsPage | GpCmsPageBlock | GpCmsPageExtension | GpCommercialService | GpdsScheduleDb | GpGeoModel | GpLayer | GpLayerMap | GpLayerPoi | GpOrganization | GpPaidFunction | GpProject | GpProjectGn | GpStatDataset;

export type LayerUnion = GpLayerMap | GpLayerPoi;

export enum OrderStatuses {
  Created = 'CREATED',
  Obtained = 'OBTAINED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Refunding = 'REFUNDING',
  Stuck = 'STUCK',
  Success = 'SUCCESS'
}

export type ProductCategoryUnion = GpLayer | GpStatDataset;

export type ProductPackagingUnion = GpdsSchedule | GpSchedule;

export type ProductUnion = GpbiRecipe | GpCommercialService | GpGeoModel | GpLayer | GpRecipe | GpStatDataset;

export type ProjectUnion = GpbiProject | GpProject;

export enum TmpLayerTypenames {
  GpLayerFormula = 'GPLayerFormula',
  GpLayerMap = 'GPLayerMap',
  GpLayerPoi = 'GPLayerPOI'
}

import { Box, Pagination as MuiPagination } from '@mui/material';
import { FC } from 'react';
import styles from './Pagination.module.scss';

interface PaginationProps {
  page: number;
  pageCount: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;

}
const Pagination:FC<PaginationProps> = ({ page, pageCount, handlePageChange }) => (
  <Box display="flex">
    <MuiPagination
      page={page}
      count={pageCount}
      onChange={handlePageChange}
      showFirstButton
      showLastButton
      className={styles.pagination}
    />
  </Box>
);

export default Pagination;

import {
  Box, Stack, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ResponseError } from '../../../../models/common';
import ProjectCard from './components/ProjectCard/ProjectCard';
import { activeAccountId$, getEmptyBiProjectId, getEmptyProjectId } from '../../../../state/context/auth.repository';
import {
  ExtendedGPProjectGn,
  projects$,
  projectsRequestPending$,
  projectsRequestStatus$,
  projectsTotalCount$,
} from '../../../../state/project/project.repository';
import { layerDatasetSchedulesPending$ } from '../../../../state/acl/acl.repository';
import { getActiveTerritoryId, getMissingScheduleWithLayer } from '../../../../state/geoData/recipes.repository';
import { PROJECTS_PAGE_SIZE_COUNT } from '../../../../utils/constants';
import Search from '../../../../blocks/Search/Search';
import { setActiveRecipeAsync, setActiveRecipeTerritoryAsync } from '../../../../state/geoData/recipes.effects';
import { DrawerContentEnum, DrawerContext } from '../../../../drawerContext';
import { fetchProjects, getLayerDatasetSchedule } from '../../../../state/project/project.actions';

import useCreateNewProject from '../../../../hooks/useCreateNewProject';
import { ProjectType } from '../../../../models/project';
import {
  GpProject,
  GpLayer,
  GpStatDataset,
} from '../../../../api/types';
import ProjectCreate from './components/ProjectCreate/ProjectCreate';
import { fetchProjectTypes } from '../../../../state/filters/filters.effects';
import { activeProjectTypesIds$ } from '../../../../state/filters/filters.repository';
import { getLayerMapDatasetFromRecipes, getLayersFromProject } from './ProjectsHelpers';

import styles from './Projects.module.scss';
import Loader from '../../../../components/Loader/Loader';
import Pagination from '../../../../components/Pagination/Pagination';
import usePageAndSearch from '../../../../hooks/usePageAndSearch';

interface ProjectsListProps {
  products: (GpLayer | GpStatDataset)[];
  projects: ExtendedGPProjectGn[];
}

const ProjectsList: FC<ProjectsListProps> = ({ products, projects }) => (
  <>
    {projects
      .filter((p) => p.project[0])
      .map((p) => (
        <ProjectCard
          key={p.id}
          id={p.id}
          title={p.project[0].title}
          updateDate={(p.project[0] as GpProject).cmsupdatedate || null}
          projectType={p.projectType}
          layersOrDatasets={getLayersFromProject(p.project[0])}
          products={products}
        />
      ))}
  </>
);

const ProjectsCatalog = () => {
  const { t } = useTranslation('common');

  const [products, setProducts] = useState<(GpLayer | GpStatDataset)[]>([]);
  const [layerDatasetSchedulesPending] = useObservable(layerDatasetSchedulesPending$);
  const [projectsRequestPending] = useObservable(projectsRequestPending$);
  const [projectsRequestStatus] = useObservable(projectsRequestStatus$);
  const [projectsTotalCount] = useObservable(projectsTotalCount$);
  const [projects] = useObservable(projects$);
  const [activeAccountId] = useObservable(activeAccountId$);
  const [activeProjectTypeIds] = useObservable(activeProjectTypesIds$);
  const drawerContext = useContext(DrawerContext);
  const createNewProject = useCreateNewProject();

  const {
    page,
    pageCount,
    inputSearch,
    handlePageChange,
    setPage,
    setPageCount,
    setInputSearch,
  } = usePageAndSearch();

  const isLoading = (
    projectsRequestPending || layerDatasetSchedulesPending
  );

  useEffect(
    () => setPageCount(Math.ceil(projectsTotalCount / PROJECTS_PAGE_SIZE_COUNT)),
    [projectsTotalCount],
  );

  useEffect(
    () => {
      void (async () => {
        if (projects.length) {
          const recipes = projects.map((p) => p.project[0].recipe[0]);
          const commodities = getLayerMapDatasetFromRecipes(recipes);
          const { schedules } = await getLayerDatasetSchedule(commodities, '');
          setProducts(schedules as (GpLayer | GpStatDataset)[]);
        }
      })();
    },
    [JSON.stringify(projects)],
  );

  useEffect(
    () => {
      if (page === 1) {
        void fetchProjects(page - 1, inputSearch, activeProjectTypeIds);
      } else {
        setPage(1);
      }
    },
    [inputSearch, activeProjectTypeIds.length, activeAccountId],
  );

  useEffect(() => {
    setProducts([]);
    void fetchProjects(page - 1, inputSearch, activeProjectTypeIds);
  }, [page]);

  useEffect(() => {
    void fetchProjectTypes();
  }, []);

  const handleNewProjectClick = useCallback(async (projectType: ProjectType) => {
    const defualtRecipeId = projectType === ProjectType.GPProject
      ? getEmptyProjectId()
      : getEmptyBiProjectId();
    await setActiveRecipeAsync(defualtRecipeId as string);
    const activeTerritory: string = getActiveTerritoryId();

    if (activeTerritory) {
      await setActiveRecipeTerritoryAsync(activeTerritory);
    }

    const missingLayers = getMissingScheduleWithLayer();
    if (!missingLayers.length) {
      if (projectType === ProjectType.GPProject) {
        await createNewProject(ProjectType.GPProject);
      } else {
        await createNewProject(ProjectType.GPBIProject);
      }
    } else {
      drawerContext.open(DrawerContentEnum.CHOOSE_RECIPE_LAYERS);
    }
  }, []);

  const renderPagination = !isLoading
    ? (
      <Pagination
        page={page}
        pageCount={pageCount}
        handlePageChange={handlePageChange}
      />
    )
    : null;

  return (
    <>
      <Stack direction="row" gap="12px" marginTop="20px">
        <ProjectCreate
          projectType={ProjectType.GPProject}
          onClick={() => handleNewProjectClick(ProjectType.GPProject)}
        />
        <ProjectCreate
          projectType={ProjectType.GPBIProject}
          onClick={() => handleNewProjectClick(ProjectType.GPBIProject)}
        />
      </Stack>

      <Box marginTop="20px">
        <Search
          placeholder={t('search.project')}
          handleInputChange={setInputSearch}
          name="search_project_query"
        />
      </Box>

      <Box className={styles.projectsContainer}>
        {projectsRequestStatus.value === 'error' && (
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Error:
          {' '}
          {(projectsRequestStatus.error as ResponseError).localizedMessage}
        </Typography>
        )}
        { isLoading ? <Loader id="progress-projects" />
          : <ProjectsList products={products} projects={projects} /> }
      </Box>
      { renderPagination }
    </>
  );
};

export default ProjectsCatalog;

import usePDF from '../../hooks/usePDF';
import {
  ProjectType,
  ProjectTypeIds,
} from '../../models/project';
import { ProjectMarkers } from '../../types/scalars';
import * as api from '../../services/geo-api.service';
import { getUpdatedMarkersCfg } from './project-recipe.service';
import * as projectRepo from './project.repository';
import { mapBiRecipeToRecipeIn, mapProjectToProjectIn, mapRecipeToRecipeIn } from './project.adapter';
import { tryGetResponseData } from '../common';
import { getEmptyBiProjectId, getEmptyProjectId } from '../context/auth.repository';
import { biCfgIn, EMPTY_PROJECT_TITLE, PROJECTS_PAGE_SIZE_COUNT } from '../../utils/constants';
import { getBoundsFromWKT, wrapRecipeNameToProjectName, queriesWrapper } from '../../utils/functions';
import { getActiveTerritory, getRecipe, hasRecipe } from '../geoData/recipes.repository';
import { LayerType } from '../../models/layer';
import {
  GpbiProjectIn,
  GpLayerFormula,
  GpLayerMap,
  GpProject,
  GpProjectIn,
  GpProjectGn,
  GpRecipe,
  GpuiLayer,
  GpAcl,
  GpLayer,
  GpbiRecipe,
  GpdsScheduleDb,
  GpStatDataset,
  GpbiProject,
  AclObjectUnion,
} from '../../api/types';
import { setAcl, setAclRequestStatus, setLayerDatasetSchedulesStatus } from '../acl/acl.repository';
import { ActionsEnum, biStartProcess } from '../../pages/BI/biHelpers';

interface UpdateMarkersData {
  newData: ProjectMarkers[keyof ProjectMarkers];
  key: keyof ProjectMarkers;
}

interface ToggleLayerData {
  id: string;
  layerType: LayerType;
}

interface ChangeUILayerData {
  id: string;
  key: keyof GpuiLayer;
  value: GpuiLayer[keyof GpuiLayer];
}

interface MoveLayerData {
  layerType: LayerType;
  convertLayerType: boolean;
  fromIndex: number;
  toIndex: number;
  layerId: string;
}

interface UpdateUILayerFormulaData {
  id: string;
  update: Partial<GpLayerFormula>;
}

interface UpdateUILayerFormulaArea {
  id: string;
  area: [number, number];
}

interface UpdateUILayerFormulaWeight {
  id: string;
  weight: number;
}

export async function updateGeoProjectProp(
  props: unknown,
  key: keyof GpProject,
): Promise<void> {
  try {
    const activeProjectGn = projectRepo.getActiveProject();
    const project = activeProjectGn.project[0] as GpProject;

    const reqData = {
      recipe: project.recipe.map((r) => mapRecipeToRecipeIn(r)),
      [key]: props,
    };

    const res = await api.updateProject(project.id, reqData);
    const updatedProject = tryGetResponseData(res).GPProject.update as GpProject;
    if (updatedProject) {
      projectRepo.updateGeoProject(project, key, updatedProject);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function updateProjectMarkersCfg(data: UpdateMarkersData) {
  const { newData, key } = data;
  const activeProjectGn = projectRepo.getActiveProject();
  const project = activeProjectGn.project[0] as GpProject;
  const markersCfg = getUpdatedMarkersCfg(newData, key, project.markersCfg || {});

  await updateGeoProjectProp(markersCfg, 'markersCfg');
}

export async function savePDF(mapElement: HTMLElement) {
  const activeProjectGn = projectRepo.getActiveProject();
  const projectGeo = activeProjectGn.project[0] as GpProject;
  const activeFormula = projectRepo.getFormulaLayer();
  const projectLayers = projectRepo.getProjectLayersSnapshot();

  if (activeFormula) {
    const pdfProcessor = usePDF(
      projectGeo,
      activeFormula.layerEm[0],
      projectLayers,
      mapElement,
    );
    void pdfProcessor.save();
  }
}

function mapProjectWithLayers(): [string, GpProjectIn, GpProjectGn] {
  const projectGn = projectRepo.getActiveProject();
  const projectGeo = projectGn.project[0] as GpProject;
  const bgLayers = projectRepo.getUILayers(LayerType.BG);
  const ovLayers = projectRepo.getUILayers(LayerType.OV);
  const newProject = structuredClone(projectGeo);

  newProject.recipe[0].layers[0].bg = bgLayers;
  newProject.recipe[0].layers[0].ov = ovLayers;
  return [projectGeo.id, mapProjectToProjectIn(newProject), projectGn];
}

async function updateProject() {
  const [id, projectIn, projectGn] = mapProjectWithLayers();
  const res = await api.updateProject(id, projectIn);
  const project = tryGetResponseData(res).GPProject.update;
  projectRepo.upsertProject({ ...projectGn, project: [project as GpProject] });
}

export async function toggleLayer(data: ToggleLayerData) {
  const { id, layerType } = data;
  projectRepo.toggleLayer(id, layerType);
  await updateProject();
}

export async function changeUILayer(data: ChangeUILayerData) {
  const { id, key, value } = data;
  projectRepo.updateUiLayer(id, key, value);
  await updateProject();
}

export async function updateUILayerFormula(data: UpdateUILayerFormulaData) {
  const { id, update } = data;
  projectRepo.updateUILayerFormula(id, update);
  await updateProject();
}

export async function toggleLayerInResulting(id: string) {
  projectRepo.toggleLayerInResulting(id);
  await updateProject();
}

export async function updateFormulaLayerItemArea(data: UpdateUILayerFormulaArea) {
  const { id, area } = data;
  projectRepo.updateFormulaLayerItemArea(id, area);
  await updateProject();
}

export async function updateFormulaLayerItemWeight(data: UpdateUILayerFormulaWeight) {
  const { id, weight } = data;
  projectRepo.updateFormulaLayerItemWeight(id, weight);
  await updateProject();
}

export async function moveLayerWithin(data: MoveLayerData) {
  const { fromIndex, toIndex, layerType } = data;
  projectRepo.moveLayer(fromIndex, toIndex, layerType);
  await updateProject();
}

export async function moveLayerToDifferentType(data: Omit<MoveLayerData, 'fromIndex'>) {
  const { toIndex, layerType, layerId } = data;
  projectRepo.moveLayerToDifferentType(layerId, toIndex, layerType);
  await updateProject();
}

export const fetchAclAsync = async () => {
  setAclRequestStatus('pending');
  const res = await api.getAcl();
  const aclData = tryGetResponseData(res).GPAcl.list.data as GpAcl[];

  const map = new Map<string, AclObjectUnion>([]);
  aclData.flatMap((r) => r.object).forEach((acl) => map.set(acl.id, acl));
  const objects = Array.from(map.values());

  setAcl(objects);
  setAclRequestStatus('success');
};

export async function createGpProjectGn(projectTypeId: ProjectTypeIds, projectId: string):
Promise<GpProjectGn> {
  const res = await api.createProjectGn({
    projectType: {
      id: projectTypeId,
      typename: 'GPProjectTypes',
    },
    project: [{
      id: projectId,
      typename: projectTypeId === ProjectTypeIds.GPProject
        ? ProjectType.GPProject
        : ProjectType.GPBIProject,
    }],
  });
  const projectGn = tryGetResponseData(res).GPProjectGn.create as GpProjectGn;
  return projectGn;
}

export async function createGeoProject(recipe?: GpRecipe): Promise<string> {
  const emptyProjectId = getEmptyProjectId();
  if (!recipe) {
    if (hasRecipe(emptyProjectId!)) {
      // eslint-disable-next-line no-param-reassign
      recipe = getRecipe(emptyProjectId!) as GpRecipe;
    } else {
      const res = await api.getRecipe(emptyProjectId!);
      // eslint-disable-next-line no-param-reassign
      recipe = tryGetResponseData(res).GPRecipe.get as GpRecipe;
    }
  }

  const title = wrapRecipeNameToProjectName(
    recipe.id === emptyProjectId
      ? EMPTY_PROJECT_TITLE
      : recipe.title,
  );
  const projectIn: GpProjectIn = {
    title,
    recipe: [mapRecipeToRecipeIn(recipe)],
  };

  const res = await api.createProject(projectIn);
  const project = tryGetResponseData(res).GPProject.create as projectRepo.ExtendedGPProject;

  const activeTerritory = getActiveTerritory();
  if (activeTerritory?.geometryWKT) {
    project.bounds = getBoundsFromWKT(activeTerritory.geometryWKT);
  }

  const projectGn = await createGpProjectGn(ProjectTypeIds.GPProject, project.id);

  projectRepo.upsertProject({ ...projectGn, project: [{ ...project }] });
  return projectGn.id;
}

export async function createBIProject(recipe?: GpRecipe | GpbiRecipe): Promise<string> {
  const emptyBiProjectId = getEmptyBiProjectId();
  if (!recipe) {
    if (hasRecipe(emptyBiProjectId!)) {
      // eslint-disable-next-line no-param-reassign
      recipe = getRecipe(emptyBiProjectId!) as GpbiRecipe;
    } else {
      const res = await api.getBiRecipe(emptyBiProjectId!);
      // eslint-disable-next-line no-param-reassign
      recipe = tryGetResponseData(res).GPBIRecipe.get as GpbiRecipe;
    }
  }

  const projectIn: GpbiProjectIn = {
    ...biCfgIn,
    recipe: [mapBiRecipeToRecipeIn(recipe as GpbiRecipe)],
  };

  const res = await api.createBIProject(projectIn);
  const project = tryGetResponseData(res)
    .GPBIProject.create as unknown as projectRepo.ExtendedGPProject;

  const projectGn = await createGpProjectGn(ProjectTypeIds.GPBIProject, project.id);

  projectRepo.upsertProject({ ...projectGn, project: [{ ...project }] });
  return projectGn.id;
}

export async function getScheduleAndTerritories(
  layersOrDataset: GpLayerMap[] | GpdsScheduleDb[],
  projectType: string,
  title: string,
  page?: number,
  limit?: number,
) {
  setLayerDatasetSchedulesStatus('pending');

  try {
    const setUNameKeys = new Set(layersOrDataset
      .map((element) => element.uName.split('_').splice(0, 3).join('_')));

    const uNameKeys = Array.from(setUNameKeys);

    if (projectType === ProjectType.GPBIProject) {
      const res = await api.getDatasetScheduleAndTerritory(uNameKeys, title, page, limit);
      const { data: scheduleAndTerritory, totalCount } = tryGetResponseData(res).GPStatDataset.list;
      setLayerDatasetSchedulesStatus('success');
      return { gpDatasets: scheduleAndTerritory as GpStatDataset[], totalCount };
    }
    const res = await api.getLayerScheduleAndTerritory(uNameKeys, title, page, limit);
    const { data: scheduleAndTerritory, totalCount } = tryGetResponseData(res).GPLayer.list;
    setLayerDatasetSchedulesStatus('success');
    return { gpLayers: scheduleAndTerritory as GpLayer[], totalCount };
  } catch (e) {
    if (e instanceof Error && e.message === 'aborted') {
      return { gpLayers: [], totalCount: 0 };
    }
    throw e;
  }
}

export async function getLayerDatasetSchedule(
  commodities: (GpdsScheduleDb | GpLayerMap)[],
  search: string,
  page?: number,
  limit?: number,
) {
  setLayerDatasetSchedulesStatus('pending');

  try {
    if (!commodities.length) {
      setLayerDatasetSchedulesStatus('success');
      return {
        schedules: [],
        totalCount: 0,
      };
    }
    const uName = commodities.map((c) => c?.uName.split('_', 3).join('_'));
    const uNameKeys = Array.from(new Set(uName));

    const resDatasetSchedule = api.getDatasetScheduleAndTerritory(uNameKeys, search, page, limit);
    const resLayerSchedule = api.getLayerScheduleAndTerritory(uNameKeys, search, page, limit);

    const res = await Promise.all([resDatasetSchedule, resLayerSchedule]);

    const { data: schedDatasets, totalCount: datasetCount } = tryGetResponseData(res[0]).GPStatDataset.list;
    const { data: schedLayers, totalCount: layerCount } = tryGetResponseData(res[1]).GPLayer.list;

    setLayerDatasetSchedulesStatus('success');
    return {
      schedules: [...(schedLayers as unknown as GpLayer[]), ...(schedDatasets as unknown as GpStatDataset[])],
      totalCount: layerCount + datasetCount,
    };
  } catch (e) {
    if (e instanceof Error && e.message === 'aborted') {
      return { gpLayers: [], totalCount: 0 };
    }
    throw e;
  }
}

export async function fetchProjects(page: number, searchText: string = '', projectType: string[] = []) {
  try {
    projectRepo.setProjectsRequestPending();
    const limit = PROJECTS_PAGE_SIZE_COUNT;
    const query = queriesWrapper<GpProjectGn>(api.getProjectsGn, page, limit);
    const res = await query(projectType, searchText);
    const projects = res.GPProjectGn.list;
    if (projects) {
      const { totalCount, data } = projects;
      projectRepo.setProjects(data, totalCount);
    }
  } catch (e) {
    if (e instanceof Error && e.message === 'aborted') {
      return;
    }
    projectRepo.setProjectsRequestError(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

export async function fetchProjectById(id: string) {
  try {
    projectRepo.setProjectsRequestPending();
    const res = await api.getProjectGn(id);
    const project = tryGetResponseData(res).GPProjectGn.get as GpProjectGn;

    projectRepo.upsertProject(project);
    projectRepo.setProjectsRequestSuccess();
  } catch (err) {
    projectRepo.setProjectsRequestError(err);
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function setActiveProject(id: string): Promise<void> {
  const activeId = projectRepo.getActiveProjectId();
  if (activeId !== id) {
    if (!projectRepo.getProject(id)?.full) {
      await fetchProjectById(id);
    }
    projectRepo.setActiveProjectId(id);
  }
  const projectGn = projectRepo.getProject(id);
  if (projectGn?.project[0].__typename === ProjectType.GPProject) {
    projectRepo.addUILayersToStore(projectGn.project[0]);
  }
}

export async function deleteProject(id: string) {
  try {
    projectRepo.setProjectsRequestPending();
    const projectGn = projectRepo.getProject(id);
    if (projectGn?.projectType.model === ProjectType.GPBIProject) {
      await biStartProcess((projectGn.project[0] as GpbiProject).id, ActionsEnum.Delete);
    } else {
      await api.deleteProjectGn(id, projectGn?.project[0].id as string);
    }
    projectRepo.deteleProjectFromStore(id);
  } catch (err) {
    projectRepo.setProjectsRequestError(err);
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function updateBiProjectProp(
  props: unknown,
  key: keyof GpbiProject,
): Promise<void> {
  try {
    const activeProjectGn = projectRepo.getActiveProject();
    const project = activeProjectGn.project[0] as GpbiProject;

    const reqData = {
      recipe: project.recipe.map((r) => mapBiRecipeToRecipeIn(r)),
      [key]: props,
    };

    const res = await api.updateBiProject(project.id, reqData);
    const updatedProject = tryGetResponseData(res).GPBIProject.update as GpbiProject;
    if (updatedProject) {
      projectRepo.updateBiProject(project, key, updatedProject);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export async function updateProjectPositionAndZoom(
  position: ProjectMarkers['position'],
  zoom: number,
): Promise<void> {
  const activeProjectGn = projectRepo.getActiveProject();
  const project = activeProjectGn?.project[0];
  if (!activeProjectGn || !project || !project.id) {
    return;
  }

  if (project.__typename === ProjectType.GPProject) {
    const proj = structuredClone(project);
    if (proj.markersCfg) {
      proj.markersCfg.zoom = zoom;
      proj.markersCfg.position = position;
    } else {
      proj.markersCfg = { position, zoom };
    }
    projectRepo.updateGeoProject(project, 'markersCfg', proj);
    void updateProject();
  }
}

export async function applyUiLayerChanges(): Promise<void> {
  const activeProjectGn = projectRepo.getActiveProject();
  const project = activeProjectGn?.project[0] as GpProject;

  if (activeProjectGn && project.__typename === ProjectType.GPProject) {
    projectRepo.resetProjectStep();
    await updateProject();
    const projectGn = projectRepo.getProject(activeProjectGn.id);
    if (projectGn?.project[0].__typename === ProjectType.GPProject) {
      projectRepo.addUILayersToStore(projectGn.project[0]);
    }
  }
}

export async function applyUiDatasetsChanges(): Promise<void> {
  try {
    const activeProjectGn = projectRepo.getActiveProject();
    const project = activeProjectGn.project[0] as GpbiProject;

    const reqData = {
      recipe: project.recipe.map((r) => mapBiRecipeToRecipeIn(r)),
    };
    projectRepo.resetProjectStep();

    const res = await api.updateBiProject(project.id, reqData);
    const updatedProject = tryGetResponseData(res).GPBIProject.update as GpbiProject;
    if (updatedProject) {
      await biStartProcess(project.id, ActionsEnum.Update);
      projectRepo.updateBiProject(project, 'recipe', updatedProject);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

/* eslint-disable max-len */
import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  GRAPHQL_BASKET_URL,
  GRAPHQL_CMS_URL,
  GRAPHQL_GEO_SERVER_FEATURE_URL,
  GRAPHQL_PAYMENT_URL,
  MARKETPLACE_PAGE_SIZE_COUNT,
  PROJECTS_PAGE_SIZE_COUNT,
} from '../utils/constants';
// eslint-disable-next-line import/no-cycle
import { getAuthProps } from '../state/context/auth.repository';
// eslint-disable-next-line import/no-cycle
import { logout } from '../state/context/auth.actions';
import { InputOrder } from '../state/filters/filters.repository';
import * as queries from '../api/queries';
import {
  Direction,
  GpLayerMap,
  GpLayerMapWhereIn,
  GpLayerWhereIn,
  GpOrderIn,
  GpPageConfigIn,
  GpProjectIn,
  GpCustomerProfileIn,
  GpProjectGnWhereIn,
  GpProjectGnIn,
  GpbiProjectIn,
  GpRecipeWhereIn,
  GpdsScheduleDbWhereIn,
  GpStatDatasetWhereIn,
  BoundingBox,
} from '../api/types';
import { ProductSearchableProp, productTypesHaveProperty } from '../models/product';

const httpLink = new HttpLink({ uri: GRAPHQL_CMS_URL });

const basketHttpLink = new HttpLink({ uri: GRAPHQL_BASKET_URL });

const paymentHttpLink = new HttpLink({ uri: GRAPHQL_PAYMENT_URL });

const geoServerHttpLink = new HttpLink(({ uri: GRAPHQL_GEO_SERVER_FEATURE_URL }));

const authLink = setContext(async (_, { headers }) => {
  const { activeId, token } = getAuthProps();
  return ({
    headers: {
      ...headers,
      auth: token,
      'profile-id': activeId,
    },
  });
});

const logoutLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  if (!response.errors?.length && response.data) {
    const queryKey = Object.keys(response.data)[0];
    if ('list' in response.data[queryKey] && response.data[queryKey].list.documents === null) {
      response.data[queryKey].list.documents = [];
    }
  }
  return response;
}));

const errorLink = onError(({ networkError }: ErrorResponse) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    void logout();
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const additiveLink = from([
  authLink,
  logoutLink,
  errorLink,
  httpLink,
]);

const additiveBasketLink = from([
  authLink,
  logoutLink,
  errorLink,
  basketHttpLink,
]);

const additiveLinkPayment = from([
  authLink,
  logoutLink,
  errorLink,
  paymentHttpLink,
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

const basketClient = new ApolloClient({
  link: additiveBasketLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

const clientPayment = new ApolloClient({
  link: additiveLinkPayment,
  cache: new InMemoryCache(),
  defaultOptions,
});

const clientGeoServerFeature = new ApolloClient({
  link: geoServerHttpLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

export async function apolloClientResetStore() {
  client.stop();
  return client.resetStore();
}

export async function getUser() {
  const result = await client
    .query({
      query: queries.UserByUidQueryDocument,
    });
  return result;
}

export function getBasicIdConfig(id: string) {
  return client.query({
    query: queries.BasicConfigDocument,
    variables: { id },
  })
    .then((res) => res);
}

export async function getBasketPrice(
  orderId: string,
  codeText: string,
) {
  const result = basketClient
    .query({
      query: queries.BasketQueryDocument,
      variables: { orderId, codeText },
    });
  return result;
}

export async function getPaymentStatus(id: string) {
  const result = await clientPayment
    .query({
      query: queries.PaymentStatusQueryDocument,
      variables: { id },
    });
  return result;
}

export async function fetchLayers(
  uNameKeys: string[] = [],
) {
  const filter: GpLayerWhereIn = {
    uNameKey: uNameKeys.length ? { _in: uNameKeys } : undefined,
  };

  return client
    .query({
      query: queries.LayersListQueryDocument,
      variables: { where: filter },
    });
}

export async function fetchDataSets(
  uNameKeys: string[] = [],
) {
  const filter: GpStatDatasetWhereIn = {
    uNameKey: uNameKeys.length ? { _in: uNameKeys } : undefined,
  };

  return client
    .query({
      query: queries.DatasetsListDocument,
      variables: { where: filter },
    })
    .then((result) => result);
}

export function fetchTariffs() {
  return client
    .query({
      query: queries.GpTariffPlanQueryDocument,
    })
    .then((result) => result);
}

export function getPageConfig(
  id: string,
) {
  return client
    .query({
      query: queries.GpPageConfigQueryDocument,
      variables: { id },
    })
    .then((result) => result);
}

export const updatePageConfig = (
  data: Partial<GpPageConfigIn>,
) => {
  if (data.id) {
    return client.mutate({
      mutation: queries.PageConfigUpdateDocument,
      variables: { id: data.id, data },
    }).then((result) => result);
  }
  return client.mutate({
    mutation: queries.PageConfigCreateDocument,
    variables: { data },
  }).then((result) => result);
};

export function getLayerMapList(
  ids?: string[],
  filterField: keyof GpLayerMap = 'uName',
) {
  const filter: GpLayerMapWhereIn = {
    _or: ids?.length
      ? ids.map((id) => ({ [filterField]: { _ilike: id } }))
      : undefined,
  };
  return client.query({
    query: queries.GpLayerMapListQueryDocument,
    variables: { where: filter },
  })
    .then((result) => result);
}

export function getScheduleDb(
  ids?: string[],
  filterField: keyof GpLayerMap = 'uName',
) {
  const filter: GpdsScheduleDbWhereIn = {
    _or: ids?.length
      ? ids.map((id) => ({ [filterField]: { _ilike: id } }))
      : undefined,
  };
  return client.query({
    query: queries.ScheduleDbListQueryDocument,
    variables: { where: filter },
  })
    .then((result) => result);
}

export function createProject(
  data: GpProjectIn,
) {
  if (data.recipe && data.recipe[0].layers) {
    data.recipe[0].layers[0]?.ov?.sort((a, b) => {
      if (a.id === 'RES') {
        return -1;
      }
      if (b.id === 'RES') {
        return 1;
      }
      return 0;
    });
  }
  return client.mutate({
    mutation: queries.ProjectCreateDocument,
    variables: { data },
  })
    .then((res) => res);
}

function getDurationFilters(durationFilters: [number, number][]): GpRecipeWhereIn | undefined {
  if (!durationFilters.length) return undefined;

  return {
    _or: durationFilters.map(([min, max]) => ({
      _and: [
        {
          duration: { _gte: min },
        },
        {
          duration: { _lte: max },
        },
      ],
    })),
  };
}

export function getRecipes(
  durationFilters: [number, number][],
) {
  return client.query({
    query: queries.GpRecipeListQueryDocument,
    variables: { where: getDurationFilters(durationFilters) },
  });
}

export function updateProject(
  id: string,
  data: Partial<GpProjectIn>,
) {
  return client.mutate({
    mutation: queries.ProjectUpdateDocument,
    variables: { id, data },
  })
    .then((res) => res);
}

export function updateBiProject(
  id: string,
  data: Partial<GpbiProjectIn>,
) {
  return client.mutate({
    mutation: queries.ProjectBiUpdateDocument,
    variables: { id, data },
  })
    .then((res) => res);
}

export function getPages() {
  return client.query({
    query: queries.GpCmsPageQueryDocument,
  })
    .then((res) => res);
}

export function getRecipe(id: string) {
  return client.query({
    query: queries.GpRecipeQueryDocument,
    variables: { id },
  })
    .then((res) => res);
}

export async function getBiRecipe(id: string) {
  const res = await client.query({
    query: queries.GetBiRecipeDocument,
    variables: { id },
  });
  return res;
}

export function getTerritoryList(limit?: number, offset?: number, search: string = '') {
  const variables = {
    orders: [{ direction: Direction.Asc, field: 'name' }],
    where: {
      name: {
        _ilike: search,
      },
    },
  };
  if (limit) {
    Object.assign(variables, { limit });
  }
  if (offset) {
    Object.assign(variables, { offset });
  }
  return client.query({
    query: queries.TerritoriesQueryDocument,
    variables,
  })
    .then((res) => res);
}

export function getRubricList() {
  return client.query({
    query: queries.GpRubricQueryDocument,
  })
    .then((res) => res);
}

export function getProjectTypesList() {
  return client.query({
    query: queries.GpProjectTypesQueryDocument,
  })
    .then((res) => res);
}

export function getUpdatePeriodList(limit?: number, offset?: number, search: string = '') {
  const variables = {
    where: {
      title: {
        _ilike: search,
      },
    },
  };
  if (limit) {
    Object.assign(variables, { limit });
  }
  if (offset) {
    Object.assign(variables, { offset });
  }
  return client.query({
    query: queries.GpUpdatePeriodQueryDocument,
    variables,
  })
    .then((res) => res);
}

export function getProductTypesList(limit?: number, offset?: number, search: string = '') {
  const variables = {
    where: {
      name: {
        _ilike: search,
      },
    },
  };
  if (limit) {
    Object.assign(variables, { limit });
  }
  if (offset) {
    Object.assign(variables, { offset });
  }
  return client.query({
    query: queries.GpProductTypesDocument,
    variables,
  })
    .then((res) => res);
}

export function getCustomerProfiles() {
  return client.query({
    query: queries.CustomerProfileListQueryDocument,
  }).then((res) => res);
}

export function updateCustomerProfile(
  id: string,
  data: GpCustomerProfileIn,
) {
  return client.mutate({
    mutation: queries.CustomerProfileUpdateDocument,
    variables: { id, data },
  }).then((res) => res);
}

export const getOrders = () => client.query({
  query: queries.OrderListQueryDocument,
  variables: {
    orders: [{ direction: Direction.Desc, field: 'dateTime' }],
  },
});

export const getFullOrders = (
  page: number = 0,
  limit: number = PROJECTS_PAGE_SIZE_COUNT,
) => client.query({
  query: queries.FullOrderListQueryDocument,
  variables: {
    limit,
    offset: page * limit,
    orders: [{ direction: Direction.Desc, field: 'dateTime' }],
  },
});

export function getOrder(id: string) {
  return client.query({
    query: queries.GetOrderDocument,
    variables: { id },
  }).then((result) => result);
}

export const newOrder = (
  data: Partial<GpOrderIn>,
) => client.mutate({
  mutation: queries.OrderCreateDocument,
  variables: { data },
});

export const updateOrder = (
  id: string,
  data: Partial<GpOrderIn>,
) => client.mutate({
  mutation: queries.OrderUpdateDocument,
  variables: { id, data },
});

export const deleteOrder = (
  id: string,
) => client.mutate({
  mutation: queries.OrderDeleteDocument,
  variables: { id },
});

export function getAcl() {
  return client
    .query({
      query: queries.AclQueryDocument,
    })
    .then((result) => result);
}

function composeProductWhere<T>(
  property: ProductSearchableProp,
  value: T,
  conditionRenderFn: (value: T) => Object,
) {
  const onTypes: any = {};
  productTypesHaveProperty[property].forEach((type) => {
    onTypes[`on_${type}`] = { [property]: conditionRenderFn(value) };
  });
  return { product: onTypes };
}

let getProductsAbort: (() => void) | null = null;

export function getProducts(
  searchText: string,
  productTypes: string[],
  territories: string[],
  rubrics: string[],
  updatePeriods: string[],
  order?: InputOrder,
  page: number = 0,
  limit: number = MARKETPLACE_PAGE_SIZE_COUNT,
) {
  const productAnd = [];

  if (productTypes.length) {
    productAnd.push({
      _or: productTypes.map((id) => ({ productType: { id: { _eq: id } } })),
    });
  }
  if (searchText) {
    productAnd.push(composeProductWhere<string>('title', searchText, (v) => ({ _ilike: v })));
  }
  if (territories.length) {
    productAnd.push({
      _or: territories.map((id) => composeProductWhere<string>('territory', id, (id) => ({ _eq: `GPTerritories:${id}` }))),
    });
  }
  if (rubrics.length) {
    productAnd.push({
      _or: rubrics.map((id) => (composeProductWhere<string>('rubrics', id, (id) => ({ id: { _eq: id } })))),
    });
  }
  if (updatePeriods.length) {
    productAnd.push({
      _or: updatePeriods.map((id) => (composeProductWhere<string>('updatePeriod', id, (id) => ({ _eq: `GPUpdatePeriod:${id}` })))),
    });
  }

  const variables: Record<string, any> = {
    offset: Math.round(page * limit),
    limit,
  };

  if (productAnd.length) {
    variables.where = { _and: productAnd };
  }

  if (order) {
    variables.order = order;
  }

  if (getProductsAbort !== null) {
    getProductsAbort();
    getProductsAbort = null;
  }

  return new Promise<ApolloQueryResult<queries.ProductsListQueryQuery>>((resolve, reject) => {
    const query = client.watchQuery({
      query: queries.ProductsListQueryDocument,
      variables,
    });
    const products$ = query.subscribe((data) => {
      resolve(data);
    });
    getProductsAbort = () => {
      products$.unsubscribe();
      reject(new Error('aborted'));
    };
  });
}

export function getProduct(id: string) {
  return client
    .query({
      query: queries.GetProductDocument,
      variables: { id },
    })
    .then((result) => result);
}

export function getProductByLayerId(id: string) {
  return client
    .query({
      query: queries.ProductsListQueryDocument,
      variables: { where: { product: { on_GPLayer: { id: { _eq: id } } } } },
    })
    .then((result) => result);
}

export function getProjectGn(id: string) {
  return client.query({
    query: queries.GpProjectGnGetDocument,
    variables: { id },
  }).then((result) => result);
}

let getProjectsGnAbort: (() => void) | null = null;

/* eslint-disable no-underscore-dangle */
export function getProjectsGn(
  projectTypes: string[],
  searchText: string,
  page: number = 0,
  limit: number = PROJECTS_PAGE_SIZE_COUNT,
) {
  const filters: GpProjectGnWhereIn = { _and: [] };
  if (searchText) {
    filters._and?.push({ project: { on_GPProject: { title: { _ilike: searchText } } } });
  }

  if (projectTypes.length) {
    filters
      ._and?.push({ _or: projectTypes.map((type) => ({ projectType: { id: { _eq: type } } })) });
  }

  const orders = [{
    field: 'cmsupdatedate',
    direction: 'DESC',
  }];

  const variables = {
    orders,
    where: filters._and?.length ? filters : undefined,
    limit,
    offset: Math.round(page * limit),
  };

  if (getProjectsGnAbort !== null) {
    getProjectsGnAbort();
    getProjectsGnAbort = null;
  }

  return new Promise<ApolloQueryResult<queries.GpProjectGnListQuery>>((resolve, reject) => {
    const query = client.watchQuery({
      query: queries.GpProjectGnListDocument,
      variables,
    });
    const projects$ = query.subscribe((data) => {
      resolve(data);
    });
    getProjectsGnAbort = () => {
      projects$.unsubscribe();
      reject(new Error('aborted'));
    };
  });
}

/* eslint-enable no-underscore-dangle */
export function deleteProjectGn(projectGnId: string, projectId: string) {
  return client.mutate({
    mutation: queries.ProjectGnDeleteDocument,
    variables: { projectGnId, projectId },
  });
}

export function createProjectGn(
  data: GpProjectGnIn,
) {
  return client.mutate({
    mutation: queries.ProjectGnCreateDocument,
    variables: { data },
  })
    .then((res) => res);
}

export function createBIProject(
  data: GpbiProjectIn,
) {
  return client.mutate({
    mutation: queries.ProjectBiCreateDocument,
    variables: { data },
  })
    .then((res) => res);
}

let getLayerScheduleAndTerritoryAbort: (() => void) | null = null;

export function getLayerScheduleAndTerritory(
  uNameKeys: string[],
  title: string,
  page: number = 0,
  limit?: number,
) {
  const variables = {
    where: {
      _and: [
        { title: { _ilike: title } },
        { uNameKey: uNameKeys.length ? { _in: uNameKeys } : undefined },
      ],
    },
    offset: limit ? page * limit : undefined,
    limit,
  };

  if (getLayerScheduleAndTerritoryAbort !== null) {
    getLayerScheduleAndTerritoryAbort();
    getLayerScheduleAndTerritoryAbort = null;
  }

  return new Promise<ApolloQueryResult<queries.GetLayerScheduleAndTerritoryQuery>>((resolve, reject) => {
    const query = client.watchQuery({
      query: queries.GetLayerScheduleAndTerritoryDocument,
      variables,
    });
    const queryObservable$ = query.subscribe((data) => resolve(data));
    getLayerScheduleAndTerritoryAbort = () => {
      queryObservable$.unsubscribe();
      reject(new Error('aborted'));
    };
  });
}

let getDatasetScheduleAndTerritoryAbort: (() => void) | null = null;

export function getDatasetScheduleAndTerritory(
  uNameKeys: string[],
  title: string,
  page: number = 0,
  limit?: number,
) {
  const variables = {
    where: {
      _and: [
        { title: { _ilike: title } },
        { uNameKey: uNameKeys.length ? { _in: uNameKeys } : undefined },
      ],
    },
    offset: limit ? page * limit : undefined,
    limit,
  };

  if (getDatasetScheduleAndTerritoryAbort !== null) {
    getDatasetScheduleAndTerritoryAbort();
    getDatasetScheduleAndTerritoryAbort = null;
  }

  return new Promise<ApolloQueryResult<queries.GetDatasetScheduleAndTerritoryQuery>>((resolve, reject) => {
    const query = client.watchQuery({
      query: queries.GetDatasetScheduleAndTerritoryDocument,
      variables,
    });
    const queryObservable$ = query.subscribe((data) => resolve(data));
    getDatasetScheduleAndTerritoryAbort = () => {
      queryObservable$.unsubscribe();
      reject(new Error('aborted'));
    };
  });
}

export function getSchedulesFromDatasetAndLayer(uName: string[], title: string = '') {
  const variables = {
    where1: {
      _and: [
        {
          title: {
            _ilike: title,
          },
        },
        {
          uNameKey: uName.length
            ? { _in: uName }
            : undefined,
        },
      ],
    },
    where2: {
      _and: [
        {
          title: {
            _ilike: title,
          },
        },
        {
          uNameKey: uName.length
            ? { _in: uName }
            : undefined,
        },
      ],
    },
  };
  return client.query({
    query: queries.SchedulesFromDatasetAndLayerDocument,
    variables,
  });
}

let getUnamesFromDatasetAndLayerAbort: (() => void) | null = null;

export function getUnamesFromDatasetAndLayer(uName: string[], title: string = '') {
  const variables = {
    where1: {
      _and: [
        {
          title: {
            _ilike: title,
          },
        },
        {
          uNameKey: uName.length
            ? { _in: uName }
            : undefined,
        },
      ],
    },
    where2: {
      _and: [
        {
          title: {
            _ilike: title,
          },
        },
        {
          uNameKey: uName.length
            ? { _in: uName }
            : undefined,
        },
      ],
    },
  };

  if (getUnamesFromDatasetAndLayerAbort !== null) {
    getUnamesFromDatasetAndLayerAbort();
    getUnamesFromDatasetAndLayerAbort = null;
  }

  return new Promise<ApolloQueryResult<queries.UnamesFromDatasetAndLayerQuery>>((resolve, reject) => {
    const query = client.watchQuery({
      query: queries.UnamesFromDatasetAndLayerDocument,
      variables,
    });
    const unames$ = query.subscribe((data) => {
      resolve(data);
    });
    getUnamesFromDatasetAndLayerAbort = () => {
      unames$.unsubscribe();
      reject(new Error('aborted'));
    };
  });
}

export function getOrderByPaymentId(paymentId: string) {
  const variables = {
    where: {
      paymentId: { _eq: paymentId },
    },
  };

  return client.query({
    query: queries.GetOrderByPaymentIdDocument,
    variables,
  });
}

export function geoServerGetFeature(typeNames: string[], rect: BoundingBox) {
  const variables = {
    params: {
      typeNames,
      rect,
    },
  };

  return clientGeoServerFeature.query({
    query: queries.GeoServerGetFeatureDocument,
    variables,
  });
}

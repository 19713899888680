import {
  createStore,
  Reducer,
  select,
  setProp,
  setProps,
  withProps,
} from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  getActiveEntity,
  getActiveId,
  getAllEntities,
  getEntity,
  hasEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  joinRequestResult,
  selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { LatLngBoundsLiteral } from 'leaflet';
import {
  map,
  Observable,
} from 'rxjs';
import { stateHistory } from '@ngneat/elf-state-history';
import {
  GpLayerFormula,
  GpLayerMap,
  GpProject,
  GpuiLayer,
  GpbiProject,
  GpProjectGn,
  TmpLayerTypenames,
  GpdsScheduleDb,
} from '../../api/types';
import {
  DEFAULT_FORMULA_ITEM,
  RESULTING_LAYER_ID,
} from '../../utils/constants';
// eslint-disable-next-line import/no-cycle
import { setUILayer } from './project-recipe.service';
import { mapLayerMapToUILayerLocal } from './project.adapter';
import { LayerType, ProjectLayerStyles } from '../../models/layer';
import { ProjectType } from '../../models/project';
import { ResponseError } from '../../models/common';

interface UiStateProps {
  cid: number;
  bg: string[];
  ov: string[];
}

const uiLayersStore = createStore(
  { name: 'uiLayers' },
  withEntities<GpuiLayer>(),
  withProps<UiStateProps>(<UiStateProps>{
    cid: 0,
    bg: [],
    ov: [],
  }),
);

export const uiLayersStoreHistory = stateHistory(uiLayersStore);

export const uiActiveIds$ = uiLayersStore.pipe(
  select((state) => state.ids.filter((id) => state.entities[id].on)),
);

type UILayersState = UiStateProps & { entities: Record<string, GpuiLayer>, ids: string[] };

const getSortedLayers = (state: UILayersState, layerType: LayerType) => (
  state[layerType].map((id) => state.entities[id])
);

export const uiLayers$ = uiLayersStore.pipe(selectAllEntities());
export const layersMap$ = uiLayers$.pipe(
  map((layers) => layers
    .filter((layer) => layer.tmpLayerTypename === 'GPLayerMap')
    .map((layer) => layer.layer[0]) as GpLayerMap[]),
);

export const baseLayers$ = uiLayersStore.pipe(
  select((state) => getSortedLayers(state, LayerType.BG)),
);

export const overlayLayers$ = uiLayersStore.pipe(
  select((state) => getSortedLayers(state, LayerType.OV)),
);

export const activeBaseLayerId$ = uiLayersStore.pipe(
  select((state) => state.bg.find((l) => state.entities[l].on)),
);

export const uiLayers = () => uiLayersStore.query(getAllEntities());

export const getBaseLayers = () => uiLayersStore.query(
  (state) => uiLayers().filter((l) => state.bg.includes(l.id)),
);

export const getUILayers = (layerType: LayerType) => uiLayersStore.query(
  (state) => getSortedLayers(state, layerType),
);

export const getOverlayLayers = () => uiLayersStore.query((state) => uiLayers()
  .filter((l) => state.ov.includes(l.id))
  .filter((l) => l.layer.length > 0));

export const getSortedOverlayLayers = () => uiLayersStore.query(
  (state) => getSortedLayers(state, LayerType.OV)
    .filter((l) => state.ov.includes(l.id))
    .filter((l) => l.layer.length > 0),
);

export const getOverlayLayersWithFormula = () => uiLayersStore.query((state) => uiLayers()
  .filter((l) => state.ov.includes(l.id)));

export const projectLayerStyles$: Observable<ProjectLayerStyles> = uiLayersStore.pipe(
  select((state) => ({
    cid: state.cid,
    bg: getSortedLayers(state, LayerType.BG).map((l) => setUILayer(l)),
    ov: getSortedLayers(state, LayerType.OV).map((l) => setUILayer(l)).reverse(),
  })),
);

export const activeFormulaLayer$: Observable<GpuiLayer | undefined> = uiLayersStore.pipe(
  select((state) => state.entities[RESULTING_LAYER_ID]),
);

export const activeFormula$ = activeFormulaLayer$.pipe(map((l) => l?.layerEm[0]));

export const getFormulaLayer = () => uiLayersStore.query(
  (state) => state.entities[RESULTING_LAYER_ID],
);

export const resultingEnabled$ = activeFormulaLayer$.pipe(
  map((layer) => !!layer?.on),
);

export const isLayerInFormula = (id: string) => {
  const formula = getFormulaLayer();
  const layers = formula.layerEm[0].L;
  return layers.some((l) => l.id === id);
};

export const getLayerDistribution = (id: string):number[] | null => uiLayersStore.query((state) => {
  const uiLayer = state.entities[id];
  if (uiLayer && uiLayer.tmpLayerTypename === TmpLayerTypenames.GpLayerMap) {
    return (uiLayer.layer[0] as GpLayerMap).distribution?.[0]?.distribution as number[];
  }
  return null;
});

export function updateUiLayer(
  id: string,
  key: keyof GpuiLayer,
  value: GpuiLayer[keyof GpuiLayer],
): void {
  uiLayersStore.update(updateEntities(id, { [key]: value }));
}

export function updateUILayerFormula(
  id: string,
  update: Partial<GpLayerFormula>,
): void {
  uiLayersStore.update(updateEntities(id, (e) => ({
    ...e,
    layerEm: [{
      ...e.layerEm[0],
      ...update,
    }],
  })));
}

export function addUILayersToStore(project: GpProject): void {
  if (project.recipe) {
    const bgIds: string[] = [];
    const ovIds: string[] = [];
    let formula: GpLayerFormula | null = null;

    const uiLayers: GpuiLayer[] = [];

    project.recipe.forEach((recipe) => {
      recipe.layers?.forEach((layer) => {
        layer.bg?.filter((layerMap) => layerMap.layer.every((l) => l.__typename === 'GPLayerMap'))
          .forEach((bg) => {
            if (!bg.id) {
              bg.id = (bg.layer[0] as GpLayerMap).uName;
            }
            uiLayers.push(bg);
            bgIds.push(bg.id);
          });
        layer.ov?.filter((layerMap) => layerMap.layer.every((l) => l.__typename === 'GPLayerMap'))
          .forEach((ov) => {
            if (!ov.id) {
              ov.id = (ov.layer[0] as GpLayerMap).uName;
            }
            uiLayers.push(ov);
            ovIds.push(ov.id);
            if (ov.layer?.length === 0 && ov.layerEm?.length > 0) {
              [formula] = ov.layerEm;
            }
          });
      });
    });

    uiLayersStore.update(
      setEntities(uiLayers),
      (state) => ({
        ...state,
        bg: bgIds,
        ov: ovIds,
        formula,
        cid: state.cid + 1,
      }),
    );
  }
}

export function toggleLayer(id: string, layerType: LayerType) {
  const layer = uiLayersStore.query(getEntity(id));
  if (!layer) {
    return;
  }

  const ops: Reducer<any>[] = [];
  if (layerType === LayerType.BG) {
    const bgIds = uiLayersStore.query((state) => state.bg);
    ops.push(
      updateEntities(bgIds, { on: false }),
      updateEntities(layer.id, { on: true }),
    );
  } else {
    ops.push(updateEntities(layer.id, { on: !layer.on }));
  }
  uiLayersStore.update(...ops);
}

function updateFormulaLayer<T extends any[]>(
  update: (formula: GpLayerFormula, id: string, idx: number, ...args: T) => void,
): (id: string, ...args: T) => void {
  function wrapper(id: string, ...args: T): void {
    const formulaLayer = getFormulaLayer();
    if (!formulaLayer || !uiLayersStore.query(hasEntity(id))) {
      return;
    }

    uiLayersStore.update(updateEntities(
      formulaLayer.id,
      (e) => {
        const mutableState = structuredClone(e);
        const idx = mutableState.layerEm[0].L.findIndex((l) => l.id === id);
        update(mutableState.layerEm[0], id, idx, ...args);
        return mutableState;
      },
    ));
  }

  return wrapper;
}

export const toggleLayerInResulting = updateFormulaLayer((formula, id, idx) => {
  if (idx !== -1) {
    formula.L.splice(idx, 1);
  } else {
    const formulaItem = { ...DEFAULT_FORMULA_ITEM, id, title: id };
    formula.L.push(formulaItem);
  }
});

export const updateFormulaLayerItemArea = updateFormulaLayer(
  (formula, _, idx, area: [number, number]) => {
    if (idx === -1) {
      return;
    }
    [formula.L[idx].min, formula.L[idx].max] = area;
  },
);

export const updateFormulaLayerItemWeight = updateFormulaLayer(
  (formula, _, idx, weight: number) => {
    if (idx === -1) {
      return;
    }
    formula.L[idx].weight = weight;
  },
);

export const moveLayer = (
  fromIndex: number,
  toIndex: number,
  layerType: LayerType,
) => uiLayersStore.update((state) => {
  const arr = [...state[layerType]];
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
  return {
    ...state,
    [layerType]: arr,
  };
});

export const moveLayerToDifferentType = (layerId: string, toIndex: number, toType: LayerType) => {
  const fromType = toType === LayerType.OV ? LayerType.BG : LayerType.OV;

  const fromIds = uiLayersStore.query((state) => [...state[fromType]]);
  const fromIndex = fromIds.indexOf(layerId);

  if (fromIndex > -1) {
    const toIds = uiLayersStore.query((state) => [...state[toType]]);
    fromIds.splice(fromIndex, 1);
    toIds.splice(toIndex, 0, layerId);

    const ops: Reducer<any>[] = [
      (state: UILayersState) => ({
        ...state,
        [fromType]: fromIds,
        [toType]: toIds,
        cid: state.cid + 1,
      }),
    ];
    if (toType === LayerType.BG) {
      ops.push(
        updateEntities(layerId, { on: false }),
      );
    }
    uiLayersStore.update(...ops);
  }
};

export function getProjectLayersSnapshot() {
  return uiLayersStore.query(getAllEntities())
    .filter((l) => l.layer.length > 0)
    .map((l) => l.layer[0] as GpLayerMap);
}

export interface ExtendedGPProject extends GpProject{
  bounds?: LatLngBoundsLiteral;
}

export interface ExtendedGPProjectGn extends GpProjectGn{
  project: (ExtendedGPProject | GpbiProject)[];
  full?: boolean;
}

export interface ProjectsProps {
  totalCount: number;
  step: number;
}

const projectStore = createStore(
  { name: 'projects' },
  withEntities<ExtendedGPProjectGn>(),
  withActiveId(),
  withProps<ProjectsProps>({
    totalCount: 0,
    step: 0,
  }),
  withRequestsStatus<'projects'>(),
);
export const trackProjectsRequestsStatus = createRequestsStatusOperator(projectStore);
export const projectStoreHistory = stateHistory(projectStore);

export const projects$ = projectStore.pipe(selectAllEntities());
export const projectsTotalCount$ = projectStore.pipe(select((state) => state.totalCount));
export const projectsRequestPending$ = projectStore.pipe(selectIsRequestPending('projects'));
export const projectsRequestStatus$ = projectStore.pipe(selectRequestStatus('projects'));
export const activeProject$ = projectStore.pipe(selectActiveEntity(), map((p) => p as GpProjectGn));
export const activeProjectWithRequestResult$ = projectStore.pipe(selectActiveEntity(), joinRequestResult(['project']));
export const step$ = projectStore.pipe(select((state) => state.step));

export const uiDataset$ = projectStore.pipe(
  selectActiveEntity(),
  map((projectGn) => projectGn?.projectType.model === ProjectType.GPBIProject
  && ((projectGn.project[0] as GpbiProject).recipe[0].datasetsList)
    .map((datasets) => datasets.dslist
      .map((ds) => ds.ds[0]))),
);

export const getStep = () => projectStore.query(
  (state) => state.step,
);

export function getActiveProject(): GpProjectGn {
  return projectStore.query(getActiveEntity()) as GpProjectGn;
}

export function getActiveProjectId(): string {
  return projectStore.query(getActiveId) as string;
}

export function addUiDataset(gpdsScheduleDb: GpdsScheduleDb): void {
  const gpUiDataset = {
    name: gpdsScheduleDb.title,
    ds: [{
      ...gpdsScheduleDb,
    }],
  };
  const { id, project } = getActiveProject();
  const newProject = structuredClone(project)[0] as GpbiProject;

  newProject.recipe[0].datasetsList[0].dslist.push(gpUiDataset);

  projectStore.update(
    updateEntities(id, {
      project: [{
        ...newProject,
      }],
    }),
    setProps((state) => (
      {
        ...state,
        step: state.step + 1,
      })),
  );
}

export function removeUiDataset(datasetId: string): void {
  const { id, project } = getActiveProject();
  const newProject = structuredClone(project)[0] as GpbiProject;

  const index = newProject.recipe[0].datasetsList[0].dslist
    .findIndex((ds) => ds.ds[0].id === datasetId);

  newProject.recipe[0].datasetsList[0].dslist.splice(index, 1);

  projectStore.update(
    updateEntities(id, {
      project: [{
        ...newProject,
      }],
    }),
    setProps((state) => (
      {
        ...state,
        step: state.step + 1,
      })),
  );
}

export function removeUiLayer(id: string): void {
  if (isLayerInFormula(id)) {
    toggleLayerInResulting(id);
    projectStore.update(setProp('step', (step) => step + 1));
  }
  uiLayersStore.update(
    deleteEntities(id),
    setProps((state) => {
      const ov = state.ov.filter((l) => l !== id);
      const bg = state.bg.filter((l) => l !== id);
      return {
        ...state,
        cid: state.cid + 1,
        ov,
        bg,
      };
    }),
  );
  projectStore.update(
    setProps((state) => (
      {
        ...state,
        step: state.step + 1,
      })),
  );
}

export function addUiLayer(layerMap: GpLayerMap): void {
  const newUILayerOV = mapLayerMapToUILayerLocal(layerMap) as GpuiLayer;
  uiLayersStore.update(
    addEntities(newUILayerOV),
    setProps((state) => {
      const ov = [newUILayerOV.id].concat(state.ov);
      return {
        ...state,
        cid: state.cid + 1,
        ov,
      };
    }),
  );
  projectStore.update(
    setProps((state) => (
      {
        ...state,
        step: state.step + 1,
      })),
  );
}

export const addUiItem = (uiItem: GpdsScheduleDb | GpLayerMap) => {
  if (getActiveProject().projectType.model === ProjectType.GPProject) {
    addUiLayer(uiItem as GpLayerMap);
  }
  if (getActiveProject().projectType.model === ProjectType.GPBIProject) {
    addUiDataset(uiItem as GpdsScheduleDb);
  }
};

export const removeUiItem = (id: string) => {
  if (getActiveProject().projectType.model === ProjectType.GPProject) {
    removeUiLayer(id);
  }
  if (getActiveProject().projectType.model === ProjectType.GPBIProject) {
    removeUiDataset(id);
  }
};

export function resetProjectStep(): void {
  projectStore.update(
    setProps((state) => (
      {
        ...state,
        step: 0,
      })),
  );
}

export function setProjectsRequestError(error: unknown) {
  projectStore.update(
    updateRequestStatus('projects', 'error', error as ResponseError),
  );
}

export function setProjectsRequestPending() {
  projectStore.update(
    updateRequestStatus('projects', 'pending'),
  );
}

export function setProjectsRequestSuccess() {
  projectStore.update(
    updateRequestStatus('projects', 'success'),
  );
}

export function setProjects(projects: ExtendedGPProjectGn[], totalCount: number) {
  projectStore.update(
    setEntities(projects),
    setProps({
      totalCount,
    }),
    updateRequestStatus('projects', 'success'),
  );
}

export function getProject(id: string) {
  return projectStore.query(getEntity(id));
}

export function setActiveProjectId(id: string) {
  projectStore.update(setActiveId(id));
}

export function deteleProjectFromStore(id: string) {
  projectStore.update(
    deleteEntities([id]),
    setProps(({ totalCount }) => ({ totalCount: totalCount - 1 })),
    updateRequestStatus('projects', 'success'),
  );
}

export function upsertProject(project: GpProjectGn): void {
  projectStore.update(upsertEntities({ ...project, full: true } as ExtendedGPProjectGn));
}

export function updateGeoProject(
  project: GpProject,
  key: keyof GpProject,
  updatedProject: GpProject,
): void {
  const id = getActiveProjectId();
  projectStore.update(updateEntities(id, {
    project: [{
      ...project,
      [key]: updatedProject[key],
      cmsupdatedate: updatedProject.cmsupdatedate,
    }],
  }));
}

export function updateBiProject(
  project: GpbiProject,
  key: keyof GpbiProject,
  updatedProject: GpbiProject,
): void {
  const id = getActiveProjectId();
  projectStore.update(updateEntities(id, {
    project: [{
      ...project,
      [key]: updatedProject[key],
      cmsupdatedate: updatedProject.cmsupdatedate,
    }],
  }));
}

export function declineChanges() {
  const undo = () => (getActiveProject().projectType.model === ProjectType.GPProject
    ? uiLayersStoreHistory.undo()
    : projectStoreHistory.undo());
  for (let i = getStep(); i > 0; i -= 1) {
    undo();
  }
  resetProjectStep();
}

export function resetMap(): void {
  projectStore.update(resetActiveId());
  uiLayersStore.reset();
}

export function popActiveProjectBounds(): LatLngBoundsLiteral | undefined {
  const activeProjectGn = projectStore.query(getActiveEntity()) as ExtendedGPProjectGn;
  const projectGeo = activeProjectGn?.project[0] as ExtendedGPProject;

  if (!projectGeo?.bounds) {
    return undefined;
  }

  projectStore.update(updateEntities(
    activeProjectGn.id,
    {
      project: [
        {
          ...projectGeo,
          bounds: undefined,
        }] as [ExtendedGPProject],
    },
  ));

  return projectGeo.bounds;
}

const createdProjectStore = createStore(
  { name: 'createdProject' },
  withProps<{ id: string }>({ id: '' }),
);

export function getCreatedProjectId() {
  return createdProjectStore.query(
    (state) => state.id,
  );
}

export function setCreatedProjectId(id: string) {
  createdProjectStore.update(
    setProps(() => ({ id })),
  );
}

export function resetCreatedProjectId() {
  createdProjectStore.update(
    setProps(() => ({ id: '' })),
  );
}

import { Box } from '@mui/material';
import { FC } from 'react';

import Pagination from '../../../../../components/Pagination/Pagination';

import AclViews from '../../../../../components/AclViews/AclViews';
import styles from './LayersBody.module.scss';
import { FlatLayersListItem, FlatLayersListItemSecond } from '../LayersHelpers';

interface LayersBodyProps {
  view: string,
  page: number,
  pageCount: number,
  flatAclFirstView: FlatLayersListItem[],
  flatAclOtherView: FlatLayersListItemSecond[],
  handlePageClick: (e: React.ChangeEvent<unknown>, value: number) => void,
}

const LayersBody: FC<LayersBodyProps> = ({
  view,
  page,
  pageCount,
  flatAclFirstView,
  flatAclOtherView,
  handlePageClick,
}) => (
  <>
    <Box className={styles.layersBodyWrapper}>
      <AclViews
        view={view}
        flatAclFirstView={flatAclFirstView}
        flatAclOtherView={flatAclOtherView}
      />
    </Box>
    <Pagination
      page={page}
      pageCount={pageCount}
      handlePageChange={handlePageClick}
    />
  </>
);

export default LayersBody;

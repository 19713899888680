import { Box } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { FC, useEffect, useState } from 'react';
import { GpOrder, OrderStatuses } from '../../../../api/types';
import Loader from '../../../../components/Loader/Loader';
import MiniCartCard from './components/MiniCartCard/MiniCartCard';
import { fetchFullOrders } from '../../../../state/order';
import {
  orderLayerSchedules$, orders$, ordersTotalCount$,
} from '../../../../state/order/order.repository';
import OrderCard from './components/OrderCard/OrderCard';
import styles from './Orders.module.scss';
import { PROJECTS_PAGE_SIZE_COUNT } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination/Pagination';
import useComponentWillMount from '../../../../hooks/useComponentWillMount';
import { activeAccountId$ } from '../../../../state/context/auth.repository';
import usePageAndSearch from '../../../../hooks/usePageAndSearch';

interface OrderListProps {
  orders: GpOrder[];
}

const OrderList: FC<OrderListProps> = ({ orders }) => (
  <>
    {orders.filter((order) => order.status !== OrderStatuses.Created).map((order) => (
      <OrderCard
        key={order.id}
        orderId={order.id}
        createDate={order.cmscreationdate}
        orderItems={order.orderItems}
        paymentDate={order.dateTime}
        status={order.status}
      />
    ))}
  </>
);

const Orders = () => {
  const [orders] = useObservable(orders$);
  const [orderItems] = useObservable(orderLayerSchedules$);
  const [ordersTotalCount] = useObservable(ordersTotalCount$);
  const [activeAccountId] = useObservable(activeAccountId$);

  const [onLoad, setOnLoad] = useState(false);
  const limit = PROJECTS_PAGE_SIZE_COUNT;

  const {
    page,
    pageCount,
    handlePageChange,
    setPageCount,
  } = usePageAndSearch();

  useComponentWillMount(() => {
    setOnLoad(true);
  });

  useEffect(() => {
    setPageCount(Math.ceil(ordersTotalCount / (limit + 1)));
  }, [ordersTotalCount]);

  useEffect(() => {
    setOnLoad(true);
    void (async () => {
      await fetchFullOrders(page - 1, limit);
      setOnLoad(false);
    })();
  }, [page, activeAccountId]);

  if (onLoad) {
    return <Loader id="progress-orders" />;
  }

  return (
    <>
      <Box className={styles.orders}>
        <MiniCartCard items={orderItems} />
        <OrderList orders={orders} />
      </Box>
      {
        ordersTotalCount
          ? (
            <Pagination
              page={page}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
            />
          )
          : null
      }
    </>
  );
};

export default Orders;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useSearchParams } from 'react-router-dom';

import { MARKETPLACE_PAGE_SIZE_COUNT } from '../../utils/constants';
import styles from './Marketplace.module.scss';
import MarketplaceRubricChips from '../../blocks/MarketplaceRubricChips/MarketplaceRubricChips';
import { ReactComponent as Menu } from '../../assets/images/menu.svg';
import {
  products$, productsRequestPending$, productsRequestStatus$,
  productsTotalCount$, resetProductsStore,
} from '../../state/geoData/products.repository';
import {
  activeRubricsIds$,
  activeTerritoriesIds$,
  activeUpdatePeriodIds$,
  activeProductTypesIds$,
  order$,
} from '../../state/filters/filters.repository';

import { ResponseError } from '../../models/common';
import { activeAccountId$, isGuestMode$ } from '../../state/context/auth.repository';
import { fetchFilters, loadPageConfig, updatePageConfig } from '../../state/filters/filters.effects';
import { fetchProducts } from '../../state/geoData/products.actions';
import MarketplaceCardNew from '../../components/MarketPlaceCard/MarketPlaceCard';
import RubricsModal from '../../components/Rubrics/RubricsModal';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import Loader from '../../components/Loader/Loader';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import Pagination from '../../components/Pagination/Pagination';
import Search from '../../blocks/Search/Search';
import usePageAndSearch from '../../hooks/usePageAndSearch';

function Marketplace() {
  const [products] = useObservable(products$);
  const [productsTotalCount] = useObservable(productsTotalCount$);
  const [productsRequestPending] = useObservable(productsRequestPending$, { initialValue: true });
  const [productsRequestStatus] = useObservable(productsRequestStatus$);

  const [activeTerritoriesIds] = useObservable(activeTerritoriesIds$);
  const [activeRubricsIds] = useObservable(activeRubricsIds$);
  const [activeUpdatePeriodIds] = useObservable(activeUpdatePeriodIds$);
  const [activeProductTypesIds] = useObservable(activeProductTypesIds$);
  const [activeAccountId] = useObservable(activeAccountId$);
  const [isGuestMode] = useObservable(isGuestMode$);
  const [order] = useObservable(order$);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageConfigId, setPageConfigId] = useState('');

  const { t } = useTranslation('common');

  const {
    page,
    pageCount,
    inputSearch,
    handlePageChange,
    setPage,
    setPageCount,
    setInputSearch,
  } = usePageAndSearch();

  const [openRubrics, setopenRubrics] = useState(false);

  useComponentWillMount(() => {
    resetProductsStore();
  });

  const handleRubricsClose = () => setopenRubrics(false);

  const fetchLayersWrap = () => {
    void fetchProducts(
      page - 1,
      inputSearch,
      activeProductTypesIds,
      activeTerritoriesIds,
      activeRubricsIds,
      activeUpdatePeriodIds,
      order,
    );

    if (!isGuestMode) {
      updatePageConfig(pageConfigId)
        .then((res) => {
          if (
            res
              && res.data
              && 'create' in res.data.GPPageConfig
              && res?.data?.GPPageConfig.create
              && res.data.GPPageConfig.create.id !== pageConfigId
          ) {
            const configId = res.data.GPPageConfig.create.id;
            setPageConfigId(configId);
            setSearchParams({ q: configId });
          }
        })
        .catch(() => {});
    }
  };

  const fetchDirectory = () => {
    void fetchFilters();
  };

  useEffect(() => {
    setPageCount(Math.ceil(productsTotalCount / MARKETPLACE_PAGE_SIZE_COUNT));
  }, [productsTotalCount]);

  useEffect(() => {
    if (page === 1) {
      void fetchLayersWrap();
    } else {
      setPage(1);
    }
  }, [
    inputSearch,
    activeTerritoriesIds.length,
    activeRubricsIds.length,
    activeUpdatePeriodIds.length,
    activeProductTypesIds.length,
    order,
  ]);

  useEffect(() => {
    fetchLayersWrap();
  }, [page]);

  useEffect(() => {
    fetchDirectory();
    const configId = searchParams.get('q');
    if (configId && configId !== pageConfigId) {
      setPageConfigId(configId);
      void loadPageConfig(configId);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    fetchDirectory();
    fetchLayersWrap();
  }, [activeAccountId]);

  const renderPagination = () => {
    if (!productsRequestPending && productsTotalCount) {
      return (
        <Pagination
          page={page}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
        />
      );
    }
    return null;
  };

  const renderMarketplaceGrid = () => (
    <Box height="100%" flexDirection="column">
      <Box className={styles.container}>
        {productsRequestPending
          ? <Loader id="progress-marketplace" />
          : products
            .map((product) => (
              <MarketplaceCardNew
                key={product.id}
                id={product.id}
                productType={product.productType}
                product={product.product[0]}
              />
            ))}
      </Box>
      { renderPagination() }
    </Box>
  );

  if (productsRequestStatus.value === 'error') {
    return (
      <Typography variant="h5" textAlign="center">
        Error:
        {' '}
        {(productsRequestStatus.error as ResponseError).localizedMessage}
      </Typography>
    );
  }

  return (
    <Box className={styles.marketplace}>
      <PageBreadcrumbs />
      <Box display="flex" gap="12px">
        <Button
          variant="contained"
          className={styles.button}
          onClick={() => setopenRubrics(true)}
        >
          <Menu />
          <Typography variant="body2" fontWeight={700}>
            {t('marketplace.catalog')}
          </Typography>
        </Button>
        <Search
          handleInputChange={setInputSearch}
          placeholder={t('marketplace.search')}
          name="search_marketplace_query"
        />
      </Box>
      <MarketplaceRubricChips />

      {renderMarketplaceGrid()}

      <RubricsModal
        open={openRubrics}
        handleClose={handleRubricsClose}
      />
    </Box>
  );
}

export default Marketplace;

import { CircularProgress } from '@mui/material';
import styles from './Loader.module.scss';

interface LoaderProps {
  id: string;
}

const Loader: React.FC<LoaderProps> = ({ id }: LoaderProps) => (
  <CircularProgress data-testid={id} className={styles.loader} />
);

export default Loader;
